import "./traitSelector.css";

import { Badge, Button, Input } from "reactstrap";
import { useEffect, useMemo, useRef, useState } from "react";

import DEFAULT_TRAIT_ICON from "../../../../static/img/default_trait_icon.png";
import { DomEvent } from "leaflet";
import { HighlightedText } from "../../../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { TraitDisplayCard } from "../../../../glossary/TraitDisplayCard";
import classNames from "classnames";
import { extractTraitNameAndClasses } from "../../../utils";
import { useTraitGroups } from "../../useTraitGroups";

/* Trait item of trait list
 * shows trait icon + trait name, trait glossary card on hover
 * sets selectedTrait on click and visualized trait btn
 */
const TraitItem = (props) => {
  const {
    trait,
    disabled,
    selectedTrait,
    setSelectedTrait,
    onDeleteAddonTrait,
    setHoverTrait,
    highlight,
  } = props;

  // Active / inactive / selected
  const traitItemClasses = classNames(
    "trait-item clickable powerdash-component",
    {
      active: selectedTrait?.technical_name === trait.technical_name,
      addon: trait.isAddon,
      disabled: disabled,
    }
  );

  const [traitName, traitClasses] = useMemo(() => {
    return extractTraitNameAndClasses(trait.name);
  }, [trait]);

  return (
    <div
      className={traitItemClasses}
      onClick={() => {
        if (!disabled) setSelectedTrait(trait);
      }}
      onMouseEnter={() => setHoverTrait(trait)}
      onMouseLeave={() => setHoverTrait(null)}
    >
      <div>
        {trait.isAddon && <i className="fa fa-area-chart me-1" />}
        <HighlightedText text={traitName} highlight={highlight} />{" "}
        {traitClasses.map((label) => (
          <Badge
            className="mt-1 me-1 hiphen-badge small opacity-75"
            key={label}
          >
            <HighlightedText text={label} highlight={highlight} />
          </Badge>
        ))}
      </div>

      {trait.isAddon && (
        <Button
          className="addon-delete-button"
          color="secondary"
          data-tooltip-variant="dark"
          data-tooltip-id="tooltip"
          data-tooltip-content="Remove additionnal trait"
          data-tooltip-place="bottom"
          onClick={onDeleteAddonTrait}
        >
          <i className="fa fa-minus" />
        </Button>
      )}
    </div>
  );
};

/* Trait list: displays vertical list of traitItems
 */
export const TraitSelector = (props) => {
  const { traits, onMap, setSelectedTrait, deleteAddonTrait } = props;
  const [traitFilter, setTraitFilter] = useState("");
  const [hoverTrait, setHoverTrait] = useState(null);

  const traitGroups = useTraitGroups(
    traits.filter((trait) =>
      trait.name
        .replaceAll("_", " ")
        .toLowerCase()
        .includes(traitFilter.toLowerCase())
    )
  );

  const refContainer = useRef(null);
  useEffect(() => {
    if (onMap) DomEvent.disableScrollPropagation(refContainer.current);
  }, [onMap]);

  return (
    <div
      ref={refContainer}
      className={`powerdash-component trait-selector ${onMap ? "on-map" : ""}`}
    >
      <div className="trait-search">
        <Input
          bsSize="sm"
          type="search"
          placeholder={`Traits (${traits.length})`}
          disabled={traits.length === 0}
          value={traitFilter}
          onChange={(event) => setTraitFilter(event.target.value)}
        />
        <i className="fa fa-lg fa-search discrete-icon"></i>
      </div>
      <div className="trait-list">
        {traitGroups.map((group) => (
          <div className="trait-list-group" key={group.uuid}>
            <div className="trait-list-group-header">
              <div className="d-flex align-items-center">
                <img src={group.icon ?? DEFAULT_TRAIT_ICON} alt="" />
                <span className="ms-1">{group.name}</span>
              </div>
            </div>
            {group.Traits.sort((a, b) => a.name.localeCompare(b.name)).map(
              (trait) => (
                <TraitItem
                  key={trait.technical_name}
                  trait={trait}
                  disabled={false}
                  setHoverTrait={setHoverTrait}
                  highlight={traitFilter}
                  onDeleteAddonTrait={(event) => {
                    event.stopPropagation();
                    // When deleting an addon trait, set selectedTrait to the base trait if it exists
                    // Otherwise, set selectedTrait to the first trait
                    setSelectedTrait(
                      traits.includes(trait.baseTrait)
                        ? trait.baseTrait
                        : traits[0]
                    );
                    deleteAddonTrait(trait.technical_name);
                  }}
                  {...props}
                />
              )
            )}
          </div>
        ))}
      </div>
      {hoverTrait && !hoverTrait.isAddon && (
        <div className="trait-display-card-wrapper">
          <TraitDisplayCard trait={hoverTrait} />
        </div>
      )}
    </div>
  );
};

TraitSelector.propTypes = {
  traits: PropTypes.array.isRequired,
  onMap: PropTypes.bool,
  setSelectedTrait: PropTypes.func.isRequired,
  deleteAddonTrait: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  selectedTrait: PropTypes.object,
};

TraitItem.propTypes = {
  trait: PropTypes.object.isRequired,
  setSelectedTrait: PropTypes.func.isRequired,
  onDeleteAddonTrait: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setHoverTrait: PropTypes.func.isRequired,
  selectedTrait: PropTypes.object,
  highlight: PropTypes.string,
};
