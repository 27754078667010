import { useCallback, useMemo, useState } from "react";

import { ANALYTICS_EVENTS } from "../../../../constants";
import { Button } from "reactstrap";
import { PropTypes } from "prop-types";
import { UpdatedColumn } from "./UpdatedColumn";
import { updateFeatureProperties } from "../../../../actions/resultMap";
import { useDispatch } from "react-redux";
import { useTracking } from "../../../../analytics";

export const VerifyYourOwnData = ({ data, headers, setUploadIsOpen }) => {
  const dispatch = useDispatch();

  const { trackEvent } = useTracking();

  const validHeadersData = useMemo(
    () =>
      headers.reduce((tempValidHeadersData, header) => {
        let count = 0;
        let isNumberCastable = true;
        data.forEach((row) => {
          const value = row[header];
          if (isNaN(value)) isNumberCastable = false;
          if (value != null && value !== "") count++;
        });

        if (count > 0)
          tempValidHeadersData[header] = { count, isNumberCastable };

        return tempValidHeadersData;
      }, {}),
    [data, headers]
  );

  const validHeaderEntries = Object.entries(validHeadersData);

  const [headerTypes, setHeaderTypes] = useState(
    validHeaderEntries.reduce(
      (tempHeaderTypes, [header, { isNumberCastable }]) => {
        tempHeaderTypes[header] = isNumberCastable ? "Number" : "Text";
        return tempHeaderTypes;
      },
      {}
    )
  );

  const handleSelectChange = (header, selectedOption) =>
    setHeaderTypes((prevSelectedTypes) => ({
      ...prevSelectedTypes,
      [header]: selectedOption.value,
    }));

  const handleValidate = useCallback(() => {
    const uploadedData = data.map((row) => {
      let newRow = {};

      headers.forEach((header) => {
        if (row[header] != null && row[header] !== "")
          newRow[header] =
            headerTypes[header] === "Number"
              ? Number(row[header])
              : String(row[header]);
      });

      return newRow;
    });

    dispatch(updateFeatureProperties(uploadedData));
    setUploadIsOpen(false);
    trackEvent(ANALYTICS_EVENTS.UPLOAD_YOUR_OWN_DATA);
  }, [data, dispatch, headerTypes, headers, setUploadIsOpen, trackEvent]);

  return (
    <>
      <div className="values-container">
        {validHeaderEntries
          .filter(([header]) => header !== "cloverfieldId" && header !== "id")
          .map(([header, { count, isNumberCastable }]) => (
            <UpdatedColumn
              key={header}
              header={header}
              count={count}
              isNumberCastable={isNumberCastable}
              handleSelectChange={handleSelectChange}
              headerType={headerTypes[header]}
            />
          ))}
      </div>
      <div className="text-end mt-4">
        <Button className="hiphen-green-button" onClick={handleValidate}>
          Validate
        </Button>
      </div>
    </>
  );
};

VerifyYourOwnData.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  setUploadIsOpen: PropTypes.func.isRequired,
};
