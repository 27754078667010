import "./advancedFiltering.scss";

import { Button, OffcanvasBody, Progress } from "reactstrap";
import {
  selectFilteredOutGroupsSet,
  selectMergedTraits,
} from "../../../../selectors/resultMap";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ADVANCED_FILTERING_DATASET_COLORS } from "../../../constants";
import { ANALYTICS_EVENTS } from "../../../../constants";
import { AdvancedFilteringGroup } from "./advancedFilteringItem/AdvancedFilteringGroup";
import { AdvancedFilteringRecap } from "./advancedFilteringRecap/AdvancedFilteringRecap";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { FilteringProfileManager } from "./filteringProfileManager/FilteringProfileManager";
import PropTypes from "prop-types";
import { compareArrays } from "../../../utils";
import { setFilteringProfileFilter } from "../../../../actions/resultMap";
import { useTracking } from "../../../../analytics";
import { useTraitGroups } from "../../useTraitGroups";

export function AdvancedFiltering({
  traitsMetrics,
  toggleOffcanvas,
  data,
  getColor,
}) {
  const [selectedDate, filteringProfile] = useSelector(({ resultMap }) => [
    resultMap.trial_date,
    resultMap.filteringProfile,
  ]);

  const traits = useSelector(selectMergedTraits);

  // Traits from the filtering profile that are not represented in data
  const missingTraits = useMemo(() => {
    const missingTraits = [];
    filteringProfile.filters.forEach((filter) => {
      filter.traits.forEach((trait) => {
        if (
          trait.isAddon &&
          !traits.find((t) => t.technical_name === trait.technical_name)
        )
          missingTraits.push(trait.technical_name);
      });
    });
    return missingTraits;
  }, [filteringProfile.filters, traits]);

  const dispatch = useDispatch();

  const excludedGroups = useSelector(selectFilteredOutGroupsSet);

  const [expandedGroups, setExpandedGroups] = useState([]);

  const traitGroups = useTraitGroups(traits);

  // Add initial ranges to traits
  const traitGroupsWithRange = useMemo(
    () =>
      traitGroups.map((group) => ({
        ...group,
        Traits: group.Traits.map((trait) => {
          return {
            ...trait,
            range: [
              traitsMetrics[trait.technical_name].min,
              traitsMetrics[trait.technical_name].max,
            ],
          };
        }),
      })),
    [traitGroups, traitsMetrics]
  );

  const traitsWithRange = useMemo(
    () => traitGroupsWithRange.flatMap(({ Traits }) => Traits),
    [traitGroupsWithRange]
  );

  // Set initial thresholds values based on existingDateFilter if already applied to this DATE
  // Initialize to default trait range if not
  const initialThresholds = useMemo(() => {
    const existingCurrentDateFilter = filteringProfile.filters.find(
      ({ date }) => date === selectedDate
    );
    return traitsWithRange.map(({ technical_name, range }) => {
      if (existingCurrentDateFilter) {
        const existingFilter = existingCurrentDateFilter.traits.find(
          (trait) => trait.technical_name === technical_name
        );
        if (existingFilter) return existingFilter;
      }
      return {
        technical_name,
        threshold: range,
      };
    });
  }, [filteringProfile.filters, selectedDate, traitsWithRange]);

  const [thresholds, setThresholds] = useState(initialThresholds);

  const activeTraits = useMemo(() => {
    return traitsWithRange
      .filter((trait) => {
        const threshold = thresholds.find(
          (threshold) => threshold.technical_name === trait.technical_name
        );
        return !compareArrays(trait.range, threshold.threshold);
      })
      .map(({ technical_name }) => technical_name);
  }, [traitsWithRange, thresholds]);

  const focusedVarieties = useSelector(
    ({ resultMap }) => resultMap.focusedVarieties
  );

  // Datasets to give every histogram data to display
  const [datasets, controlData] = useMemo(() => {
    const includedData = [];
    const excludedData = [];
    const dataToInclude = [];
    const dataToExclude = [];
    const controlData = [];

    data.forEach((featuresGroup) => {
      if (
        getColor && // analytics only
        featuresGroup.isControl &&
        focusedVarieties.includes(featuresGroup.group)
      )
        controlData.push({
          ...featuresGroup,
          color: getColor(featuresGroup.group),
        });
      const isOutOfRanges = thresholds.some((trait) => {
        const traitValue = featuresGroup.properties[trait.technical_name].mean;

        return (
          traitValue < trait.threshold[0] || traitValue > trait.threshold[1]
        );
      });

      // Excluded groups represents groups already filtered out
      const isExcluded = excludedGroups.has(featuresGroup.group);

      if (isOutOfRanges)
        if (isExcluded) excludedData.push(featuresGroup);
        else dataToExclude.push(featuresGroup);
      // If item is currently not filtered out by threshold but has been previously filtered
      // an extra verification is needed on wether or not it was by another date
      // if not, it should be included back
      else if (isExcluded)
        if (
          filteringProfile.filters.some(
            (filter) =>
              filter.date !== selectedDate &&
              filter.excludedGroups.includes(featuresGroup.group)
          )
        )
          excludedData.push(featuresGroup);
        else dataToInclude.push(featuresGroup);
      else includedData.push(featuresGroup);
    });

    return [
      {
        includedData,
        dataToInclude,
        dataToExclude,
        excludedData,
      },
      controlData,
    ];
  }, [
    data,
    excludedGroups,
    filteringProfile.filters,
    selectedDate,
    thresholds,
    focusedVarieties,
    getColor,
  ]);

  const handleCollapse = (groupUuid) => {
    setExpandedGroups((prevGroups) => {
      if (!filteringProfile.scope) return prevGroups;
      const isGroupOpen = prevGroups.includes(groupUuid);
      if (isGroupOpen) return prevGroups.filter((uuid) => uuid !== groupUuid);
      else return [...prevGroups, groupUuid];
    });
  };

  const handleThresholdChange = useCallback(
    (technical_name, threshold) =>
      setThresholds((prevState) => {
        const trait = traits.find(
          (trait) => trait.technical_name === technical_name
        );
        const traitsThresholdsCopy = prevState.filter(
          (trait) => trait.technical_name !== technical_name
        );
        traitsThresholdsCopy.push({
          technical_name,
          threshold,
          isAddon: trait.isAddon,
        });

        return traitsThresholdsCopy;
      }),
    [traits]
  );

  // Dispatch the addition/update of filter object for current date.
  // Only thresholds that have changed since initial trait range should be saved
  const { trackEvent } = useTracking();
  const applyFilters = () => {
    dispatch(
      setFilteringProfileFilter({
        date: selectedDate,
        traits: thresholds.filter(({ technical_name, threshold }) => {
          const trait = traitsWithRange.find(
            (trait) => trait.technical_name === technical_name
          );
          return !compareArrays(trait.range, threshold);
        }),
        excludedGroups: datasets.excludedData
          .concat(datasets.dataToExclude)
          .map(({ group }) => group),
      })
    );

    trackEvent(ANALYTICS_EVENTS.AF_APPLY_FILTER);
  };

  const thresholdsChangedSinceLastSave = !compareArrays(
    initialThresholds.toSorted(({ technical_name: a }, { technical_name: b }) =>
      a.localeCompare(b)
    ),
    thresholds.toSorted(({ technical_name: a }, { technical_name: b }) =>
      a.localeCompare(b)
    )
  );

  return (
    <>
      <OffcanvasBody className="trait-list-advanced p-2">
        <FilteringProfileManager
          filteringProfile={filteringProfile}
          toggleOffcanvas={toggleOffcanvas}
        />
        {missingTraits.length ? (
          <>
            <div className="h-25">
              <ComponentPlaceholder
                icon="fa fa-warning"
                text="Filtering profile cannot be modified because the following traits
            are missing and must be recomputed"
              />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
              {missingTraits.map((trait) => (
                <div
                  className="missing-trait p-2 d-flex gap-2 align-items-center"
                  key={trait}
                >
                  {trait}
                </div>
              ))}
            </div>
          </>
        ) : (
          traitGroupsWithRange.map((group) => (
            <AdvancedFilteringGroup
              key={group.uuid}
              group={group}
              expanded={expandedGroups.includes(group.uuid)}
              scopeIsDefined={Boolean(filteringProfile.scope)}
              activeTraits={activeTraits}
              handleCollapse={handleCollapse}
              thresholds={thresholds}
              initialThresholds={initialThresholds}
              controlData={controlData}
              datasets={datasets}
              handleThresholdChange={handleThresholdChange}
            />
          ))
        )}
      </OffcanvasBody>
      {filteringProfile.scope && (
        <div
          className={`p-2 d-flex justify-content-end align-items-center gap-2 apply-filters ${
            thresholdsChangedSinceLastSave ? "active" : ""
          }`}
        >
          {missingTraits.length ? (
            <div className="d-flex flex-grow-1 align-items-center justify-content-center gap-2">
              <i className="fa fa-warning" /> Invalid filtering profile
            </div>
          ) : (
            <>
              <Progress multi>
                {Object.entries(datasets).map(([key, value]) => (
                  <Progress
                    key={key}
                    bar
                    value={(value.length * 100) / data.length}
                    style={{
                      backgroundColor: ADVANCED_FILTERING_DATASET_COLORS[key],
                    }}
                  />
                ))}
              </Progress>
              <span
                className={`diff plus ${
                  datasets.dataToInclude.length ? "active" : ""
                }`}
              >{`+${datasets.dataToInclude.length}`}</span>
              <span
                className={`diff minus ${
                  datasets.dataToExclude.length ? "active" : ""
                }`}
              >{`-${datasets.dataToExclude.length}`}</span>
            </>
          )}
          <Button
            className="hiphen-green-button"
            disabled={!thresholdsChangedSinceLastSave}
            onClick={applyFilters}
          >
            <i className="fa fa-filter" /> Apply Filters
          </Button>
          <Button
            disabled={!thresholdsChangedSinceLastSave}
            onClick={() => setThresholds(initialThresholds)}
          >
            Cancel
          </Button>
        </div>
      )}
      <AdvancedFilteringRecap data={data} />
    </>
  );
}

AdvancedFiltering.propTypes = {
  toggleOffcanvas: PropTypes.func.isRequired,
  traitsMetrics: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getColor: PropTypes.func,
};
