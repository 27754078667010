import "./expandedFocusView.css";

import { Col, Row } from "reactstrap";

import { AD_COL_CLASSES } from "../../../../constants";
import { FocusList } from "../focusList/focusList";
import { PropTypes } from "prop-types";
import { VarietyThumbnail } from "../varietyThumbnail/varietyThumbnail";

const colClasses = AD_COL_CLASSES;

export const ExpandedFocusView = (props) => {
  const { expandFocusView, visualizedElements } = props;
  return (
    <>
      {expandFocusView && (
        <div className="p-0 powerdash-component focus-view expanded">
          <Row className="focus-view-row g-0">
            <Col className={colClasses} sm="2">
              <FocusList {...props} />
            </Col>
            <Col className={colClasses} sm="10">
              <div className="expanded-focus-view-content">
                <VarietyThumbnail
                  visualizedElement={visualizedElements[0]}
                  horizontal
                  {...props}
                />
                <VarietyThumbnail
                  visualizedElement={visualizedElements[1]}
                  horizontal
                  {...props}
                />
                <VarietyThumbnail
                  visualizedElement={visualizedElements[2]}
                  horizontal
                  {...props}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

ExpandedFocusView.propTypes = {
  expandFocusView: PropTypes.bool.isRequired,
};
