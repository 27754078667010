import "./complexSearch.css";

import { Badge, Input } from "reactstrap";
import { memo, useEffect, useMemo, useRef, useState } from "react";

import { HighlightedText } from "../../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { capitalize } from "../../utils";

const ComplexSearchOption = ({
  option,
  handleSelection,
  input,
  CustomComponent,
}) => {
  return (
    <div
      tabIndex="0"
      key={option.value}
      className="search-results-option clickable"
      onClick={() => handleSelection(option)}
      onKeyDown={({ key }) => {
        if (key === "Enter") handleSelection(option);
      }}
    >
      <div className="search-results-option-bullet">{option.renderBullet}</div>
      {CustomComponent ? (
        <CustomComponent input={input} option={option.label} />
      ) : (
        <HighlightedText text={option.label} highlight={input} />
      )}
    </div>
  );
};

const ComplexSearchCategory = memo(
  ({
    name,
    CustomComponent,
    options,
    input,
    handleClick,
    setInput,
    setFocused,
    closeOnSelect,
  }) => {
    const handleSelection = (option) => {
      setInput("");
      handleClick(option.value);
      if (closeOnSelect) setFocused(false);
    };

    const categoryOptions = options.filter(({ label }) =>
      label.replaceAll("_", " ").toLowerCase().includes(input.toLowerCase())
    );

    return (
      <div className="vertical-stack">
        <span className="search-results-category">{name}</span>
        {categoryOptions.length > 0 ? (
          categoryOptions.map((option) => (
            <ComplexSearchOption
              key={option.label.concat(option.value)}
              option={option}
              handleSelection={handleSelection}
              input={input}
              CustomComponent={CustomComponent}
            />
          ))
        ) : (
          <div className="search-results-category-placeholder">No result</div>
        )}
      </div>
    );
  }
);

/*
 * Complex search is composed of an input and a list that unfolds when input is focused
 * and displays a list of elements filtered on the input string and separated by categories
 * It takes an object in the shape of category keys containing options and a handleClick function
 */
export const ComplexSearch = ({
  placeholder,
  content,
  handleClickDefault,
  closeOnSelect = true,
  inverted,
  disabled,
  deferTime = 0,
}) => {
  const [input, setInput] = useState("");
  const [search, setSearch] = useState("");
  const [isFocused, setFocused] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => setSearch(input), deferTime);
    return () => clearTimeout(timeout);
  }, [deferTime, input]);

  const filteredEntries = useMemo(
    () =>
      Object.entries(content).filter(([category]) =>
        selectedCategory ? category === selectedCategory : true
      ),
    [content, selectedCategory]
  );

  const wrapperRef = useRef(null);

  useEffect(() => {
    // Set unfocused state if clicked on outside of search or results component
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target))
        setFocused(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="complex-search-wrapper" ref={wrapperRef}>
      <Input
        className={`complex-search-input ${disabled ? "disabled" : ""}`}
        type="search"
        placeholder={placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onFocus={() => setFocused(true)}
        disabled={disabled}
      />
      {isFocused && (
        <div
          className={`search-results vertical-stack ${
            inverted ? "inverted" : ""
          }`}
        >
          <div className="search-results-header">
            {selectedCategory && (
              <Badge
                pill
                className="hiphen-badge clickable"
                onClick={() => setSelectedCategory(null)}
              >
                <i className="fa fa-close discrete-icon" />
              </Badge>
            )}
            {filteredEntries.map(([category]) => (
              <Badge
                pill
                key={category}
                className={`hiphen-badge clickable ${
                  category === selectedCategory ? "active" : ""
                }`}
                onClick={() =>
                  setSelectedCategory((current) =>
                    current === category ? null : category
                  )
                }
              >
                {capitalize(category)}
              </Badge>
            ))}
            {deferTime > 0 && input !== search && (
              <i className="fa fa-circle-o-notch fa-spin" />
            )}
          </div>
          <div className="search-results-body">
            {filteredEntries.map(
              ([category, { options, handleClick, CustomComponent }]) => (
                <ComplexSearchCategory
                  key={category}
                  name={category}
                  options={options}
                  input={search}
                  setInput={setInput}
                  setFocused={setFocused}
                  handleClick={handleClick || handleClickDefault}
                  closeOnSelect={closeOnSelect}
                  CustomComponent={CustomComponent}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ComplexSearchOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    renderBullet: PropTypes.node,
  }),
  handleSelection: PropTypes.func.isRequired,
  input: PropTypes.string.isRequired,
  CustomComponent: PropTypes.elementType,
};

ComplexSearchCategory.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  input: PropTypes.string.isRequired,
  setInput: PropTypes.func.isRequired,
  setFocused: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  closeOnSelect: PropTypes.bool,
  CustomComponent: PropTypes.elementType,
};

ComplexSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  handleClickDefault: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  deferTime: PropTypes.number,
};
