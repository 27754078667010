import { Button, Col, Input, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { clickableRows, fixHeaders } from "../components/dataTable";

import AlertComponent from "../components/alert";
import { EXPERIMENT_STATUS } from "../constants";
import LoadingImg from "../components/loading";
import NavbarComponent from "../components/navbar";
import PageTitle from "../components/pageTitle";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from "react-select";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchCompanies } from "../actions/companies";
import { fetchCompanyContracts } from "../actions/company";
import { fetchSites } from "../actions/sites";
import moment from "moment";
import queryString from "query-string";
import { requestFetchSiteExperiments } from "../services/backendRequests";
import { useDidMountEffect } from "../hooks/useDidMountEffect";
import { useLocation } from "react-router-dom";

const Experiments = ({
  user,
  companies,
  contracts,
  sites,
  fetchCompanies,
  fetchCompanyContracts,
  fetchSites,
}) => {
  const location = useLocation();
  const { company, contract, site } = queryString.parse(location.search);
  const [companyId, setCompanyId] = useState(company);
  const [contractId, setContractId] = useState(contract);
  const [siteId, setSiteId] = useState(site);
  const [status, setStatus] = useState(EXPERIMENT_STATUS.COMPLETED);
  const [experiments, setExperiments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const applyEffect = (fetch, elementId, reset = undefined) => {
    if (elementId) {
      fetch({ id: elementId });
      if (reset) reset();
    }
  };

  useDidMountEffect(() => {
    applyEffect(
      (e) => fetchCompanyContracts(e),
      companyId,
      () => {
        setContractId();
        setSiteId();
        setExperiments();
      }
    );
  }, [companyId]);

  useDidMountEffect(() => {
    applyEffect(
      (e) => fetchSites(e),
      contractId,
      () => {
        setSiteId();
        setExperiments();
      }
    );
  }, [contractId]);

  const search = async () => {
    setLoading(true);
    let data = [];
    if (siteId) {
      const res = await requestFetchSiteExperiments({ id: siteId }, user);
      data = data.concat(res);
    } else {
      for (const site of sites) {
        const res = await requestFetchSiteExperiments(site, user);
        data = data.concat(res);
      }
    }
    if (status) {
      let sites_with_status = sites
        .filter((s) => s.experiment_status === status)
        .map((s) => s.id);
      data = data.filter((exp) => sites_with_status.includes(exp.site.id));
    }
    setExperiments(data);
    setLoading(false);
  };

  const selectComponent = (label, options, value, onChange) => {
    return (
      <>
        <Col xs="1">
          <label className="col-form-label green-color">{label}</label>
        </Col>
        <Col xs="3">
          <Select
            options={options.map((c) => {
              return { value: c.id, label: c.name };
            })}
            value={{
              value: value,
              label: options.find((c) => c.id === value)?.name,
            }}
            onChange={(e) => onChange(e.value)}
            isSearchable={true}
          />
        </Col>
      </>
    );
  };

  return (
    <div className="wrapper">
      <NavbarComponent />
      <AlertComponent />

      <div className="page-content container-fluid">
        <PageTitle title="Plot maps" />

        <div className="col-12 section full-height">
          <Row className="pb-2">
            {selectComponent("Company", companies, companyId, (p) =>
              setCompanyId(p)
            )}
            {selectComponent("Contract", contracts, contractId, (p) =>
              setContractId(p)
            )}
            {selectComponent("Site", sites, siteId, (p) => setSiteId(p))}
          </Row>
          <Row className="pb-2">
            <Col xs={{ size: 1 }}>
              <label className="col-form-label green-color">Status</label>
            </Col>
            <Col xs="3">
              <Input
                type="select"
                value={status}
                onChange={(e) => setStatus(Number(e.target.value))}
              >
                {Object.keys(EXPERIMENT_STATUS).map((key) => {
                  if (key !== "properties")
                    return (
                      <option key={key} value={EXPERIMENT_STATUS[key]}>
                        {
                          EXPERIMENT_STATUS.properties[EXPERIMENT_STATUS[key]]
                            .label
                        }
                      </option>
                    );
                })}
              </Input>
            </Col>
            <Col xs={{ size: 3, offset: 5 }}>
              <Button
                color="primary"
                className="w-100"
                data-tooltip-id="tooltip"
                data-tooltip-content="View experiments"
                onClick={() => {
                  search();
                  ReactTooltip.hide();
                }}
                disabled={!companyId || !contractId}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12 text-center pb-2">
              <Table striped hover>
                {fixHeaders(
                  "Site",
                  "Plot map label",
                  "Created at",
                  "Updated at",
                  "Status"
                )}
                <tbody>
                  {!loading &&
                    experiments?.map((exp) =>
                      clickableRows(
                        (exp) => {},
                        exp,
                        exp.id,
                        exp.site.display_name,
                        exp.features.features.find((i) => i.id === "Polygon")
                          .properties.block_name,
                        moment(exp.created_at).format("YYYY-MM-DD HH:mm:ss"),
                        moment(exp.updated_at).format("YYYY-MM-DD HH:mm:ss"),
                        EXPERIMENT_STATUS.properties[
                          sites.find((s) => s.id === exp.site.id)
                            .experiment_status
                        ].label
                      )
                    )}
                </tbody>
              </Table>
              <LoadingImg visible={loading} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const companies = state.companies.all;
  const sortedCompanies = _.orderBy(
    companies,
    [(company) => company.name.toLowerCase()],
    ["asc"]
  );

  const contracts = state.company.contracts.filter(
    (c) => c.has_experiment === true
  );
  const sortedContracts = _.orderBy(
    contracts,
    [(contract) => contract.name.toLowerCase()],
    ["asc"]
  );

  const sites = state.sites.all;
  const sortedSites = _.orderBy(
    sites,
    [(site) => site.display_name?.toLowerCase()],
    ["asc"]
  );

  return {
    user: state.user,
    companies: sortedCompanies,
    contracts: sortedContracts,
    sites: sortedSites,
  };
}

const mapDispatchToProps = {
  fetchCompanies,
  fetchCompanyContracts,
  fetchSites,
};

export default connect(mapStateToProps, mapDispatchToProps)(Experiments);

Experiments.propTypes = {
  companies: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  fetchCompanyContracts: PropTypes.func.isRequired,
  fetchSites: PropTypes.func.isRequired,
};
