import "./advancedFilteringItem.css";

import { Badge, Input, InputGroup, InputGroupText } from "reactstrap";
import { compareArrays, extractTraitNameAndClasses } from "../../../../utils";

import { AdvancedChart } from "./AdvancedChart";
import PropTypes from "prop-types";

/*
 * Component showing both histogram+slider and trait information below
 * to be used in the context of advanced filtering drawer
 */
export const AdvancedFilteringItem = ({
  trait,
  active,
  threshold,
  initialThreshold,
  datasets,
  controlData,
  handleThresholdChange,
}) => {
  const step = Math.pow(10, Math.floor(Math.log10(trait.range[1]))) / 100;
  const [traitName, traitClasses] = extractTraitNameAndClasses(trait.name);

  return (
    <div className="pt-2 pb-0 p-4 advanced-filtering-item">
      <AdvancedChart
        threshold={threshold}
        trait={trait}
        datasets={datasets}
        handleThresholdChange={handleThresholdChange}
        controlData={controlData}
        step={step}
      />
      <div className="d-flex justify-content-between p-3">
        <span
          className="trait-name"
          title={`${traitName}${
            traitClasses.length ? ":" : ""
          } ${traitClasses.join(" ")}`}
        >
          {active && <i className="fa fa-circle" />}{" "}
          {trait.isAddon && <i className="fa fa-area-chart" />}
          {traitName}{" "}
          {traitClasses.map((traitClass) => (
            <Badge className="hiphen-badge small ms-1" key={traitClass}>
              {traitClass}
            </Badge>
          ))}
        </span>
        <div className="align-items-center d-flex gap-1">
          {/* Button to revert changes made to trait since last save */}
          {!compareArrays(threshold, initialThreshold) && (
            <i
              className="fa fa-undo clickable"
              onClick={() =>
                handleThresholdChange(trait.technical_name, initialThreshold)
              }
            />
          )}
          {/* Button to reset threshold to initial trait range */}
          {active && (
            <i
              className="fa fa-times clickable"
              onClick={() =>
                handleThresholdChange(trait.technical_name, trait.range)
              }
            />
          )}
          <InputGroup className="threshold-input-group">
            <Input
              type="number"
              className="threshold-input"
              value={threshold[0]}
              step={step}
              onChange={(event) => {
                if (
                  event.target.value < threshold[1] &&
                  event.target.value >= trait.range[0]
                )
                  handleThresholdChange(trait.technical_name, [
                    event.target.value,
                    threshold[1],
                  ]);
              }}
            />
            {trait.unit && <InputGroupText>{trait.unit}</InputGroupText>}
          </InputGroup>
          <i className="fa fa-long-arrow-right" />
          <InputGroup className="threshold-input-group">
            <Input
              type="number"
              className="threshold-input"
              value={threshold[1]}
              step={step}
              onChange={(event) => {
                if (
                  event.target.value > threshold[0] &&
                  event.target.value <= trait.range[1]
                )
                  handleThresholdChange(trait.technical_name, [
                    threshold[0],
                    event.target.value,
                  ]);
              }}
            />
            {trait.unit && <InputGroupText>{trait.unit}</InputGroupText>}
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

AdvancedFilteringItem.propTypes = {
  active: PropTypes.bool.isRequired,
  trait: PropTypes.object.isRequired,
  threshold: PropTypes.array.isRequired,
  initialThreshold: PropTypes.array.isRequired,
  datasets: PropTypes.exact({
    includedData: PropTypes.array.isRequired,
    excludedData: PropTypes.array.isRequired,
    dataToInclude: PropTypes.array.isRequired,
    dataToExclude: PropTypes.array.isRequired,
  }),
  handleThresholdChange: PropTypes.func.isRequired,
  controlData: PropTypes.array.isRequired,
};
