import {
  MISSION_MONITORING_COLORWAY,
  MISSION_MONITORING_FLAG_COLORS,
  MISSION_MONITORING_STATIC_STATUS,
  MISSION_MONITORING_STATIC_STATUS_COLORS,
  POWERDASH_DATE_FORMAT,
} from "./constants";

import chroma from "chroma-js";
import { hasMonitoringRole } from "../users/rolesUtil";
import moment from "moment";

export function dateSort(dateA, dateB) {
  const momentA = moment(dateA);
  const momentB = moment(dateB);
  if (momentA.isBefore(momentB)) return -1;
  if (momentB.isBefore(momentA)) return 1;
  return 0;
}

export function uniqueArrayOfObject(array, keyToBeUnique) {
  // Filter by looking at the next objects if the key is present a second time
  return array.filter(
    (x, xi) =>
      !array.slice(xi + 1).some((y) => y[keyToBeUnique] === x[keyToBeUnique])
  );
}

// group by groups object from array inside an object with keyx and arrays as value
// key1 key2 are nesting levels
export const groupBy = function (xs, key1, key2) {
  return xs.reduce(function (rv, x) {
    (rv[x[key1][key2]] = rv[x[key1][key2]] || []).push(x);
    return rv;
  }, {});
};

export const groupByKey = function (xs, key1) {
  return xs.reduce(function (rv, x) {
    (rv[x[key1]] = rv[x[key1]] || []).push(x);
    return rv;
  }, {});
};

// Returns black or white based on hexcolor, used to set text color on colorful background
export function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

// Count all occurences of key inside nested array keyToCount inside data
export const occurrences = (keys, data, keyToCount, excludeZeros) =>
  Object.fromEntries(
    keys.map((key) => {
      const count = data.filter(
        (element) => element[keyToCount] === key
      ).length;
      if (excludeZeros && count === 0) return [];
      return [key, count];
    })
  );

// bool contract is started and not ended
export const contractIsActive = (contract) => {
  const now = moment();
  return (
    moment(contract.start_date) < now &&
    now < moment(contract.end_date) &&
    hasMonitoringRole(contract.roles)
  );
};

// returns flag color based on status: red when raised, green when resolved
export const getFlagColor = (flag) => {
  return flag.closing_date === null
    ? MISSION_MONITORING_FLAG_COLORS.RAISED
    : MISSION_MONITORING_FLAG_COLORS.RESOLVED;
};

// Sum of every value of key inside elements
export const sumKey = (elements, key) => {
  let count = 0;
  elements.forEach((element) => (count += element[key]));
  return count;
};

// Format date iso cloverfield
export const formatDate = (date) => {
  return date != null ? moment(date).format(POWERDASH_DATE_FORMAT) : null;
};

export const humanize = (str) => {
  return str ? str.replaceAll("_", " ") : str;
};

export const capitalize = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

// Base color of top level status
const getStatusBaseColor = (statusList, status) => {
  let statusColor = "";

  let status_tech = status.replaceAll(" ", "_");

  // If color of this status has been defined static
  if (MISSION_MONITORING_STATIC_STATUS.includes(status)) {
    statusColor = MISSION_MONITORING_STATIC_STATUS_COLORS[status_tech];
  } else {
    // Assign a new color from colorway to every other status
    const filteredStatus = statusList.filter(
      (st) => !MISSION_MONITORING_STATIC_STATUS.includes(st)
    );
    statusColor =
      MISSION_MONITORING_COLORWAY[
        filteredStatus.sort().indexOf(status) %
          MISSION_MONITORING_COLORWAY.length
      ];
  }

  return statusColor;
};
// Function that guarantees status color
// Returns the base color when status, substatus color when a status is selected
export const getStatusColor = (
  statusList,
  status,
  subStatus,
  selectedStatus
) => {
  if (statusList.includes(status))
    return getStatusBaseColor(statusList, status);

  if (selectedStatus && subStatus.length > 0) {
    const baseColor = getStatusBaseColor(statusList, selectedStatus) ?? "#fff";
    const range = [chroma(baseColor), chroma(baseColor).darken(2)];

    return chroma.scale(range).colors(subStatus.length)[
      subStatus.indexOf(status)
    ];
  } else {
    return getStatusBaseColor(statusList, status);
  }
};

export const extractTraitNameAndClasses = (name) => {
  const splitted = name.replaceAll("_", " ").split("|");
  const traitName = splitted.shift();
  const classes = splitted.map((c) => capitalize(c));
  return [traitName, classes];
};

// Compare arrays equality by converting them to JSON strings
// Use on small arrays only
export const compareArrays = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
