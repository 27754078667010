import "./selectList.css";

import { Badge, ListGroup, ListGroupItem } from "reactstrap";

import { HIPHEN_GREEN } from "../../../constants";
import PropTypes from "prop-types";

/*
 * Select list is the equivalent of React Select but custom and stylized
 * can be multiple isMulti
 */
export const SelectList = ({
  options,
  value,
  setValue,
  isMulti,
  isDisabled,
  displayControls,
}) => {
  const elementIsSelected = (element) => {
    if (isMulti) return value.find((v) => v.value === element.value);
    else return value === element.value;
  };
  const handleSelection = (element) => {
    if (isMulti)
      elementIsSelected(element)
        ? setValue(
            (value = value.filter((item) => item.value !== element.value))
          )
        : setValue([...value, element]);
    else setValue(element.value);
  };

  return (
    <>
      <ListGroup className="powerdash-select-list-group">
        {options.map((element) => (
          <ListGroupItem
            className="powerdash-select-list-item"
            onClick={() => handleSelection(element)}
            key={element.value ?? "∅"}
            disabled={isDisabled}
            title={element.label}
          >
            {elementIsSelected(element) && (
              <span className="powerdash-select-list-item-icon">
                <i className="fa fa-check"></i>
              </span>
            )}
            <span className="powerdash-select-list-item-text">
              {/* FIXME:  */}
              {element.label ?? "∅"}
            </span>
          </ListGroupItem>
        ))}
      </ListGroup>
      {isMulti && displayControls && !isDisabled && (
        <div className="select-list-controls">
          <Badge
            className="control-btn clickable"
            style={{ background: HIPHEN_GREEN }}
            onClick={() => setValue(options)}
          >
            All
          </Badge>
          <Badge
            className="control-btn clickable"
            style={{ background: "coral" }}
            onClick={() => setValue([])}
          >
            Empty
          </Badge>
        </div>
      )}
    </>
  );
};

SelectList.propTypes = {
  options: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
