import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { EXPERIMENT_STATUS } from "../constants.js";
import React from "react";

export default function ExperimentStatusModal({
  openExperimentStatusModal,
  setOpenExperimentStatusModal,
  saveExperimentStatus,
}) {
  return (
    <Modal
      isOpen={openExperimentStatusModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader>Update plot map status</ModalHeader>

      <ModalBody>
        <Row>
          <Col xs="12">
            <h5 className="px-2">
              Please, mark as COMPLETED when:
              <ul>
                <li>Plot map is drawn</li>
                <li>Plot map information is provided</li>
                <li>You made sure that everything is correct</li>
              </ul>
            </h5>
            <div className="alert alert-danger px-2 text-center" role="alert">
              Once data processing will start, status will update to
              SYNCHRONIZED, and you will no longer be able to modify it.
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() => {
                saveExperimentStatus(EXPERIMENT_STATUS.COMPLETED);
                setOpenExperimentStatusModal(false);
              }}
            >
              Mark as COMPLETED
            </Button>
          </Col>
          <Col xs="6">
            <Button
              color="secondary"
              className="w-100"
              onClick={() => {
                setOpenExperimentStatusModal(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
