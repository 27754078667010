import "./dropdown-icon.scss";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fullResetResultMap } from "../actions/resultMap";
import { logout } from "../actions/user";

class UserCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  handleLogout(event) {
    event.preventDefault();
    this.props.logout();
    this.props.fullResetResultMap();
  }

  render() {
    const toggle = () =>
      this.setState({ dropdownOpen: !this.state.dropdownOpen });

    return (
      <Dropdown
        className="dropdown-button"
        isOpen={this.state.dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle className="fa fa-fw fa-user-circle icon-logo" />
        <DropdownMenu>
          <DropdownItem header>{this.props.user.fullname}</DropdownItem>
          <DropdownItem divider className={"border-secondary"} />
          <DropdownItem onClick={this.handleLogout.bind(this)}>
            Log out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

UserCardComponent.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  fullResetResultMap: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.self,
  };
}

export default connect(mapStateToProps, { logout, fullResetResultMap })(
  UserCardComponent
);
