import "./varietyThumbnail.css";

import { Badge } from "reactstrap";
import { DEFAULT_BLACKLISTED_TRAITS } from "../../../../constants";
import { NULL_GROUP_LABEL } from "../../../constants";
import { PlotModal } from "../../../../trialDataviz/PlotModal";
import PropTypes from "prop-types";
import hiphenLogo from "../../../../static/img/Hiphen_logo_white.png";
import { truncateNumber } from "../../../../services/utils";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";

const TraitBadge = ({
  name,
  value,
  hiphen,
  isRating,
  highlightedProperty,
  setHighlightedProperty,
}) => {
  return (
    <Badge
      className={`trait-badge  ${isRating ? "rating" : ""} ${
        hiphen ? "hiphen" : ""
      } ${highlightedProperty === name ? "highlighted" : ""}`}
      onClick={() => {
        if (highlightedProperty !== name) setHighlightedProperty(name);
        else setHighlightedProperty(null);
      }}
    >
      <span className="name">
        {hiphen && (
          <img
            className="hiphen-logo me-2"
            src={hiphenLogo}
            alt="hiphen-logo"
          ></img>
        )}
        {isRating && <i className="fa fa-pencil" />} {name}
      </span>
      <span className={`value ${value == null ? "falsy" : ""}`}>
        {truncateNumber(value) ?? NULL_GROUP_LABEL}
      </span>
    </Badge>
  );
};

export const VarietyThumbnail = ({
  visualizedElement,
  features,
  getColor,
  traits,
  propertyFilter,
  getImage,
  highlightedProperty,
  setHighlightedProperty,
  horizontal,
  plotRatingConfig,
}) => {
  const color = getColor(visualizedElement);
  const blackListedProperties = [
    ...DEFAULT_BLACKLISTED_TRAITS,
    ...traits.map((trait) => trait.technical_name.toLowerCase()),
  ];

  const filteredFeatures = useMemo(
    () =>
      features.filter((feature) => feature.group === visualizedElement) ?? [],
    [features, visualizedElement]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const handlePrev = (event) => {
    event.stopPropagation();
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(filteredFeatures.length - 1);
  };
  const handleNext = (event) => {
    event.stopPropagation();
    setCurrentIndex(currentIndex + 1);
  };
  const selectedFeature = useMemo(
    () => filteredFeatures[currentIndex % filteredFeatures.length],
    [currentIndex, filteredFeatures]
  );

  const [image, setImage] = useState(null);
  const [imageIsLoading, setImageIsLoading] = useState(false);

  useEffect(() => {
    // TODO: prevent refetch
    const fetchImage = async (feature) => {
      setImageIsLoading(true);
      const image = await getImage(feature, "base");
      setImageIsLoading(false);
      setImage(image);
    };

    if (selectedFeature) fetchImage(selectedFeature);
  }, [getImage, selectedFeature]);

  const [plotModalIsOpen, setPlotModalOpen] = useState(false);

  const handleClosePlotModal = () => {
    setPlotModalOpen(false);
  };

  const plotRatingLabels = plotRatingConfig.map(({ label }) => label);

  return (
    <div
      className={`powerdash-component p-0 variety-thumbnail ${
        visualizedElement ? "" : "translucid"
      }
      `}
      style={{ background: color }}
    >
      <PlotModal
        src={image?.url}
        isOpen={plotModalIsOpen}
        toggle={handleClosePlotModal}
      />
      <div className="variety-thumbnail-title">{visualizedElement}</div>
      {visualizedElement ? (
        <div
          className={`variety-thumbnail-content ${
            horizontal ? "horizontal" : ""
          }`}
        >
          <div
            className={`variety-thumbnail-carousel clickable${
              imageIsLoading ? "loading" : ""
            }`}
            style={{
              backgroundImage: `url(${image?.url})`,
              backgroundColor: image?.blank ? "#C3C3C3" : "black",
            }}
            onClick={() => setPlotModalOpen(true)}
          >
            {!horizontal && (
              <div className="carousel-img-popover">
                <div className="carousel-img-popover-header">
                  plot {selectedFeature?.displayId}
                </div>
                <img src={image?.url} alt="" />
              </div>
            )}
            <div className="carousel-header">
              plot {selectedFeature?.displayId}
              {image && !image?.blank && (
                <a
                  href={image.url}
                  target="_blank"
                  rel="noreferrer"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Download image"
                  data-tooltip-place="top"
                >
                  <i className="fa fa-lg fa-download"></i>
                </a>
              )}
            </div>
            {filteredFeatures.length > 1 && (
              <>
                <div className="carousel-controls">
                  <div className="carousel-control" onClick={handlePrev}>
                    <i className="fa fa-lg fa-chevron-left"></i>
                  </div>
                  <div className="carousel-control" onClick={handleNext}>
                    <i className="fa fa-lg fa-chevron-right"></i>
                  </div>
                </div>
                <Badge className="carousel-index-badge">
                  {(currentIndex % filteredFeatures.length) + 1} /{" "}
                  {filteredFeatures.length}
                </Badge>
              </>
            )}
          </div>
          <div className="properties-masonry">
            {/* Hiphen traits */}
            {traits
              .filter(({ name }) =>
                name.toLowerCase().includes(propertyFilter.toLowerCase())
              )
              .map((trait) => (
                <TraitBadge
                  key={trait.technical_name}
                  name={trait.name}
                  value={selectedFeature?.properties[trait.technical_name]}
                  hiphen={true}
                  highlightedProperty={highlightedProperty}
                  setHighlightedProperty={setHighlightedProperty}
                />
              ))}
            {/* Other props */}
            {Object.keys(selectedFeature?.properties ?? {})
              .filter(
                (property) =>
                  !blackListedProperties.includes(property.toLowerCase())
              )
              .filter((key) =>
                key.toLowerCase().includes(propertyFilter.toLowerCase())
              )
              .map((key) => (
                <TraitBadge
                  key={key}
                  name={key}
                  isRating={plotRatingLabels.includes(key)}
                  value={selectedFeature?.properties[key]}
                  highlightedProperty={highlightedProperty}
                  setHighlightedProperty={setHighlightedProperty}
                />
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

TraitBadge.propTypes = {
  name: PropTypes.string.isRequired,
  setHighlightedProperty: PropTypes.func.isRequired,
  value: PropTypes.any,
  highlightedProperty: PropTypes.string,
  isRating: PropTypes.bool,
  hiphen: PropTypes.bool,
};

VarietyThumbnail.propTypes = {
  features: PropTypes.array.isRequired,
  getColor: PropTypes.func.isRequired,
  traits: PropTypes.array.isRequired,
  propertyFilter: PropTypes.string.isRequired,
  getImage: PropTypes.func.isRequired,
  setHighlightedProperty: PropTypes.func.isRequired,
  plotRatingConfig: PropTypes.array.isRequired,
  visualizedElement: PropTypes.string,
  highlightedProperty: PropTypes.string,
  horizontal: PropTypes.bool,
};
