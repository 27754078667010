import "./controlSection.css";

import { Button } from "reactstrap";
import { PropTypes } from "prop-types";
import Select from "react-select";
import { hasAnalyticsRole } from "../../../../users/rolesUtil";
import { setSelectedContract } from "../../../../actions/resultMap";
import { uniqueArrayOfObject } from "../../../utils";
import { useDispatch } from "react-redux";
import { useState } from "react";

export const ControlSection = (props) => {
  const {
    sortedUserTrials,
    onSelectTrial,
    trial,
    refreshing,
    selectedContract,
    contracts,
    toggleOffcanvas,
  } = props;

  const dispatch = useDispatch();

  // Pre filtered
  const filteredContracts = contracts.filter(({ roles }) =>
    hasAnalyticsRole(roles)
  );

  // Calculate companies from user contracts
  const companies = uniqueArrayOfObject(
    filteredContracts.map((contract) => contract.company),
    "id"
  );
  const companiesOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }));
  const [selectedCompany, setSelectedCompany] = useState();

  return (
    <div className="powerdash-component control-section">
      {companiesOptions.length > 1 && (
        <Select
          isClearable
          options={companiesOptions}
          value={selectedCompany}
          onChange={(value) => {
            setSelectedCompany(value);
            if (value)
              dispatch(
                setSelectedContract(
                  filteredContracts.find(
                    ({ company }) => company.id === value.value
                  )
                )
              );
          }}
          isDisabled={refreshing}
          placeholder="Filter by company"
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              fontSize: "large",
            }),
          }}
        />
      )}
      <Select
        options={
          selectedCompany
            ? filteredContracts.filter(
                ({ company }) => company.id === selectedCompany.value
              )
            : filteredContracts
        }
        value={selectedContract}
        id="contract"
        name="contract"
        onChange={(selectedOption) => {
          dispatch(setSelectedContract(selectedOption));
        }}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id.toString()}
        isDisabled={refreshing}
        placeholder="Select campaign"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: "large",
          }),
        }}
      />
      <Select
        options={sortedUserTrials.filter((trial) =>
          selectedContract?.sites.map(({ id }) => id).includes(trial.id)
        )}
        value={trial}
        id="trial"
        name="trial"
        onChange={(selectedOption) => onSelectTrial(selectedOption)}
        getOptionLabel={({ display_name }) => display_name}
        getOptionValue={({ id }) => id.toString()}
        isDisabled={refreshing || selectedContract == null}
        placeholder="Select site"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: "large",
          }),
        }}
      />
      {trial && (
        <Button
          id="advanced-filtering-button"
          className="hiphen-green-button"
          size="sm"
          onClick={toggleOffcanvas}
        >
          <i className="fa fa-filter" /> Advanced Filtering
        </Button>
      )}
    </div>
  );
};

ControlSection.propTypes = {
  sortedUserTrials: PropTypes.array.isRequired,
  onSelectTrial: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  toggleOffcanvas: PropTypes.func.isRequired,
  trial: PropTypes.object,
  selectedContract: PropTypes.object,
  contracts: PropTypes.array,
};
