import "./SelectionFloatingCard.css";

import { useDispatch, useSelector } from "react-redux";

import { FloatingCard } from "../../../../../../components/floatingCard/FloatingCard";
import PropTypes from "prop-types";
import { addFocusedVarieties } from "../../../../../../actions/resultMap";
import { useState } from "react";

const DISPLAY_LIMIT = 200;

export const SelectionFloatingCard = ({ plots }) => {
  const [visible, setVisible] = useState(false);
  const [previousPlots, setPreviousPlots] = useState(plots);

  const focusedVarieties = useSelector(
    ({ resultMap }) => resultMap.focusedVarieties
  );

  if (previousPlots !== plots) {
    setVisible(true);
    setPreviousPlots(plots);
  }

  const dispatch = useDispatch();

  return (
    <>
      {visible && (
        <FloatingCard hide={() => setVisible(false)}>
          <span className="selection-floating-card-title">
            <b>{plots.length}</b>
            Plots selected from chart
          </span>
          <div className="selection-floating-card-content">
            {plots
              .sort((a, b) => a.displayId.localeCompare(b.displayId))
              .slice(0, DISPLAY_LIMIT)
              .map((plot) => (
                <div
                  className={`selection-floating-card-content-item clickable ${
                    focusedVarieties.includes(plot.group) ? "discrete" : ""
                  }`}
                  onClick={() => dispatch(addFocusedVarieties([plot.group]))}
                  key={plot.displayId}
                >
                  {plot.displayId}
                </div>
              ))}
            {plots.length > DISPLAY_LIMIT && (
              <span>+ {plots.length - DISPLAY_LIMIT}</span>
            )}
          </div>
        </FloatingCard>
      )}
    </>
  );
};

SelectionFloatingCard.propTypes = {
  plots: PropTypes.array.isRequired,
};
