import { COMMON_PLOT_CONFIG } from "../../../constants";
import { Chart } from "./Chart";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { THEME } from "../../../../constants";
import { selectFeaturesFilteredOnExperimentAndLayer } from "../../../../selectors/resultMap";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const PLOT_STYLE = {
  width: "100%",
  height: "85%",
};

const layout = {
  autosize: true,
  margin: { l: 40, r: 30, t: 0, b: 20 },
  plot_bgcolor: "transparent",
  paper_bgcolor: "white",
  yaxis: {
    gridcolor: THEME.indicators,
    fixedrange: true,
  },
  xaxis: {
    gridcolor: THEME.indicators,
    fixedrange: true,
  },
  font: { color: THEME.indicators, size: 15 },

  colorway: THEME.baseColors,
};

export const HistogramChart = ({ title, trait, exportName }) => {
  const features = useSelector(selectFeaturesFilteredOnExperimentAndLayer);
  const displayData = useMemo(
    () => [
      {
        x: features.map((feature) => feature.properties[trait?.technical_name]),
        type: "histogram",
        hovertemplate: "%{x}<br>Amount: %{y}<extra></extra>",
      },
    ],
    [features, trait]
  );

  const config = useMemo(
    () => ({
      toImageButtonOptions: {
        filename: exportName,
      },
      ...COMMON_PLOT_CONFIG,
    }),
    [exportName]
  );

  return (
    <Chart
      icon="fa-bar-chart"
      title={title}
      missingData={features.length === 0 || !trait}
    >
      <Plot
        useResizeHandler
        data={displayData}
        layout={layout}
        config={config}
        style={PLOT_STYLE}
      />
    </Chart>
  );
};

HistogramChart.propTypes = {
  title: PropTypes.string.isRequired,
  trait: PropTypes.object.isRequired,
  exportName: PropTypes.string.isRequired,
};
