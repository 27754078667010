import "./filteringProfileManager.css";

import { ANALYTICS_EVENTS, THEME } from "../../../../../constants";
import {
  Alert,
  Badge,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createFilteringProfile,
  deleteFilteringProfile,
  resetFilteringProfile,
  setExperimentsFilter,
  setFilteringProfile,
  setLayerFilter,
  setModalitiesFilter,
} from "../../../../../actions/resultMap";
import {
  requestCreateFilteringProfile,
  requestDeleteFilteringProfile,
  requestFetchFilteringProfile,
} from "../../../../../services/backendRequests";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";

import { EditableLabel } from "../../../../../components/editableLabel/EditableLabel";
import { NULL_GROUP_LABEL } from "../../../../constants";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { ScopeItemSelector } from "./ScopeItemSelector/ScopeItemSelector";
import moment from "moment";
import { useTracking } from "../../../../../analytics";

const customStyles = {
  control: (provided, _) => ({
    ...provided,
    maxHeight: "90px",
  }),
  valueContainer: (provided, _) => ({
    ...provided,
    maxHeight: "80px",
    overflowY: "scroll",
  }),
  indicatorsContainer: (provided, _) => ({
    ...provided,
    maxHeight: "80px",
  }),
};

const CustomFilteringProfileOption = ({
  innerProps,
  isDisabled,
  label,
  value,
}) =>
  !isDisabled && (
    <div
      className="custom-filtering-profile-option clickable d-flex align-items-end justify-content-between"
      {...innerProps}
    >
      <div>
        <small>{moment(value.created_at).format("YYYY-MM-DD HH:mm")}</small>
        <br />
        <span>{label}</span>
      </div>
    </div>
  );

export const FilteringProfileManager = ({
  filteringProfile,
  toggleOffcanvas,
}) => {
  const [experiments, modalities, trial, filteringProfiles, uploadedData] =
    useSelector(({ resultMap }) => [
      resultMap.distinctExperiments,
      resultMap.distinctModalities,
      resultMap.trial,
      resultMap.filteringProfiles,
      resultMap.uploadedData,
    ]);
  const [name, setName] = useState(
    `Filtering Profile ${filteringProfiles.length + 1}`
  );
  const [alert, setAlert] = useState(null);
  const user = useSelector((state) => state.user);

  const filteringProfilesOptions = useMemo(
    () =>
      filteringProfiles.map((fp) => ({
        label: fp.name,
        value: fp,
      })),
    [filteringProfiles]
  );

  const layers = useSelector((state) => state.resultMap.distinctLayers);
  const {
    modalities: selectedModalities,
    experiments: selectedExperiments,
    layer: selectedLayer,
  } = useSelector((state) => state.resultMap.filters);

  const dispatch = useDispatch();

  const setSelectedExperiments = (experiments) => {
    dispatch(setExperimentsFilter(experiments));
  };

  const setSelectedModalities = (modalities) => {
    dispatch(setModalitiesFilter(modalities));
  };

  const setSelectedLayer = (layer) => {
    dispatch(setLayerFilter(layer));
  };

  const handleSelectAllExperiments = () => {
    if (selectedExperiments.length === experimentsOptions.length) {
      // If all items are already selected, deselect them
      setSelectedExperiments([]);
    } else {
      // Otherwise, select all items
      const allExperiments = experimentsOptions.map(
        (experiment) => experiment.value
      );
      setSelectedExperiments(allExperiments);
    }
  };
  const handleSelectAllModalities = () => {
    if (selectedModalities.length === modalityOptions.length) {
      // If all items are already selected, deselect them
      setSelectedModalities([]);
    } else {
      // Otherwise, select all items
      const allModalities = modalityOptions.map((modality) => modality.value);
      setSelectedModalities(allModalities);
    }
  };

  const modalityOptions = modalities.map((modality) => {
    return { label: modality ?? NULL_GROUP_LABEL, value: modality };
  });

  const experimentsOptions = experiments.map((experiment) => {
    return { label: experiment ?? NULL_GROUP_LABEL, value: experiment };
  });

  const layersOptions = layers.map((layer) => {
    return { label: layer, value: layer };
  });

  const handleLoadFilteringProfile = async (id) => {
    const filteringProfile = await requestFetchFilteringProfile(user, id);
    dispatch(setFilteringProfile(filteringProfile));
  };
  const { trackEvent } = useTracking();

  const handleCreateFilteringProfile = async () => {
    const newFilteringProfile = {
      name: name,
      scope: {
        experiments: selectedExperiments,
        modalities: selectedModalities,
        layer: selectedLayer,
        uses_imported_data: uploadedData !== null,
      },
      filters: [],
      blacklist: [],
      whitelist: [],
    };
    try {
      const res = await requestCreateFilteringProfile(
        user,
        trial,
        newFilteringProfile
      );
      dispatch(createFilteringProfile(res));
      setAlert(null);
    } catch (err) {
      setAlert(err);
    }

    trackEvent(ANALYTICS_EVENTS.AF_SAVE_SCOPE);
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleModal = () => setIsDeleteModalOpen((prev) => !prev);

  return (
    <div
      className={`filtering-profile-manager p-2 pb-0 ${
        !filteringProfile.scope ? "focused" : ""
      }`}
    >
      <Modal isOpen={isDeleteModalOpen} toggle={toggleModal}>
        <ModalHeader>Delete current filtering profile ?</ModalHeader>
        <ModalBody toggle={toggleModal}>
          This operation is irreversible
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              requestDeleteFilteringProfile(user, filteringProfile);
              dispatch(deleteFilteringProfile(filteringProfile));
              toggleModal();
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex gap-2 align-items-center justify-content-between mb-1 pe-2">
        <div className="filtering-profile-manager-header d-flex gap-2 align-items-center">
          <Button
            className="button-new"
            onClick={() => {
              // First it resets the filtering profile
              dispatch(resetFilteringProfile());
              setName(`Filtering Profile ${filteringProfiles.length + 1}`);
            }}
          >
            <i className="fa fa-plus" />
          </Button>
          <ReactSelect
            className="flex-grow-1"
            placeholder="Load filtering profile"
            value={filteringProfilesOptions.find(
              (e) => e.value.id === filteringProfile.id
            )}
            options={filteringProfilesOptions}
            onChange={({ value }) => handleLoadFilteringProfile(value.id)}
            components={{ Option: CustomFilteringProfileOption }}
          />
          {Boolean(filteringProfile.id) && (
            <i
              className="fa fa-lg fa-trash discrete-icon clickable"
              onClick={toggleModal}
            />
          )}
        </div>
        <i
          className="fa fa-lg fa-times discrete-icon clickable"
          onClick={toggleOffcanvas}
        />
      </div>
      {!Boolean(filteringProfile.id) && (
        <EditableLabel value={name} onChange={setName} />
      )}
      <div id="advanced-filtering-scope-selector" className="p-2 pt-1">
        <Label>
          Scope{" "}
          {filteringProfile.scope ? (
            <i className="fa fa-lock" />
          ) : (
            <span className="tip">
              please define a set of pre-filtered plots before applying more
              filters
            </span>
          )}
        </Label>
        {!filteringProfile.scope ? (
          <>
            <div className="scope-selection pt-1 p-2">
              <ScopeItemSelector
                labelContent="Experiments"
                selected={selectedExperiments}
                options={experimentsOptions}
                handleSelectAll={handleSelectAllExperiments}
              />
              <ReactSelect
                value={selectedExperiments.map((experiment) => ({
                  label: experiment ?? NULL_GROUP_LABEL,
                  value: experiment,
                }))}
                onChange={(elements) =>
                  setSelectedExperiments(
                    elements ? elements.map((element) => element.value) : []
                  )
                }
                options={experimentsOptions}
                closeMenuOnSelect={false}
                maxHeight={3}
                displayControls
                styles={customStyles}
                isMulti
              />

              <ScopeItemSelector
                labelContent="Modalities"
                selected={selectedModalities}
                options={modalityOptions}
                handleSelectAll={handleSelectAllModalities}
              />
              <ReactSelect
                value={selectedModalities.map((modality) => ({
                  label: modality ?? NULL_GROUP_LABEL,
                  value: modality,
                }))}
                onChange={(elements) =>
                  setSelectedModalities(
                    elements ? elements.map((element) => element.value) : []
                  )
                }
                styles={{
                  ...customStyles,
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 3,
                      backgroundColor: data.value
                        ? `${THEME.modalityColorHash.hex(data.value)}99`
                        : "#E6E6E6",
                    };
                  },
                }}
                options={modalityOptions}
                closeMenuOnSelect={false}
                maxHeight={3}
                displayControls
                isMulti
              />

              {layersOptions.length > 0 && (
                <>
                  <label className="my-1">Layer</label>
                  <ReactSelect
                    value={{ label: selectedLayer, value: selectedLayer }}
                    isDisabled={layersOptions.length === 0}
                    onChange={({ value }) => setSelectedLayer(value)}
                    options={layersOptions}
                    maxHeight={3}
                    displayControls
                  />
                </>
              )}
            </div>
            <div className="pt-2 d-flex justify-content-between align-items-center">
              <small className="text-danger">{alert}</small>
              <Button
                disabled={
                  selectedExperiments.length === 0 ||
                  selectedModalities.length === 0
                }
                className="hiphen-green-button"
                size="sm"
                onClick={handleCreateFilteringProfile}
              >
                <i className="fa fa-lock" /> Save scope & Start filtering
              </Button>
            </div>
          </>
        ) : (
          <div className="scope-summary p-2 d-flex gap-2 flex-column">
            <div>
              <i className="fa fa-braille" />{" "}
              {filteringProfile.scope.experiments.length}{" "}
              {`experiment${
                filteringProfile.scope.experiments.length === 1 ? "" : "s"
              }`}
              <div className="stack">
                {filteringProfile.scope.experiments.map((experiment) => (
                  <Badge
                    key={experiment ?? NULL_GROUP_LABEL}
                    className="hiphen-badge small ms-1 mt-1"
                  >
                    {experiment ?? NULL_GROUP_LABEL}
                  </Badge>
                ))}
              </div>
            </div>
            <div>
              <i className="fa fa-eyedropper" />{" "}
              {filteringProfile.scope.modalities.length}{" "}
              {`modalit${
                filteringProfile.scope.modalities.length === 1 ? "y" : "ies"
              }`}
              <div className="stack">
                {filteringProfile.scope.modalities.map((modality) => (
                  <Badge
                    key={modality ?? NULL_GROUP_LABEL}
                    className="small ms-1 mt-1"
                    // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
                    color="invalid background color"
                    style={{
                      border: `1px solid ${
                        modality
                          ? THEME.modalityColorHash.hex(modality)
                          : "darkgray"
                      }`,
                      background: `${
                        modality
                          ? THEME.modalityColorHash.hex(modality)
                          : "#E4E4E4"
                      }55`,
                      color: "black",
                      fontWeight: "initial",
                    }}
                  >
                    {modality ?? NULL_GROUP_LABEL}
                  </Badge>
                ))}
              </div>
            </div>
            {filteringProfile.scope.layer && (
              <div>
                <i className="fa fa-bars" /> {filteringProfile.scope.layer}
              </div>
            )}

            {filteringProfile.scope.uses_imported_data && (
              <Alert color="warning" className="p-2 mb-0">
                <i className="fa fa-warning" /> This filtering profile is
                defined using dynamically uploaded data. You might encounter
                inconsistencies if the current data differ from the one used in
                this scope.
              </Alert>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FilteringProfileManager.propTypes = {
  toggleOffcanvas: PropTypes.func.isRequired,
  filteringProfile: PropTypes.object.isRequired,
};
