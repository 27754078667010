import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./index.css";

import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";

import Administration from "./administration";
import AdministrationCompany from "./administration/companies/company";
import AdministrationContract from "./administration/contract";
import { AnalyticsDashboard } from "./powerdash/analyticsDashboard/index";
import AskPasswordReset from "./password/askPasswordReset";
import ClientContact from "./contact";
import ClientDashboard from "./clientDashboard";
import ErrorBoundary from "./components/errorBoundary";
import Experiments from "./experiments";
import { Glossary } from "./glossary/Glossary";
import Landing from "./landing";
import { Legals } from "./legals";
import { MissionMonitoringDashboard } from "./powerdash/missionMonitoringDashboard";
import { Page404 } from "./components/page404";
import PasswordEdition from "./password/edit";
import PendingAccess from "./components/pendingAccess";
import { Provider } from "react-redux";
import RefreshUser from "./components/refreshUser";
import { Tooltip } from "react-tooltip";
import { TrackingProvider } from "./analytics";
import TrialDataviz from "./trialDataviz";
import Upload from "./upload";
import UploadSession from "./upload/session";
import UploadSessions from "./uploadsessions";
import Users from "./users";
import _ from "lodash";
import { clearAllAlerts } from "./actions/alerts";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { createBrowserHistory } from "history";
import createRootReducer from "./reducers";
import { disableConsole } from "./remove-console";
import { hasBackofficeAccess } from "./services/roles";
import missionMonitoringResults from "./powerdash/missionMonitoringResults/missionMonitoringResults";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

/* DEV: Display store for redux pluggin */
/* const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; */
const composeEnhancers = compose;
const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(middleware, thunk))
);

history.listen(() => store.dispatch(clearAllAlerts()));

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: "/",
  authenticatedSelector: (state) => {
    return !_.isEmpty(state.user.self);
  },
  wrapperDisplayName: "UserIsAuthenticated",
});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, query) => {
    const location = query.location;

    let params = new URLSearchParams(location.search);
    const redirect = params.get("redirect");

    if (redirect) return redirect;
    return hasBackofficeAccess(state.user.self) ? "/administration" : "/map";
  },
  allowRedirectBack: false,
  authenticatedSelector: (state) => {
    return _.isEmpty(state.user.self);
  },
  wrapperDisplayName: "UserIsNotAuthenticated",
  redirectQueryParamName: false,
});

const userHasAccess = connectedRouterRedirect({
  redirectPath: "/pendingaccess",
  authenticatedSelector: (state) => {
    return !_.isEmpty(state.user.self) && state.user.self.access;
  },
  wrapperDisplayName: "UserHasAccess",
});

const userIsBackoffice = connectedRouterRedirect({
  redirectPath: "/pendingaccess",
  authenticatedSelector: (state) => {
    return !_.isEmpty(state.user.self) && hasBackofficeAccess(state.user.self);
  },
  wrapperDisplayName: "UserIsBackoffice",
});

// Common pages
const LandingPage = userIsNotAuthenticated(Landing);
const PasswordEditionPage = userIsNotAuthenticated(PasswordEdition);
const AskPasswordResetPage = userIsNotAuthenticated(AskPasswordReset);
const PendingPage = userIsAuthenticated(PendingAccess);
const UsersPage = userIsAuthenticated(userIsBackoffice(Users));
const UploadSessionsPage = userIsAuthenticated(
  userIsBackoffice(UploadSessions)
);
const ExperimentsPage = userIsAuthenticated(userIsBackoffice(Experiments));

// BackOffice Administration Pages
const AdministrationPage = userIsAuthenticated(
  userIsBackoffice(Administration)
);
const AdministrationCompanyPage = userIsAuthenticated(
  userIsBackoffice(AdministrationCompany)
);
const AdministrationContractPage = userIsAuthenticated(
  userIsBackoffice(AdministrationContract)
);
const TrialDatavizPage = userIsAuthenticated(userHasAccess(TrialDataviz));
const ClientContactPage = userIsAuthenticated(userHasAccess(ClientContact));
const ClientDashboardPage = userIsAuthenticated(userHasAccess(ClientDashboard));
const GlossaryPage = userIsAuthenticated(userHasAccess(Glossary));
const UploadPage = userIsAuthenticated(userHasAccess(Upload));
const UploadSessionPage = userIsAuthenticated(userHasAccess(UploadSession));
const MissionMonitoringDashboardPage = userIsAuthenticated(
  userHasAccess(MissionMonitoringDashboard)
);

const MissionMonitoringResultPage = userIsAuthenticated(
  userHasAccess(missionMonitoringResults)
);
const AnalyticsDashboardPage = userIsAuthenticated(
  userHasAccess(AnalyticsDashboard)
);

// eslint-disable-next-line max-lines-per-function
export default function App() {
  const [rehydrated, setRehydrated] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") disableConsole();
    persistStore(store, null, () => setRehydrated(true));
  });

  return !rehydrated ? (
    <div>Loading...</div>
  ) : (
    <Provider store={store}>
      <ErrorBoundary>
        <RefreshUser>
          <TrackingProvider>
            <ConnectedRouter history={history}>
              <Switch>
                <Route component={LandingPage} exact path="/" />
                <Route component={UploadPage} exact path="/upload" />
                <Route
                  component={UploadSessionPage}
                  exact
                  path="/upload/:sessionId"
                />
                <Route component={UsersPage} exact path="/users" />
                <Route
                  component={UploadSessionsPage}
                  exact
                  path="/uploadsessions"
                />
                <Route component={ExperimentsPage} exact path="/plot_maps" />
                <Route
                  component={AdministrationPage}
                  exact
                  path="/administration"
                />
                <Route
                  component={AdministrationCompanyPage}
                  exact
                  path="/administration/company/:companyId"
                />
                <Route
                  component={AdministrationContractPage}
                  exact
                  path="/administration/company/:companyId/contract/:contractId"
                />
                <Route
                  component={TrialDatavizPage}
                  path="/map/:trialId?/:date?"
                />
                <Route
                  component={ClientDashboardPage}
                  exact
                  path="/clientDashboard"
                />
                <Route component={ClientContactPage} exact path="/contact" />
                <Route component={GlossaryPage} exact path="/glossary" />
                <Route
                  component={AnalyticsDashboardPage}
                  exact
                  path="/analyticsdashboard"
                />
                <Route
                  component={AskPasswordResetPage}
                  path="/password/askPasswordReset"
                />
                <Route component={PasswordEditionPage} path="/password/edit" />
                <Route component={PendingPage} exact path="/pendingaccess" />
                <Route
                  component={MissionMonitoringDashboardPage}
                  exact
                  path="/monitoring"
                />
                <Route
                  component={MissionMonitoringResultPage}
                  exact
                  path="/monitoring/:mmId"
                />
                <Route component={Legals} exact path="/legals" />
                <Route component={Page404} path="*" />
              </Switch>
            </ConnectedRouter>
          </TrackingProvider>
        </RefreshUser>
        <Tooltip
          id="tooltip"
          className="tooltip-custom"
          place="bottom"
          variant="success"
        />
      </ErrorBoundary>
    </Provider>
  );
}
