import "./dropdown-icon.scss";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import React, { useState } from "react";

import { Link } from "react-router-dom";

export const HelpDropdown = () => {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <Dropdown
      className="dropdown-button"
      isOpen={openDropdown}
      toggle={() => setOpenDropdown(!openDropdown)}
    >
      <DropdownToggle className="fa fa-fw fa-question-circle icon-logo" />
      <DropdownMenu>
        <DropdownItem>
          <Link className="text-decoration-none" to="/contact">
            Contact us
          </Link>
        </DropdownItem>
        <DropdownItem divider className={"border-secondary"} />
        <DropdownItem>
          <Link className="text-decoration-none" to="/legals">
            Legals
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
