import { Badge } from "reactstrap";
import { HighlightedText } from "../../../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { extractTraitNameAndClasses } from "../../../utils";
import { useMemo } from "react";

export function TraitClassesBadge({ input, option }) {
  const [traitName, classes] = useMemo(() => {
    return extractTraitNameAndClasses(option);
  }, [option]);

  return (
    <>
      <HighlightedText text={traitName} highlight={input} />
      {classes.map((label) => (
        <Badge key={label} className="hiphen-badge small">
          <HighlightedText text={label} highlight={input} />
        </Badge>
      ))}
    </>
  );
}
TraitClassesBadge.propTypes = {
  input: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
};
