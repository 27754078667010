import "./orthoSelector.css";

import { useEffect, useRef } from "react";

import { DomEvent } from "leaflet";

export const OrthoSelector = ({
  orthos,
  selectedOrtho,
  changeSelectedOrtho,
  refreshing,
}) => {
  const refContainer = useRef(null);
  useEffect(() => {
    DomEvent.disableScrollPropagation(refContainer.current);
  }, []);

  return (
    <div
      className="ortho-selector powerdash-component transparent"
      ref={refContainer}
    >
      {orthos
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map((ortho) => (
          <div className="d-inline-block mt-1" key={ortho}>
            <div
              disabled={refreshing}
              onClick={() => changeSelectedOrtho(ortho)}
              className={
                "ortho-button clickable" +
                (ortho === selectedOrtho ? " selected" : "")
              }
              data-tooltip-id="tooltip"
              data-tooltip-content={ortho}
              data-tooltip-place="right"
            >
              {ortho}
            </div>
          </div>
        ))}
    </div>
  );
};
