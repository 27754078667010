import "./ScopeItemSelector.scss";

import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

export const ScopeItemSelector = ({
  labelContent,
  selected,
  options,
  handleSelectAll,
}) => {
  return (
    <div className="scope-item-selector">
      <label className="my-1">{labelContent}</label>
      <span>
        {selected.length}/{options.length}
      </span>
      <Button
        className="select-button"
        onClick={handleSelectAll}
        disabled={!options.length}
      >
        {selected.length === options.length ? "Unselect all" : "Select all"}
      </Button>
    </div>
  );
};

ScopeItemSelector.propTypes = {
  labelContent: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
};
