import React, { useMemo } from "react";

import { Badge } from "reactstrap";
import { PropTypes } from "prop-types";
import Select from "react-select";

const stringException = [
  "genotype",
  "modality",
  "layer",
  "experiment",
  "control_variety",
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "120px",
  }),
};

export function UpdatedColumn({
  header,
  handleSelectChange,
  count,
  isNumberCastable,
  headerType,
}) {
  const options = useMemo(
    () => [
      { value: "Text", label: "Text" },
      {
        value: "Number",
        label: "Number",
        isDisabled: !isNumberCastable,
      },
    ],
    [isNumberCastable]
  );

  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <span>{header}</span>
      <div className="d-flex justify-content-between align-items-center gap-2">
        <Badge
          className="hiphen-badge small opacity-50"
          data-tooltip-id="tooltip"
          data-tooltip-html="Number of values"
        >
          {count}
        </Badge>
        <Select
          isDisabled={stringException.includes(header)}
          styles={customStyles}
          options={options}
          value={options.find((option) => option.value === headerType)}
          onChange={(selectedOption) =>
            handleSelectChange(header, selectedOption)
          }
        />
      </div>
    </div>
  );
}

UpdatedColumn.propTypes = {
  header: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  isNumberCastable: PropTypes.bool.isRequired,
  headerType: PropTypes.string.isRequired,
};
