import { HIPHEN_GREEN, THEME } from "../../../../constants";

import { COMMON_PLOT_CONFIG } from "../../../constants";
import { Chart } from "./Chart";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { selectFilteredFeatures } from "../../../../selectors/resultMap";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const PLOT_STYLE = {
  width: "100%",
  height: "85%",
};

export const ModalityProfiler = ({ title, trait, exportName }) => {
  const features = useSelector(selectFilteredFeatures);
  const modalities = useSelector(
    ({ resultMap }) => resultMap.distinctModalities
  );

  const displayData = useMemo(() => {
    const modalitiesData = {};
    modalities.forEach((modality) => {
      modalitiesData[modality ?? ""] = [];
    });

    features.forEach((feature) => {
      const { modality } = feature;
      const value = feature.properties[trait.technical_name];
      if (value) modalitiesData[modality].push(value);
    });

    return Object.entries(modalitiesData).map(([modality, values]) => ({
      y: values,
      type: "box",
      name: modality,
      marker: {
        color: modality ? THEME.modalityColorHash.hex(modality) : HIPHEN_GREEN,
      },
    }));
  }, [features, modalities, trait.technical_name]);

  const layout = useMemo(
    () => ({
      autosize: true,
      margin: { r: 30, t: 0, b: 20 },
      plot_bgcolor: "transparent",
      paper_bgcolor: "white",
      yaxis: {
        title: {
          text: trait.name,
        },
        gridcolor: THEME.indicators,
        fixedrange: true,
        automargin: true,
      },
      xaxis: {
        automargin: true,
        gridcolor: THEME.indicators,
        fixedrange: true,
        type: "category",
      },
      font: { color: THEME.indicators, size: 15 },
      showlegend: false,
    }),
    [trait.name]
  );

  const config = useMemo(
    () => ({
      toImageButtonOptions: {
        filename: exportName,
      },
      ...COMMON_PLOT_CONFIG,
    }),
    [exportName]
  );

  return (
    <Chart icon="fa-tint" title={title}>
      <Plot
        useResizeHandler
        data={displayData}
        layout={layout}
        config={config}
        style={PLOT_STYLE}
      />
    </Chart>
  );
};

ModalityProfiler.propTypes = {
  title: PropTypes.string.isRequired,
  trait: PropTypes.object.isRequired,
  exportName: PropTypes.string.isRequired,
};
