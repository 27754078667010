import "./glossary.css";

import { Badge, Card } from "reactstrap";
import React, { useMemo } from "react";

import DEFAULT_TRAIT_ICON from "../static/img/default_trait_icon.png";
import PropTypes from "prop-types";
import { extractTraitNameAndClasses } from "../powerdash/utils";

/*
 * TraitDisplayCard is used to display the trait information in various contexts
 * such as on hover in traitSelector
 */
export const TraitDisplayCard = ({ trait }) => {
  const [traitName, traitClasses] = useMemo(() => {
    return extractTraitNameAndClasses(trait.name);
  }, [trait]);
  return (
    <Card className="powerdash-component trait-display-card">
      <div className="trait-display-card-header">
        <img
          src={trait.traitGroup.icon ?? DEFAULT_TRAIT_ICON}
          alt={trait.traitGroup.name}
        />
        <div className="trait-display-card-header-text">
          <span className="trait-display-card-header-trait-group">
            {trait.traitGroup.name}
          </span>
          <span className="trait-display-card-header-trait">{traitName}</span>
          <div>
            {traitClasses.map((label) => (
              <Badge
                className="hiphen-badge small me-1 mt-1 opacity-75"
                key={label}
              >
                {label}
              </Badge>
            ))}
          </div>
        </div>
      </div>
      <div className="trait-display-card-boxes">
        <div>
          <span>TYPE</span>
          <span>{trait.type}</span>
        </div>
        <div>
          <span>UNIT</span>
          <span>{trait.unit}</span>
        </div>
        <div>
          <span>BBCH</span>
          <span>
            {trait.MinBBCHStage.stage} - {trait.MaxBBCHStage.stage}
          </span>
        </div>
      </div>
      <div className="trait-display-card-description">
        <p>{trait.description}</p>
      </div>
    </Card>
  );
};

TraitDisplayCard.propTypes = {
  trait: PropTypes.object.isRequired,
};
