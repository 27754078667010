import { ADD_ALERT, CLEAR_ALL_ALERTS, REMOVE_ALERT } from "./actionTypes";

export function addAlert(style, message) {
  return { type: ADD_ALERT, message, style };
}

export function addDangerAlert(message) {
  return addAlert("danger", message);
}

export function addSuccessAlert(message) {
  return addAlert("success", message);
}

export function removeAlert(index) {
  return { type: REMOVE_ALERT, index };
}

export function clearAllAlerts() {
  return { type: CLEAR_ALL_ALERTS };
}
