import { Card, CardBody, Container } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AlertComponent from "../components/alert";
import NavbarComponent from "../components/navbar";
import NewUploadForm from "./NewUploadForm";
import _ from "lodash";
import { jsonContentType } from "../services/backendRequests";
import queryString from "query-string";
import { useFetch } from "../hooks/useFetch";
import { useSelector } from "react-redux";

export default function Upload() {
  const user = useSelector((state) => state.user);

  const history = useHistory();
  const location = useLocation();
  const { contractId, siteId } = queryString.parse(location.search);

  const [contractsResponse] = useFetch("/api/client/contract_infos");
  const [contract, setContract] = useState(contractId || "");
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (contractsResponse) {
      const dataContracts = _.filter(
        contractsResponse,
        (c) => c.upload_template != null
      );
      setContracts(dataContracts.map((c) => ({ label: c.name, value: c.id })));
    }
  }, [contractsResponse]);

  const [site, setSite] = useState(siteId || "");
  const [sites, setSites] = useState([]);
  useEffect(() => {
    if (!contractsResponse || !contract) return;

    const siteOptions = contractsResponse
      .find((c) => c.id === contract)
      .sites.map((s) => ({ label: s.display_name, value: s.id }));
    setSites(siteOptions);
  }, [contractsResponse, contract]);

  const [acquisitionDate, setAcquisitionDate] = useState("");

  const [sessions] = useFetch(`/api/client/sites/${site}/upload_sessions`, {
    skip: site.length === 0,
  });

  return (
    <div className="wrapper">
      <NavbarComponent />
      <AlertComponent />

      <div className="page-content below-breadcrumb container-fluid">
        <Container style={{ maxWidth: "450px" }}>
          <div className="card p-3">
            {contracts.length > 0 ? (
              <NewUploadForm
                {...{
                  contract,
                  contracts,
                  setContract,
                  site,
                  sites,
                  setSite,
                  sessions: sessions ?? [],
                  acquisitionDate,
                  setAcquisitionDate,
                  onCreate: () => {
                    if (!site || !acquisitionDate) return;

                    fetch(`/api/client/sites/${site}/upload_sessions`, {
                      method: "POST",
                      body: JSON.stringify({
                        acquisition_date: acquisitionDate,
                      }),
                      headers: jsonContentType(user.identity),
                    })
                      .then((r) => r.json())
                      .then((json) => history.push(`/upload/${json.id}`));
                  },
                  onEdit: ({ id }) => history.push(`/upload/${id}`),
                }}
              />
            ) : (
              <div className="text-center">
                <h5>No campaign allows you to upload data.</h5>
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}
