import React, { Component } from "react";
import AlertComponent from "../components/alert";
import NavbarComponent from "../components/navbar";
import { Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PageTitle from "../components/pageTitle";
import { sendContactMail } from "../actions/user";

class ClientHelp extends Component {
  constructor(props) {
    super(props);
    this.mail = React.createRef();
    this.object = React.createRef();
    this.content = React.createRef();
  }

  isValidForm() {
    return (
      this.mail.current.checkValidity() &&
      this.object.current.checkValidity() &&
      this.content.current.checkValidity()
    );
  }

  handleSubmit(event) {
    if (this.isValidForm()) {
      event.preventDefault();
      const clientRequest = {
        reply_to: this.mail.current.value,
        subject: this.object.current.value,
        body: this.content.current.value
      };
      this.props.sendContactMail(clientRequest);
    }
  }

  render() {
    return (
      <div className="wrapper">
        <NavbarComponent />
        <AlertComponent />

        <div className="page-content container-fluid">
          <PageTitle title="Contact us" />
          <form id="contactForm">
            <Row>
              <Col xs="12">
                <div className="section mx-auto">
                  <Row className="mt-2">
                    <Col xs="12">
                      <label htmlFor="mail">Mail *</label>
                      <input
                        type="text"
                        ref={this.mail}
                        id="mail"
                        defaultValue={this.props.user.email}
                        required
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="12">
                      <label htmlFor="object">Subject *</label>
                      <input
                        type="text"
                        ref={this.object}
                        id="object"
                        required
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="12">
                      <label htmlFor="content">Content *</label>
                      <textarea
                        rows="5"
                        ref={this.content}
                        id="content"
                        required
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="12">
                      <Button
                        color="primary"
                        className="btn action-btn pull-right"
                        type="submit"
                        form="contactForm"
                        onClick={event => {
                          this.handleSubmit(event);
                        }}
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    user: store.user.self
  };
}

const callbacks = {
  sendContactMail
};

export default connect(mapStateToProps, callbacks)(ClientHelp);

ClientHelp.propTypes = {
  user: PropTypes.object.isRequired,
  sendContactMail: PropTypes.func.isRequired
};
