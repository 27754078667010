import "./alert.css";

import { Alert, Button } from "reactstrap";
import React, { Component } from "react";
import { clearAllAlerts, removeAlert } from "../actions/alerts";

import PropTypes from "prop-types";
import { connect } from "react-redux";

class AlertComponent extends Component {
  constructor() {
    super();
    this.onDismiss = this.onDismiss.bind(this);
    this.handleRemoveAlerts = this.handleRemoveAlerts.bind(this);
  }

  onDismiss(event, index) {
    event.preventDefault();
    this.props.removeAlert(index);
  }

  handleRemoveAlerts() {
    this.props.clearAllAlerts();
  }

  render() {
    if (!this.props.alerts.length) {
      return null;
    }
    return (
      <div className="fixed-bottom mx-auto w-75 mb-5 pt-3 alert-container">
        {this.props.alerts.map((alert, index) => {
          return (
            <Alert
              className="mx-3 mb-1"
              toggle={(event) => this.onDismiss(event, index)}
              color={alert.style}
              key={`alert-${index.toString()}`}
              fade={false}
            >
              {alert.message}
            </Alert>
          );
        })}

        <div className="mx-3 text-end">
          <Button color="link" onClick={this.handleRemoveAlerts}>
            <small className="text-muted">Remove all alerts</small>
          </Button>
        </div>
      </div>
    );
  }
}

AlertComponent.propTypes = {
  alerts: PropTypes.array.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    alerts: state.alerts.all,
  };
}

export default connect(mapStateToProps, { removeAlert, clearAllAlerts })(
  AlertComponent
);
