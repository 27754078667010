/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-fragments */
import "./legendColorScale.css";

import { Button, Col, Input, Row } from "reactstrap";
import React, { Component } from "react";
import { setColorOpacity, setColorScaleStep } from "../actions/resultMap";

import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { selectColorScale } from "../selectors/resultMap";
import { truncateNumber } from "../services/utils";

class LegendColorScale extends Component {
  state = {
    stepValue: this.props.colorScale.step,
    expand: false,
    opacity: this.props.opacity,
    opacityEnabled: false,
  };

  handleChange(step) {
    if (step !== this.props.colorScale.step) this.props.setColorScaleStep(step);
  }

  handleOpacityChange(opacity) {
    if (opacity === this.props.opacity) return;
    this.props.setColorOpacity(opacity);
  }

  editScale() {
    this.setState({
      expand: !this.state.expand,
    });
  }

  render() {
    const opacityIcon = this.state.opacityEnabled ? "eye-slash" : "eye";
    const scaleMin = this.props.colorScale.minValue;
    const scaleMax = this.props.colorScale.maxValue;
    if ((scaleMin !== 0 && !scaleMin) || (scaleMax !== 0 && !scaleMax))
      return "";

    return (
      <React.Fragment>
        <div className="customControlBottomLeft mt-2">
          <Row>
            <Col xs="10 pe-1">
              <Input
                type="range"
                value={this.state.opacity}
                onChange={(e) => this.setState({ opacity: e.target.value })}
                min={0}
                max={1}
                step={0.1}
                disabled={this.state.opacityEnabled}
                onMouseUp={(e) => this.handleOpacityChange(e.target.value)}
                data-tooltip-id="tooltip"
                data-tooltip-content="Change opacity"
              />
            </Col>
            <Col xs="2 ps-0 my-auto">
              <Button
                data-tooltip-id="tooltip"
                data-tooltip-content="Change opacity"
                active={this.state.opacityEnabled}
                className="py-0 px-1 w-100 h-100 btn-hiphen"
                onClick={() => {
                  const isEnabled = this.state.opacityEnabled;
                  this.handleOpacityChange(isEnabled ? this.state.opacity : 0);
                  this.setState({ opacityEnabled: !isEnabled });
                }}
              >
                <i className={`fa fa-${opacityIcon}`} />
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xs="10 pe-1">
              <Row>
                {this.state.expand && (
                  <Col xs="12">
                    <div className="my-2">
                      <Input
                        type="range"
                        id="step"
                        name="step"
                        list="tickmarks"
                        min="2"
                        max="10"
                        step="1"
                        value={this.state.stepValue}
                        onChange={(e) =>
                          this.setState({ stepValue: e.target.value })
                        }
                        onMouseUp={(e) => this.handleChange(e.target.value)}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Change number of class"
                      />
                    </div>
                  </Col>
                )}
                <Col xs="12">
                  <Row>
                    <Col xs="12">
                      {_.map(Array(10), (e, index) => (
                        <span
                          key={index}
                          className="grad-step m-0 p-0 w-10"
                          style={{
                            backgroundColor: this.props.colorScale.func(
                              scaleMin + (index / 10) * (scaleMax - scaleMin)
                            ),
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs="12">
                      <span className="label-legend float-start">
                        {truncateNumber(scaleMin)}
                      </span>
                      <span className="label-legend float-start text-center">
                        {truncateNumber((scaleMax + scaleMin) / 2)}
                      </span>
                      <span className="label-legend float-end text-end">
                        {truncateNumber(scaleMax)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs="2 ps-0 my-auto">
              {this.state.expand ? (
                <Button
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Change number of classes"
                  className="py-0 px-1 w-100 h-100 btn-hiphen"
                  onClick={() => this.handleChange(this.state.stepValue)}
                >
                  {this.state.stepValue}
                </Button>
              ) : (
                <Button
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Change number of classes"
                  className="py-0 px-1 w-100 h-100 btn-hiphen"
                  onClick={() => this.editScale()}
                >
                  <i className="fa fa-pencil" />
                </Button>
              )}
            </Col>
          </Row>
        </div>
        {this.state.expand && (
          <Row>
            <Col xs="12">
              <i
                className="fa fa-lg fa-window-close icon-properties mt-0"
                onClick={() => this.editScale()}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    colorScale: selectColorScale(state),
  };
}

const callbacks = {
  setColorScaleStep,
  setColorOpacity,
};

export default connect(mapStateToProps, callbacks)(LegendColorScale);

LegendColorScale.propTypes = {
  setColorScaleStep: PropTypes.func.isRequired,
  setColorOpacity: PropTypes.func.isRequired,
  colorScale: PropTypes.object.isRequired,
  changeSelectedTrait: PropTypes.func.isRequired,
  opacity: PropTypes.number.isRequired,
};
