import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

export const TrackingContext = createContext(null);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

export const TrackingProvider = ({ children }) => {
  const user = useSelector((state) => state.user);
  const resultMap = useSelector((state) => state.resultMap);

  useEffect(() => {
    if (user.self.id && ReactGA.isInitialized)
      ReactGA.set({
        user_properties: {
          userId: user.self.id,
          cloverfieldUserRoleIsAdmin: user.self.admin,
          cloverfieldUserCompany: user.self.company.name,
          cloverfieldUserFullName: user.self.fullname,
          userRole: user.self.role,
        },
      });

    // Disabled on purpose
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.self.id]);

  const trackEvent = useCallback(
    ({ eventName, eventCategory, eventLabel }, props) => {
      const timestamp = new Date().toLocaleString();

      ReactGA.event(eventName, {
        category: eventCategory,
        label: eventLabel,
        trial_date: resultMap.trial_date,
        contract: resultMap.selectedContract?.name,
        site: resultMap.trial?.name,
        timestamp: timestamp,
        ...props,
      });
    },
    [resultMap]
  );
  const trackingContextValue = useMemo(() => ({ trackEvent }), [trackEvent]);

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context)
    throw new Error("useTracking must be used within a TrackingProvider");
  return context;
};
