import "./draggableMarker.css";

import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { useEffect, useRef, useState } from "react";

import L from "leaflet";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";

const LATLONG_REG =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

const DraggableMarker = (props) => {
  const { map, isActive, setIsActive } = props;
  const markerRef = useRef();
  const inputRef = useRef();
  const [isInputInvalid, setIsInputInvalid] = useState(false);

  useEffect(() => {
    if (!isActive) {
      // Reset if draggableMarker was displayed
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
        markerRef.current = null;
        setIsInputInvalid(false);
      }
    } else {
      const initialLocation = map.getCenter();
      markerRef.current = new L.marker(initialLocation, {
        draggable: "true",
      });

      // protect from geoman "experiment" action
      markerRef.current._pmTempLayer = true;
      markerRef.current._dragDisabled = true;

      // On dragend, display marker lat and long into input
      markerRef.current.on("dragend", () => {
        const position = markerRef.current.getLatLng();
        inputRef.current.value = `${position.lat.toFixed(
          8
        )}, ${position.lng.toFixed(8)}`;
      });
      map.addLayer(markerRef.current);
    }
  }, [isActive, map]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") setPositionMarker(e);
  };

  const handleActivation = () => {
    setIsActive(!isActive);
  };

  const setPositionMarker = (e) => {
    e.target.blur();
    const inputValue = inputRef.current.value.replaceAll(" ", "");
    if (inputValue === "") return;
    if (LATLONG_REG.test(inputValue)) {
      const [lat, lng] = inputValue.split(",");
      markerRef.current.setLatLng([parseFloat(lat), parseFloat(lng)]);
      setIsInputInvalid(false);
    } else {
      setIsInputInvalid(true);
    }
  };

  const inputPositionMarker = () => (
    <>
      <InputGroup className="mt-2 marker-input-group">
        <Input
          invalid={isInputInvalid}
          innerRef={inputRef}
          onKeyPress={handleKeyPress}
          placeholder="latitude, longitude"
          autoFocus
        />
        <Button
          className={classnames({
            "input-icon": true,
            invalid: isInputInvalid,
          })}
          onClick={setPositionMarker}
        >
          <i className="fa fa-search" />
        </Button>
      </InputGroup>
      {isInputInvalid && (
        <div className="helperText">
          Accepted format: "latitude, longitude"
          <br />
          example: 43.91665372, 4.89261713
        </div>
      )}
    </>
  );

  return (
    <>
      <button
        type="button"
        data-tooltip-id="tooltip"
        data-tooltip-content="Add marker"
        className={classnames({
          "btn btn-hiphen ms-2": true,
          active: isActive,
        })}
        onClick={handleActivation}
      >
        <i className="fa fa-lg fa-map-marker"></i>
      </button>
      {isActive && inputPositionMarker()}
    </>
  );
};

export default connect()(DraggableMarker);

DraggableMarker.propTypes = {
  map: PropTypes.object.isRequired,
};
