import "./editableLabel.css";

import { Button, Input } from "reactstrap";
import { useRef, useState } from "react";

import PropTypes from "prop-types";
import { useClickAway } from "react-use";

/*
 * Custom component for a label that becomes an input on click
 */
export const EditableLabel = ({ disabled, value, onChange, ...props }) => {
  const [isEditing, setEditing] = useState(false);
  const [newLabel, setNewLabel] = useState(value);

  const submit = () => {
    if (!newLabel.length) return cancel();
    setEditing(false);
    onChange(newLabel);
  };

  const cancel = () => {
    setEditing(false);
    setNewLabel(value);
  };

  const ref = useRef();
  useClickAway(ref, submit);

  return (
    <div className="editable-label" ref={ref}>
      {isEditing && !disabled ? (
        <>
          <Input
            {...props}
            autoFocus
            type="text"
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") submit();
              if (e.key === "Escape") cancel();
            }}
          />
          <div className="editable-label-controls">
            <Button onClick={submit}>
              <i className="fa fa-check" />
            </Button>
            <Button onClick={cancel}>
              <i className="fa fa-close" />
            </Button>
          </div>
        </>
      ) : (
        <div
          {...props}
          className={`p-2 label ${disabled ? "disabled" : ""}`}
          onClick={() => {
            if (!disabled) setEditing(true);
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
};

EditableLabel.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
