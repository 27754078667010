import { CONTROL_VARIETY_YES } from "../powerdash/constants";
import { boolStringToInt } from "../services/utils";
import chroma from "chroma-js";
import { createSelector } from "reselect";

const selectFeaturesWithAUCData = createSelector(
  (state) => state.resultMap.features,
  (state) => state.resultMap.aucData,
  (features, aucData) => {
    if (!aucData.traits.length) return features;
    return features.map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        ...aucData.featureData[feature.properties.plot_id],
      },
    }));
  }
);

const filterFeatures = (
  features,
  selectedLayer,
  selectedExperiments,
  distinctExperiments,
  distinctLayers,
  selectedModalities,
  distinctModalities,
  filteringProfile
) => {
  // User filtering profile instead of filters if it is defined in store
  if (filteringProfile.scope) {
    selectedExperiments = filteringProfile.scope.experiments;
    selectedModalities = filteringProfile.scope.modalities;
    selectedLayer = filteringProfile.scope.layer;
  }

  // Do not filter on layer if less than 2 layers
  const shouldSkipLayerFilter = distinctLayers.length <= 1;
  // Do not filter on experiments if no experiments, no experiments selected or all experiments selected
  const shouldSkipExperimentFilter =
    !distinctExperiments.length ||
    (!!selectedExperiments.length &&
      distinctExperiments.length === selectedExperiments.length);

  // Do not filter on modalities if no modalities, no modalities selected or all modalities selected
  const shouldSkipModalityFilter =
    !distinctModalities.length ||
    (!!selectedModalities.length &&
      distinctModalities.length === selectedModalities.length);

  return features.filter(({ properties: { experiment, layer, modality } }) => {
    // If filter not skipped only includes features with layer matching the selected one
    const isSelectedLayer = shouldSkipLayerFilter || selectedLayer === layer;

    if (!isSelectedLayer) return false;

    // If filter not skipped only includes features with experiments matching the selected ones
    // Explicitely cast falsy values to null to avoid undefined confusion
    return (
      (shouldSkipExperimentFilter ||
        selectedExperiments
          .map((exp) => exp ?? null)
          .includes(experiment ?? null)) &&
      (shouldSkipModalityFilter ||
        selectedModalities.map((mod) => mod ?? null).includes(modality ?? null))
    );
  });
};

export const selectFilteredFeaturesByDate = createSelector(
  (state) => state.resultMap.featuresByDate,
  (state) => state.resultMap.filters.layer,
  (state) => state.resultMap.filters.experiments,
  (state) => state.resultMap.distinctExperiments,
  (state) => state.resultMap.distinctLayers,
  (state) => state.resultMap.filters.modalities,
  (state) => state.resultMap.distinctModalities,
  (state) => state.resultMap.filteringProfile,
  filterFeatures
);

export const selectFeaturesFilteredOnExperimentAndLayer = createSelector(
  selectFeaturesWithAUCData,
  // Exact properties of filters have to be extracted
  // otherwise min or min filter update would also trigger this selector update
  (state) => state.resultMap.filters.layer,
  (state) => state.resultMap.filters.experiments,
  (state) => state.resultMap.distinctExperiments,
  (state) => state.resultMap.distinctLayers,
  (state) => state.resultMap.filters.modalities,
  (state) => state.resultMap.distinctModalities,
  (state) => state.resultMap.filteringProfile,
  filterFeatures
);

// Get unique excluded groups across all dates
export const selectFilteredOutGroupsSet = createSelector(
  (state) => state.resultMap.filteringProfile,
  (filteringProfile) => {
    if (!filteringProfile.scope) return new Set();
    return new Set(
      filteringProfile.filters.flatMap((filter) => filter.excludedGroups) // Concat all excluded groups
    );
  }
);

export const selectExcludedGroupsSet = createSelector(
  (state) => state.resultMap.filteringProfile,
  selectFilteredOutGroupsSet,
  (filteringProfile, filteredOutGroupsSet) => {
    if (!filteringProfile.scope) return new Set();
    return new Set(
      Array.from(filteredOutGroupsSet)
        .concat(filteringProfile.blacklist) // Add all blacklisted groups
        .filter((group) => !filteringProfile.whitelist.includes(group)) // Remove whitelisted groups
    );
  }
);

export const selectSelectedTrialContractInfo = createSelector(
  (state) => state.user.contracts,
  (state) => state.resultMap.trial?.contract_id,
  (contracts, contractId) =>
    contracts.find((contract) => contract.id === contractId)
);

export const selectMergedTraits = createSelector(
  (state) => state.resultMap.traitsListForMap,
  (state) => state.resultMap.aucData.traits,
  (traitsListForMap, aucDataTraits) => [...traitsListForMap, ...aucDataTraits]
);

export const selectTraitsMetricsAndAggregatedFeatures = createSelector(
  selectFeaturesFilteredOnExperimentAndLayer,
  selectMergedTraits,
  (features, traits) => {
    const aggregatedFeatures = {};
    const traitsMetrics = {};

    traits.forEach(({ technical_name }) => {
      traitsMetrics[technical_name] = {
        min: Infinity,
        max: -Infinity,
        sum: 0,
      };
    });

    features.forEach((feature) => {
      const { group, properties, modality, genotype } = feature;
      const groupData = aggregatedFeatures[group] || {
        features: [],
        properties: {},
        modality,
        genotype,
        isControl:
          properties.control_variety?.toLowerCase() === CONTROL_VARIETY_YES,
      };

      groupData.features.push(feature);

      traits.forEach(({ technical_name }) => {
        const traitValue = properties[technical_name];

        traitsMetrics[technical_name].min = Math.min(
          traitsMetrics[technical_name].min,
          traitValue
        );
        traitsMetrics[technical_name].max = Math.max(
          traitsMetrics[technical_name].max,
          traitValue
        );
        traitsMetrics[technical_name].sum += traitValue;

        const groupProperty = groupData.properties[technical_name];
        // Set values for this group properties specifically
        if (!groupProperty) {
          groupData.properties[technical_name] = {
            min: traitValue,
            max: traitValue,
            sum: traitValue,
          };
        } else {
          groupProperty.min = Math.min(groupProperty.min, traitValue);
          groupProperty.max = Math.max(groupProperty.max, traitValue);
          groupProperty.sum += traitValue;
        }
      });

      aggregatedFeatures[group] = groupData;
    });

    const aggregatedFeaturesArray = Object.entries(aggregatedFeatures).map(
      ([group, data]) => {
        const { properties, ...spare } = data;
        const newProperties = {};
        Object.entries(properties).forEach(([key, value]) => {
          newProperties[key] = {
            ...value,
            mean: value.sum / data.features.length,
          };
        });
        return {
          group,
          properties: newProperties,
          ...spare,
        };
      }
    );

    // Compute traits means
    traits.forEach(({ technical_name }) => {
      traitsMetrics[technical_name].mean =
        traitsMetrics[technical_name].sum / features.length;
    });

    return [aggregatedFeaturesArray, traitsMetrics];
  }
);

export const selectFilteredAggregatedFeatures = createSelector(
  selectTraitsMetricsAndAggregatedFeatures,
  selectExcludedGroupsSet,
  ([aggregatedFeatures], excludedGroups) =>
    !excludedGroups.size
      ? aggregatedFeatures
      : aggregatedFeatures.filter(({ group }) => !excludedGroups.has(group))
);

export const selectFilteredFeatures = createSelector(
  selectFilteredAggregatedFeatures,
  (aggregatedFeatures) => {
    return aggregatedFeatures.flatMap(({ features }) => features);
  }
);

export const selectColorScale = createSelector(
  selectFilteredFeatures,
  (state) => state.resultMap.selectedTrait,
  (state) => state.resultMap.colorScaleStep,
  (filteredFeatures, { style, technical_name }, colorScaleStep) => {
    const defaultColorScale = {
      minValue: null,
      maxValue: null,
      step: null,
      func: () => "rgba(255, 255, 255, 0)",
    };

    // If `selectedTrait` not empty then `style` is defined
    if (filteredFeatures.length && style && style.opacity !== false) {
      let minValue = null;
      let maxValue = null;

      // Expect both min and max interval to be defined if `intervalDefault` is true
      if (style.intervalDefault) {
        minValue = style.intervalMin;
        maxValue = style.intervalMax;
      } else {
        // `forEach` prefered to `Math.min`/`Math.max` or `reduce` to prevent performance or stack size problems on very large arrays
        filteredFeatures.forEach((feature) => {
          const value = feature.properties[technical_name];
          if (value != null) {
            if (minValue === null || value < minValue) minValue = value;
            if (maxValue === null || value > maxValue) maxValue = value;
          }
        });

        minValue = boolStringToInt(minValue);
        maxValue = boolStringToInt(maxValue);
      }

      if ((minValue !== 0 && !minValue) || (maxValue !== 0 && !maxValue))
        return defaultColorScale;

      const stepValue = colorScaleStep || style.step;

      return {
        minValue: minValue,
        maxValue: maxValue,
        step: stepValue,
        func: chroma
          .scale(style.color)
          .domain([minValue, maxValue])
          .mode("lrgb")
          .classes(stepValue),
      };
    }
    return defaultColorScale;
  }
);

export const selectControlVarieties = createSelector(
  selectTraitsMetricsAndAggregatedFeatures,
  ([aggregatedFeatures]) =>
    aggregatedFeatures
      .filter(({ isControl }) => isControl)
      .map(({ group }) => group)
);
