import { Badge, Input } from "reactstrap";
import {
  removeGroupFromFilteringProfileBlacklist,
  removeGroupFromFilteringProfileWhitelist,
} from "../../../../../actions/resultMap";
import { useMemo, useState } from "react";

import { HighlightedText } from "../../../../../components/highlightedText/HighlightedText";
import { LabelledSeparator } from "../../../../../components/labelledSeparator/LabelledSeparator";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

const TABS = Object.freeze({
  WHITELIST: "Allow List",
  BLACKLIST: "Block List",
});
const Tabs = ({ mode, setMode }) => {
  return (
    <>
      {Object.values(TABS).map((value) => (
        <Badge
          key={value}
          className={`hiphen-badge clickable ${mode === value ? "active" : ""}`}
          onClick={() => setMode(value)}
        >
          <i
            className={`fa ${
              value === TABS.WHITELIST ? "fa-shield" : "fa-minus-circle"
            }`}
          />{" "}
          {value}
        </Badge>
      ))}
    </>
  );
};

export const GroupListManager = ({ filteringProfile }) => {
  const [mode, setMode] = useState(TABS.BLACKLIST);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const groups = useMemo(
    () =>
      (mode === TABS.BLACKLIST
        ? filteringProfile.blacklist
        : filteringProfile.whitelist
      )
        .filter((group) => group.toLowerCase().includes(search.toLowerCase()))
        .toSorted(),
    [filteringProfile, mode, search]
  );

  return (
    <>
      <div className="pb-0 d-flex gap-1">
        <Input
          size="sm"
          type="search"
          placeholder="Search groups"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Tabs mode={mode} setMode={setMode} />
      </div>
      <div className="group-list h-50 p-2">
        <LabelledSeparator>
          {mode === TABS.BLACKLIST
            ? "Explicitely always filtered out"
            : "Protected from being filtered out"}
        </LabelledSeparator>
        {groups.map((group) => (
          <div className="group-list-item p-2" key={group}>
            <span>
              <i
                className={`fa ${
                  mode === TABS.BLACKLIST
                    ? "fa-minus-circle bl"
                    : "fa-shield wl"
                }`}
              />{" "}
              <HighlightedText text={group} highlight={search} />
            </span>
            <i
              className="fa fa-close discrete-icon clickable"
              onClick={() => {
                if (mode === TABS.BLACKLIST)
                  dispatch(removeGroupFromFilteringProfileBlacklist(group));
                else dispatch(removeGroupFromFilteringProfileWhitelist(group));
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

GroupListManager.propTypes = {
  filteringProfile: PropTypes.object.isRequired,
};

Tabs.propTypes = {
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
};
