import "./ComparativeScatter.css";

import {
  ANALYTICS_EVENTS,
  DEFAULT_BLACKLISTED_TRAITS,
  HIPHEN_GREEN,
  PLOT_RATING_COLOR,
  THEME,
} from "../../../../../constants";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  selectFilteredFeatures,
  selectMergedTraits,
} from "../../../../../selectors/resultMap";

import { COMMON_PLOT_CONFIG } from "../../../../constants";
import { Chart } from "../Chart";
import { ComplexSearch } from "../../../../components/complexSearch/ComplexSearch";
import { Metric } from "./Metric";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import ReactSlider from "react-slider";
import ReactSwitch from "react-switch";
import { SelectionFloatingCard } from "./SelectionFloatingCard/SelectionFloatingCard";
import { Statistics } from "statistics.js";
import { TraitClassesBadge } from "../../traitClassesBadge/TraitClassesBadge";
import { capitalize } from "../../../../utils";
import { truncateNumber } from "../../../../../services/utils";
import { useSelector } from "react-redux";
import { useTracking } from "../../../../../analytics";

const PLOT_STYLE = { width: "100%", height: "100%" };

export const ComparativeScatter = memo(
  ({
    exportName,
    plotRatingConfig,
    xAxis,
    setXAxis,
    yAxis,
    setYAxis,
    showRegression,
    setShowRegression,
    scatterOpacity,
    setScatterOpacity,
  }) => {
    const [selectedPlots, setSelectedPlots] = useState([]);

    const features = useSelector(selectFilteredFeatures);
    const traits = useSelector(selectMergedTraits);

    const { options, rsquared, spearman, pearson, regression } = useMemo(() => {
      const statData = []; // Stat data is used by statistics.js to calculate regression and other metrics
      const propertiesKeys = new Set(); // Unique keys from features properties are used to bind options

      /* First iteration over features to create options and stat data, cannot be done in parallel of displayData
       * because linear regression have to be calculated first
       */
      features.forEach(({ properties }) => {
        statData.push({
          x: properties[xAxis],
          y: properties[yAxis],
        });
        Object.keys(properties).forEach((property) =>
          propertiesKeys.add(property)
        );
      });

      const stats = new Statistics(statData, { x: "metric", y: "metric" });
      const linearRegression = stats.linearRegression("x", "y");

      // Blacklisted traits are excluded from unique keys and they are rebinded
      const options = Array.from(propertiesKeys)
        .filter(
          (key) => !DEFAULT_BLACKLISTED_TRAITS.includes(key.toLowerCase())
        )
        .map((key) => ({
          label:
            traits.find(({ technical_name }) => technical_name === key)?.name ??
            capitalize(key),
          value: key,
        }));

      return {
        options,
        rsquared: linearRegression?.coefficientOfDetermination,
        spearman: stats.spearmansRho("x", "y")?.rho,
        pearson: stats.correlationCoefficient("x", "y")?.correlationCoefficient,
        regression: linearRegression?.regressionFirst,
      };
    }, [features, traits, xAxis, yAxis]);

    const [xLabel, yLabel] = useMemo(
      () => [
        options.find(({ value }) => value === xAxis)?.label,
        options.find(({ value }) => value === yAxis)?.label,
      ],
      [options, xAxis, yAxis]
    );

    const displayData = useMemo(() => {
      const scatterData = {
        type: "scattergl",
        mode: "markers",
        marker: {
          border: "transparent",
          size: 8,
          opacity: scatterOpacity,
        },
        text: [],
        hovertemplate: "%{text}<extra></extra>",
        x: [],
        y: [],
      };

      const regressionTrace = {
        type: "scattergl",
        mode: "lines",
        hovertemplate: "Linear regression<extra></extra>",
        x: [],
        y: [],
      };

      features.forEach(({ displayId, properties }) => {
        scatterData.x.push(properties[xAxis]);
        scatterData.y.push(properties[yAxis]);
        scatterData.text.push(
          `<b>${displayId}</b><br><b>x</b> ${xLabel}: ${truncateNumber(
            properties[xAxis]
          )}<br><b>y</b> ${yLabel}: ${truncateNumber(properties[yAxis])}<br>`
        );

        if (regression) {
          regressionTrace.x.push(properties[xAxis]);
          regressionTrace.y.push(
            regression.beta1 + properties[xAxis] * regression.beta2
          );
        }
      });

      const displayData = [scatterData];
      if (showRegression) displayData.push(regressionTrace);
      return displayData;
    }, [
      features,
      regression,
      showRegression,
      xAxis,
      xLabel,
      yAxis,
      yLabel,
      scatterOpacity,
    ]);

    /*
     * Search object used by ComplexSearch with categories:
     *   Plot ratings : keys contained in the contract plotRatingConfig as labels
     *   Traits: keys that are hiphen traits
     *   Other Properties: everything else
     *
     * searchObject is created then every option is iterated once and is assigned to a category
     */
    const propertiesSearch = useMemo(() => {
      const plotRatingLabels = plotRatingConfig.map(({ label }) => label);
      const searchObject = {
        traits: {
          options: [],
          CustomComponent: TraitClassesBadge,
        },
        "Plot ratings": {
          options: [],
        },
        "Other properties": {
          options: [],
        },
      };

      options.forEach((option) => {
        if (plotRatingLabels.includes(option.value))
          searchObject["Plot ratings"].options.push({
            ...option,
            renderBullet: (
              <i
                className="fa fa-pencil"
                style={{ color: PLOT_RATING_COLOR }}
              />
            ),
          });
        else if (
          traits.find(({ technical_name }) => technical_name === option.value)
        )
          searchObject.traits.options.push({
            ...option,
            renderBullet: <i className="fa fa-circle" />,
          });
        else searchObject["Other properties"].options.push(option);
      });

      return searchObject;
    }, [options, plotRatingConfig, traits]);

    const layout = useMemo(
      () => ({
        autosize: true,
        showlegend: false,
        dragmode: "select",
        margin: {
          r: 30,
          t: 0,
        },
        plot_bgcolor: "transparent",
        paper_bgcolor: "white",
        yaxis: {
          automargin: true,
          gridcolor: THEME.indicators,
          categoryorder: "array",
          categoryarray: plotRatingConfig.find(({ label }) => label === yAxis)
            ?.options,
          title: yLabel,
        },
        xaxis: {
          automargin: true,
          gridcolor: THEME.indicators,
          categoryorder: "array",
          categoryarray: plotRatingConfig.find(({ label }) => label === xAxis)
            ?.options,
          title: xLabel,
        },

        font: { color: THEME.indicators, size: 15 },

        colorway: [
          HIPHEN_GREEN,
          showRegression ? THEME.baseColors[4] : "transparent",
        ],
      }),
      [plotRatingConfig, showRegression, xAxis, xLabel, yAxis, yLabel]
    );

    const config = useMemo(
      () => ({
        toImageButtonOptions: {
          filename: exportName,
        },
        ...COMMON_PLOT_CONFIG,
      }),
      [exportName]
    );

    const { trackEvent } = useTracking();

    const handleSelection = useCallback(
      (event) => {
        if (!event) return;
        if (event.points.length === 0) return;
        trackEvent(ANALYTICS_EVENTS.SELECT_FROM_SCATTER_TOOL);
        const selectedFeatures = event.points.map(
          (point) => features[point.pointIndex]
        );
        setSelectedPlots(selectedFeatures);
      },
      [features, trackEvent]
    );

    // Prevent undefined axis
    useEffect(() => {
      if (!xAxis) setXAxis(options[0].value);
      if (!yAxis) setYAxis(options[0].value);
    }, [options, setXAxis, setYAxis, xAxis, yAxis]);

    return (
      <Chart icon="fa-balance-scale" title="Comparative scatter tool">
        <div className="position-relative">
          <SelectionFloatingCard plots={selectedPlots} />
        </div>

        <Plot
          useResizeHandler
          data={displayData}
          layout={layout}
          onSelected={handleSelection}
          config={config}
          style={PLOT_STYLE}
        />

        <div className="scatter-tool-footer">
          <div className="d-flex flex-column w-50 gap-3">
            {regression && (
              <div className="scatter-tool-switch">
                <ReactSwitch
                  onChange={setShowRegression}
                  checked={showRegression}
                  height={20}
                  width={40}
                  uncheckedIcon={null}
                  checkedIcon={null}
                  onColor={HIPHEN_GREEN}
                />
                <span>
                  Display linear regression
                  <span className="scatter-tool-switch-equation">
                    <span>y = </span>
                    <span className="variable">
                      {truncateNumber(regression.beta2)}
                    </span>
                    <span>x + </span>
                    <span className="variable">
                      {truncateNumber(regression.beta1)}
                    </span>
                  </span>
                </span>
              </div>
            )}
            <ReactSlider
              value={scatterOpacity}
              step={0.01}
              min={0}
              max={1}
              onAfterChange={setScatterOpacity}
              className="opacity-slider-track"
              thumbClassName="opacity-slider-thumb"
              withTracks={false}
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}</div>
              )}
            />
          </div>

          <div className="scatter-tool-metrics">
            <Metric
              name="R²"
              value={rsquared}
              tip="Coefficient of determination"
              href="https://en.wikipedia.org/wiki/Coefficient_of_determination"
            />
            <Metric
              name="Spearman"
              value={spearman}
              tip="Spearman's Rho"
              href="https://en.wikipedia.org/wiki/Spearman%27s_rank_correlation_coefficient"
            />
            <Metric
              name="Pearson"
              value={pearson}
              tip="Pearson's correlation coefficient "
              href="https://en.wikipedia.org/wiki/Pearson_correlation_coefficient"
            />
          </div>
        </div>

        <div className="axis-picker">
          <i className="fa fa-arrows-v discrete-icon" />
          <ComplexSearch
            inverted
            placeholder={`Y: ${yLabel}`}
            content={propertiesSearch}
            handleClickDefault={setYAxis}
            closeOnSelect={false}
          />
          <i className="fa fa-arrows-h discrete-icon" />
          <ComplexSearch
            inverted
            placeholder={`X: ${xLabel}`}
            content={propertiesSearch}
            handleClickDefault={setXAxis}
            closeOnSelect={false}
          />
        </div>
      </Chart>
    );
  }
);

ComparativeScatter.propTypes = {
  exportName: PropTypes.string.isRequired,
  plotRatingConfig: PropTypes.array.isRequired,
  xAxis: PropTypes.string.isRequired,
  setXAxis: PropTypes.func.isRequired,
  yAxis: PropTypes.string.isRequired,
  setYAxis: PropTypes.func.isRequired,
  showRegression: PropTypes.bool.isRequired,
  setShowRegression: PropTypes.func.isRequired,
  scatterOpacity: PropTypes.number.isRequired,
  setScatterOpacity: PropTypes.func.isRequired,
};
