/* eslint-disable max-lines-per-function */
import "./clientDashboard.css";

import { ANALYTICS_EVENTS, EXPERIMENT_STATUS } from "../constants";
import {
  Button,
  Col,
  Collapse,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import React, { Component } from "react";
import { hasAdminAccess, hasBackofficeAccess } from "../services/roles";
import {
  hasCreateSiteRole,
  hasEditPlotRatingConfigRole,
  hasExperimentsRole,
} from "../users/rolesUtil";
import {
  requestClientCreateSite,
  requestClientUpdateSite,
  requestFetchClientSite,
} from "../services/backendRequests";

import AlertComponent from "../components/alert";
import { ButtonAddRemoveUser } from "../components/ButtonAddRemoveUser";
import { CountryFlag } from "../powerdash/components/countryFlag/countryFlag";
import DetailContract from "./detailContract";
import LoadingImg from "../components/loading";
import NavbarComponent from "../components/navbar";
import PageTitle from "../components/pageTitle";
import { PlotRatingConfig } from "./editPlotRatingConfig/plotRatingConfig";
import PropTypes from "prop-types";
import Select from "react-select";
import { TrackingContext } from "../analytics";
import _ from "lodash";
import { addDangerAlert } from "../actions/alerts";
import { connect } from "react-redux";
import { fetchCrops } from "../actions/crops";
import { fetchResultMapFeatures } from "../actions/resultMap";
import { fetchUserContractsTrials } from "../actions/user";
import { iso31661 } from "iso-3166";
import moment from "moment";
import { updateContractInformation } from "../actions/company";
import { withRouter } from "react-router-dom";

const iso31661Options = iso31661.map((c) => ({
  label: c.name,
  value: c.alpha3,
}));

const newSiteInitialState = {
  id: null,
  display_name: "",
  country: null,
  crop: null,
  nb_microplots: 0,
};

class ClientDashboard extends Component {
  state = {
    siteSelected: null,
    contractSelected: null,
    contractDisplay: null,
    newSite: newSiteInitialState,
    newSiteAlert: "",
    editedContract: null,
  };

  componentDidMount() {
    this.props.fetchUserContractsTrials();
    this.props.fetchCrops();
  }

  loadSites = (contract) => {
    if (this.state.contractSelected === contract)
      //Close collapsable area
      this.setState({ contractSelected: null });
    else this.setState({ contractSelected: contract });

    this.setState({ newSite: newSiteInitialState, newSiteAlert: "" });
  };

  toggleOpenContractEdition = (contract) => {
    this.setState({ editedContract: contract });
  };

  toggleCloseContractEdition = () => {
    this.setState({ editedContract: null });
  };

  displaySite = (contract, site) => {
    requestFetchClientSite(site, this.props.user).then((siteSelected) => {
      this.setState({
        siteSelected: siteSelected,
        contractDisplay: contract,
      });
    });
  };

  closePanel = () => {
    this.setState({ siteSelected: null });
  };

  openResultMapFromSite = (event, site) => {
    event.stopPropagation();
    this.props.history.push(`/map/${site.id}`);
  };

  openResultMapFromSiteOnDate = (site, date) => {
    this.props.history.push(`/map/${site.id}/${date}`);
  };

  openContractInAdministration = (contract) => {
    this.props.history.push(
      `/administration/company/${contract.company.id}/contract/${contract.id}`
    );
  };

  checkSiteUnicity(site) {
    const similarSite = this.state.contractSelected.sites.find(
      ({ display_name, id }) =>
        display_name.toLowerCase() === site.display_name.toLowerCase() &&
        site.id !== id
    );
    if (similarSite == null) return true;

    return false;
  }

  handleSiteCreation() {
    const site = {
      ...this.state.newSite,
      crop: this.state.newSite.crop ?? "",
      display_name: this.state.newSite.display_name.trim(),
    };
    if (!hasCreateSiteRole(this.state.contractSelected.roles)) return;
    if (!this.checkSiteUnicity(site)) {
      this.setState({ newSiteAlert: "Site name must be unique" });
      return;
    }
    if (this.state.newSite.id == null)
      requestClientCreateSite(
        this.state.contractSelected,
        site,
        this.props.user
      )
        .then(() => {
          this.props.fetchUserContractsTrials();
          this.setState({ newSite: newSiteInitialState, newSiteAlert: "" });
        })
        .catch((err) => {
          this.props.addDangerAlert(err);
        });
    else
      requestClientUpdateSite(site, this.props.user).then(() => {
        this.props.fetchUserContractsTrials();
        this.setState({ newSite: newSiteInitialState, newSiteAlert: "" });
      });
  }

  editSite(event, site) {
    event.stopPropagation();
    this.setState({ newSite: site });
  }

  resetNewSite() {
    this.setState({ newSite: newSiteInitialState });
  }

  static contextType = TrackingContext;

  render() {
    const { trackEvent } = this.context;
    let actionsVisible =
      hasBackofficeAccess(this.props.user.self) ||
      hasAdminAccess(this.props.user.self);

    return (
      <div className="wrapper">
        <NavbarComponent />
        <AlertComponent />

        <div className="page-content container-fluid">
          <PageTitle title="Campaigns" />
          <Row className="flex-grow-1">
            <Col xs={this.state.siteSelected ? "6" : "12"}>
              <div className="section full-height">
                <Row className="pb-2">
                  <Col xs="4" className="ps-2 fw-bold">
                    Campaign
                  </Col>
                  <Col xs="2" className="fw-bold">
                    Start date
                  </Col>
                  <Col xs="2" className="fw-bold">
                    End date
                  </Col>
                  <Col xs="2" className="fw-bold ">
                    Sites
                  </Col>
                </Row>
                <Modal
                  size="md"
                  isOpen={Boolean(this.state.editedContract)}
                  toggle={this.toggleCloseContractEdition}
                >
                  <ModalHeader>
                    <h4>
                      {this.state.editedContract?.name}
                      {": "}
                      <small>Plot rating configuration</small>
                    </h4>
                  </ModalHeader>
                  <ModalBody>
                    {this.state.editedContract && (
                      <PlotRatingConfig
                        contract={this.state.editedContract}
                        user={this.props.user}
                      />
                    )}
                  </ModalBody>
                </Modal>
                {!this.props.loading &&
                  this.props.contracts?.map((contract, index) => (
                    <React.Fragment key={contract.id}>
                      <Row
                        className={
                          "clickable-row py-2 rounded detail-row " +
                          (index % 2 === 0 ? "bg-lightgrey" : "")
                        }
                        onClick={() => this.loadSites(contract)}
                      >
                        <Col xs="4 ps-2">{contract.name}</Col>
                        <Col xs="2">{contract.start_date}</Col>
                        <Col xs="2">{contract.end_date}</Col>
                        <Col xs="2">{contract.total_sites}</Col>
                        <Col xs="2" className="text-end pe-2">
                          {hasEditPlotRatingConfigRole(contract.roles) && (
                            <Button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Edit plot rating config"
                              className="me-2 p-0"
                              color="link"
                              onClick={(event) => {
                                event.stopPropagation();
                                this.toggleOpenContractEdition(contract);
                              }}
                            >
                              <i className="fa fa-lg fa-pencil" />
                            </Button>
                          )}
                          {actionsVisible && (
                            <>
                              <ButtonAddRemoveUser
                                contract={contract}
                                company={contract.company}
                                hasContractAccess
                              />
                              <Button
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Open in administration"
                                className="me-2 p-0"
                                color="link"
                                onClick={() =>
                                  this.openContractInAdministration(contract)
                                }
                              >
                                <i className="fa fa-lg fa-cogs" />
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                      <Collapse
                        isOpen={
                          this.state.contractSelected
                            ? contract.id === this.state.contractSelected.id
                            : false
                        }
                        className={
                          "row px-4 py-4 rounded-bottom border rounded"
                        }
                      >
                        <Col>
                          <Row className="pb-2 mx-2">
                            {hasCreateSiteRole(
                              this.state.contractSelected?.roles
                            ) && (
                              <div className={"site-creation-discrete"}>
                                <div className="site-creation-discrete-input">
                                  <label>
                                    Site name* <i>must be unique</i>
                                  </label>
                                  <Input
                                    placeholder="Site name"
                                    value={this.state.newSite.display_name}
                                    onChange={(e) => {
                                      this.setState({
                                        newSite: {
                                          ...this.state.newSite,
                                          display_name: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                                <div className="site-creation-discrete-input">
                                  <label>Country*</label>
                                  <Select
                                    isSearchable
                                    style={{ width: "100%" }}
                                    value={
                                      this.state.newSite.country
                                        ? iso31661Options.find(
                                            (c) =>
                                              c.value ===
                                              this.state.newSite.country
                                          )
                                        : null
                                    }
                                    onChange={({ value }) => {
                                      this.setState({
                                        newSite: {
                                          ...this.state.newSite,
                                          country: value,
                                        },
                                      });
                                    }}
                                    options={iso31661Options}
                                    required
                                  />
                                </div>
                                <div className="site-creation-discrete-input">
                                  <label>Crop</label>
                                  <Select
                                    isSearchable
                                    style={{ width: "100%" }}
                                    value={this.props.crops
                                      .filter(
                                        (c) =>
                                          c.name === this.state.newSite.crop
                                      )
                                      .map((crop) => ({
                                        label: crop.name,
                                        value: crop.id,
                                      }))}
                                    onChange={({ label }) => {
                                      this.setState({
                                        newSite: {
                                          ...this.state.newSite,
                                          crop: label,
                                        },
                                      });
                                    }}
                                    options={this.props.crops
                                      .sort((a, b) =>
                                        a.name
                                          .toLowerCase()
                                          .localeCompare(b.name.toLowerCase())
                                      )
                                      .map((crop) => ({
                                        label: crop.name,
                                        value: crop.id,
                                      }))}
                                    required
                                  />
                                </div>
                                <div>
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      trackEvent(ANALYTICS_EVENTS.CREATE_SITE);
                                      this.handleSiteCreation();
                                    }}
                                    disabled={
                                      this.state.newSite.display_name === "" ||
                                      this.state.newSite.display_name == null ||
                                      this.state.newSite.country == null
                                    }
                                  >
                                    {this.state.newSite.id == null ? (
                                      <>
                                        <i className="fa fa-plus" /> Create new
                                        site
                                      </>
                                    ) : (
                                      <>
                                        <i className="fa fa-save" /> Edit site
                                      </>
                                    )}
                                  </Button>
                                  {this.state.newSite.id != null && (
                                    <i
                                      className="fa fa-lg fa-close ms-2 clickable"
                                      onClick={() => {
                                        this.resetNewSite();
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            <span className="text-danger">
                              {this.state.newSiteAlert}
                            </span>
                          </Row>
                          <Row className="pb-2 mx-2">
                            <Col xs="3" className="ps-2 fw-bold">
                              Site
                            </Col>
                            <Col xs="2" className="fw-bold">
                              Crop
                            </Col>
                            <Col xs="3" className="fw-bold">
                              Country
                            </Col>
                            {contract.has_experiment &&
                              hasExperimentsRole(contract.roles) && (
                                <Col xs="2" className="fw-bold">
                                  Plot map
                                </Col>
                              )}
                            <Col xs="2" className="fw-bold">
                              {" "}
                            </Col>
                          </Row>
                          {_.sortBy(contract.sites, (i) => i.display_name)?.map(
                            (site, siteIndex) => (
                              <Row
                                key={site.id}
                                className={
                                  "py-2 mx-2 rounded clickable-row detail-row " +
                                  (siteIndex % 2 === 0 ? "bg-lightgrey " : "") +
                                  (this.state.siteSelected &&
                                  site.id === this.state.siteSelected.id
                                    ? "row-selected"
                                    : "")
                                }
                                onClick={() => this.displaySite(contract, site)}
                              >
                                <Col
                                  xs="3"
                                  className="ps-2 my-auto text-truncate"
                                  title={site.display_name}
                                >
                                  {site.display_name}
                                </Col>
                                <Col xs="2" className="my-auto">
                                  {site.crop ?? ""}
                                </Col>
                                <Col xs="3" className="my-auto">
                                  <div className="country-col">
                                    {site.country != null &&
                                      this.state.siteSelected == null && (
                                        <CountryFlag alpha={site.country} />
                                      )}
                                    {iso31661Options.find(
                                      (c) => c.value === site.country
                                    )?.label ?? ""}
                                  </div>
                                </Col>
                                <Col xs="2" className="my-auto">
                                  {contract.has_experiment &&
                                    hasExperimentsRole(contract.roles) && (
                                      <span
                                        className={`badge ${
                                          EXPERIMENT_STATUS.properties[
                                            site.experiment_status
                                          ].color
                                        }`}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content={
                                          site.experiment_updated_at
                                            ? `Last update: ${moment(
                                                site.experiment_updated_at
                                              ).format("YYYY-MM-DD HH:mm:ss")}`
                                            : ""
                                        }
                                        data-tooltip-place="right"
                                      >
                                        {
                                          EXPERIMENT_STATUS.properties[
                                            site.experiment_status
                                          ].label
                                        }
                                      </span>
                                    )}
                                </Col>
                                <Col xs="2" className="text-end pe-2">
                                  {hasCreateSiteRole(
                                    this.state.contractSelected?.roles
                                  ) && (
                                    <Button
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content="Edit site"
                                      className="p-0"
                                      color="link"
                                      onClick={(e) => this.editSite(e, site)}
                                    >
                                      <i className="fa fa-lg fa-pencil" />
                                    </Button>
                                  )}
                                  {this.props.trials.filter(
                                    (t) => t.id === site.id
                                  ).length > 0 && (
                                    <Button
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content="View results"
                                      className="p-0 ms-2"
                                      color="link"
                                      onClick={(e) =>
                                        this.openResultMapFromSite(e, site)
                                      }
                                    >
                                      <i className="fa fa-lg fa-globe" />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}
                        </Col>
                      </Collapse>
                    </React.Fragment>
                  ))}
                <LoadingImg visible={this.props.loading} />
              </div>
            </Col>
            {this.state.siteSelected ? (
              <DetailContract
                contract={this.state.contractDisplay}
                openResultMapFromSiteOnDate={this.openResultMapFromSiteOnDate}
                closePanel={this.closePanel}
                site={this.state.siteSelected}
                trial={this.props.trials?.find(
                  (t) => t.id === this.state.siteSelected.id
                )}
                missions={this.state.siteSelected?.missions}
                user={this.props.user}
              />
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    contracts: store.user.contracts,
    loading: store.user.contractsLoading,
    trials: store.user.trials,
    user: store.user,
    crops: store.crops.all,
    sites: store.sites.all,
  };
}

const callbacks = {
  updateContractInformation,
  fetchUserContractsTrials,
  fetchResultMapFeatures,
  fetchCrops,
  addDangerAlert,
};

export default withRouter(connect(mapStateToProps, callbacks)(ClientDashboard));

ClientDashboard.propTypes = {
  updateContractInformation: PropTypes.func.isRequired,
  contracts: PropTypes.array.isRequired,
  trials: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  fetchUserContractsTrials: PropTypes.func.isRequired,
  fetchResultMapFeatures: PropTypes.func.isRequired,
};
