import ReactSelect, { components } from "react-select";

export const SelectSearch = (props) => {
  return (
    <ReactSelect
      {...props}
      value={null}
      // Overriding dropdown indicator for a search icon and removing separator
      components={{
        IndicatorSeparator: "",
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <i className="fa fa-search" />
          </components.DropdownIndicator>
        ),
      }}
    />
  );
};
