//------------ Truncate Decimal to 3 digit
const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
};

export const truncateNumber = (value) => {
  if (typeof value === "string") return value;
  const parsedValue = Number.parseFloat(value);

  if (Number.isNaN(parsedValue)) return value == null ? "" : value.toString();

  return countDecimals(parsedValue) > 3
    ? parsedValue.toFixed(3)
    : parsedValue.toString();
};

//------------ Password Generator
const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const passwordCharacters = () => {
  const passwordLength = 8;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";

  if (characters.length) {
    for (let i = 0; i < passwordLength; i++)
      password += characters[getRandomInteger(0, characters.length - 1)];

    return password;
  }
};

export const generatePassword = () => {
  return passwordCharacters();
};

//------------- Convert boolean string to Int
export const boolStringToInt = (val) => {
  if (typeof val === "string") {
    if (["true", "on", "yes"].includes(val.toLowerCase())) return 1;
    if (["false", "off", "no"].includes(val.toLowerCase())) return 0;
  }
  return val;
};

/**
 * Format bytes as human-readable text.
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) return bytes + " B";

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

/**
 * Format string dataset_X as human-readable text.
 * @param str string to convert.
 **/
export function datasetToTitleCase(str) {
  if (!str.includes("dataset_")) return str;

  return str
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace("_", " ");
}

/**
 * Create enum-like object with utils methods
 * Utils methods :
 * * iterateKeys : Iterate on non NaN keys
 * @param Object containing unique k-v associations
 */
export function bimap(obj) {
  // check values unicity
  if (Object.values(obj).length !== new Set(Object.values(obj)).size) {
    console.warn("Impossible to convert object to enum-like object");
    return obj;
  }
  const enumObj = { ...obj };
  Object.entries(enumObj).forEach(([k, v]) => {
    enumObj[v] = k;
  });

  // add utils methods
  enumObj.iterateKeys = () =>
    Object.keys(enumObj).filter((k) => isNaN(enumObj[k]));

  return Object.freeze(enumObj);
}

export const traitGroupCompare = (a, b) => {
  return a.deprecated && !b.deprecated
    ? 1
    : !a.deprecated && b.deprecated
    ? -1
    : a.name.localeCompare(b.name);
};
