import { Button, Col, Row, Table } from "reactstrap";
import {
  createSite,
  deleteSite,
  fetchSites,
  fetchTrial,
  resetTrial,
  updateSiteAlert,
} from "../../actions/sites";

import AdminFilterHeader from "../../components/AdminFilterHeader/AdminFilterHeader";
import AlertComponent from "../../components/alert";
import CopyButton from "../../components/copyButton";
import { EXPERIMENT_STATUS } from "../../constants";
import { Link } from "react-router-dom";
import LoadingImg from "../../components/loading";
import NavbarComponent from "../../components/navbar";
import PropTypes from "prop-types";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import SiteModal from "./SiteModal";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchCompanies } from "../../actions/companies";
import { fetchCompanyContracts } from "../../actions/company";
import { requestFetchSite } from "../../services/backendRequests";

class AdministrationContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteSelected: null,
      missionSelected: null,
      search: "",
    };
    this.createNewSite = this.createNewSite.bind(this);
  }
  handleFilterChange = (event) => {
    this.setState({ search: event.target.value });
  };

  componentDidMount() {
    if (!this.props.company || !this.props.contract) {
      this.props.fetchCompanies();
      this.props.fetchCompanyContracts({ id: this.props.companyId });
      this.props.fetchSites({ id: this.props.contractId });
    } else {
      this.props.fetchSites(this.props.contract);
    }
  }

  openSiteEdition(site) {
    this.props.fetchTrial(site);
    requestFetchSite(site, this.props.user).then((siteSelected) => {
      siteSelected.missions = _.sortBy(siteSelected.missions, ["name"]);
      this.setState((state) => {
        return {
          ...state,
          missionSelected: null,
          siteSelected: {
            id: siteSelected.id,
            name: siteSelected.name,
            display_name: siteSelected.display_name,
            nb_microplots: siteSelected.nb_microplots,
            country: siteSelected.country,
            crop: siteSelected.crop,
            missions: siteSelected.missions,
            experiment_status: siteSelected.experiment_status,
          },
        };
      });
    });
  }

  createNewSite() {
    this.props.resetTrial();
    this.setState((state) => {
      return {
        ...state,
        missionSelected: null,
        siteSelected: {
          id: "",
          name: "Site " + (this.props.sites.all.length + 1),
          display_name: "Site " + (this.props.sites.all.length + 1),
          nb_microplots: 0,
          crop: "",
          country: null,
          missions: [],
          experiment_status: EXPERIMENT_STATUS.NOT_STARTED,
        },
      };
    });
  }

  deleteSite(event, site) {
    event.stopPropagation();
    if (
      window.confirm("Do you really want to delete site: " + site.name + " ?")
    ) {
      this.props.deleteSite(site);
      this.setState({ siteSelected: null });
    }
  }

  handleCloseSiteModal() {
    this.props.updateSiteAlert(""); // Clear alerts when closing modal
    this.setState({
      siteSelected: null,
    });
  }

  render() {
    if (!this.props.company || !this.props.contract) return "";
    const search = this.state.search.toLowerCase();
    const filteredSites = !this.props.sites.all
      ? []
      : search === ""
      ? this.props.sites.all
      : this.props.sites.all.filter((site) => {
          return site.name.toLowerCase().includes(search);
        });

    return (
      <div className="wrapper">
        <NavbarComponent />
        <AlertComponent />

        <div className="page-content container-fluid">
          <div className="row">
            <div className="col-12">
              <Link to={`/administration/company/${this.props.company.id}`}>
                <Button color="primary">
                  <i className="fa fa-lg fa-arrow-left me-1" />
                  Contracts
                </Button>
              </Link>
            </div>
          </div>

          <SiteModal
            company={this.props.company}
            contract={this.props.contract}
            site={this.state.siteSelected}
            isOpen={
              this.state.siteSelected !== null || this.props.sites.alert !== ""
            }
            handleCloseModal={() => this.handleCloseSiteModal()}
          />

          <Row>
            <Col className="pageTitle">
              <span className="h2">
                {this.props.company.name + " > " + this.props.contract.name}
              </span>
            </Col>
          </Row>

          <div className="col-12 section">
            <div className="mt-3 ps-3">
              <div className="mb-3 row">
                <label className="col-sm-2 col-form-label text-end my-auto">
                  Start date
                </label>
                <div className="col-sm-3">
                  <input
                    className="form-control"
                    defaultValue={this.props.contract.start_date}
                    type="date"
                    disabled
                  />
                </div>
                <label className="col-sm-2 col-form-label text-end my-auto">
                  End date
                </label>
                <div className="col-sm-3">
                  <input
                    className="form-control"
                    defaultValue={this.props.contract.end_date}
                    type="date"
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 mb-2">
                <AdminFilterHeader
                  onButtonClick={this.createNewSite}
                  onFilterChange={this.handleFilterChange}
                  onRefresh={() => this.props.fetchSites(this.props.contract)}
                  refreshing={this.props.refreshing}
                  buttonName="New site"
                  search={this.state.search}
                />
              </div>
              <div className="col-12 mb-2 pe-0">
                <Table striped>
                  <thead>
                    <tr>
                      <th className="w-25">
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Display name</span>
                      </th>
                      <th>
                        <span>Country</span>
                      </th>
                      <th>
                        <span>Crop</span>
                      </th>
                      <th>
                        <span>Number of microplots</span>
                      </th>
                      <th className="w-25">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.props.refreshing &&
                      filteredSites
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((site) => (
                          <tr
                            key={site.id + "_" + site.name}
                            className={"clickable-row"}
                            onClick={() => this.openSiteEdition(site)}
                          >
                            <td>{site.name}</td>
                            <td>{site.display_name}</td>
                            <td>{site.country}</td>
                            <td>{site.crop}</td>
                            <td>{site.nb_microplots}</td>
                            <td>
                              <CopyButton
                                value={site.id}
                                datatip="Copy site UUID"
                              />
                              <Button
                                data-tooltip-id="tooltip"
                                data-tooltip-content="View upload sessions"
                                className="ms-2 p-0"
                                color="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.props.history.push(
                                    "/uploadsessions?company=" +
                                      this.props.company.id +
                                      "&contract=" +
                                      this.props.contract.id +
                                      "&site=" +
                                      site.id
                                  );
                                  ReactTooltip.hide();
                                }}
                              >
                                <i className="fa fa-lg fa-upload" />
                              </Button>
                              <Button
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Delete site"
                                data-tooltip-variant="error"
                                className="ms-2 p-0"
                                color="link"
                                onClick={(event) => {
                                  this.deleteSite(event, site);
                                  ReactTooltip.hide();
                                }}
                              >
                                <i className="fa fa-lg fa-trash" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
                <LoadingImg visible={this.props.refreshing} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store, props) {
  const { companyId, contractId } = props.match.params;
  const companies = store.companies.all;
  const contracts = store.company.contracts;
  let company = {};
  let contract = {};

  if (companies) company = companies.find((item) => item.id === companyId);

  if (contracts)
    contract = _.filter(store.company.contracts, { id: contractId })[0];

  return {
    user: store.user,
    company: company,
    companyId: companyId,
    contractId: contractId,
    contract: contract,
    sites: store.sites,
    refreshing: store.sites.table.refreshing,
  };
}

const callbacks = {
  fetchSites,
  fetchTrial,
  createSite,
  deleteSite,
  fetchCompanies,
  fetchCompanyContracts,
  updateSiteAlert,
  resetTrial,
};

export default connect(mapStateToProps, callbacks)(AdministrationContract);

AdministrationContract.propTypes = {
  fetchCompanies: PropTypes.func.isRequired,
  fetchCompanyContracts: PropTypes.func.isRequired,
  fetchSites: PropTypes.func.isRequired,
  fetchTrial: PropTypes.func.isRequired,
  resetTrial: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  updateSiteAlert: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
};
