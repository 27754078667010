import { Button, Offcanvas } from "reactstrap";
import { useMemo, useState } from "react";

import { ANALYTICS_EVENTS } from "../constants";
import { AdvancedFiltering } from "../powerdash/analyticsDashboard/components/advancedFiltering/AdvancedFiltering";
import PropTypes from "prop-types";
import ResultDownloadModal from "../clientDashboard/resultDownloadModal";
import Select from "react-select";
import { hasBackofficeAccess } from "../services/roles";
import { selectTraitsMetricsAndAggregatedFeatures } from "../selectors/resultMap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTracking } from "../analytics";

export const MapNavbar = ({
  modeActivated,
  onSelectContract,
  onSelectTrial,
  onBackToMarkerView,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    selectedContract,
    trial,
    trial_date: trialDate,
    refreshing,
    filteringProfile,
    traitsListForMap: traits,
  } = useSelector((state) => state.resultMap);
  const user = useSelector((state) => state.user);

  const history = useHistory();

  const sortedUserTrials = useMemo(
    () =>
      user.trials
        .filter((trial) =>
          selectedContract?.sites.map(({ id }) => id).includes(trial.id)
        )
        .sort((trial1, trial2) =>
          trial1.display_name.localeCompare(trial2.display_name)
        ),
    [selectedContract?.sites, user.trials]
  );

  // Advanced filtering
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const { trackEvent } = useTracking();

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
    trackEvent(ANALYTICS_EVENTS.MAP_AF_BUTTON);
  };

  const [aggregatedPrefilteredFeatures, traitsMetrics] = useSelector(
    selectTraitsMetricsAndAggregatedFeatures
  );

  // Key given to advanced filtering to reset component whenever the date changes
  // and/or the scope is reset
  const advancedFilteringKey = useMemo(
    () =>
      [
        trialDate,
        filteringProfile.scope,
        filteringProfile.id,
        ...Object.keys(traitsMetrics),
      ].join(","),
    [trialDate, filteringProfile.scope, filteringProfile.id, traitsMetrics]
  );

  return (
    <div className="map-navbar">
      <div>
        <Select
          options={user.contracts}
          value={selectedContract}
          placeholder="Select campaign"
          id="contract"
          name="campaign"
          onChange={(selectedOption) => onSelectContract(selectedOption)}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id.toString()}
          isDisabled={refreshing || modeActivated || trial != null}
          isClearable
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              fontSize: "large",
            }),
          }}
        />
      </div>
      {selectedContract != null && (
        <div>
          <Select
            options={sortedUserTrials}
            value={trial}
            placeholder="Select site"
            id="trial"
            name="trial"
            onChange={(selectedOption) => onSelectTrial(selectedOption)}
            getOptionLabel={({ display_name }) => display_name}
            getOptionValue={({ id }) => id.toString()}
            isDisabled={refreshing || modeActivated}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                fontSize: "large",
              }),
            }}
          />
        </div>
      )}

      {trial?.links && (
        <div className="download-button-wrapper">
          <Button
            data-tooltip-id="tooltip"
            data-tooltip-content="Back to entire map"
            className="btn btn-hiphen"
            onClick={() => onBackToMarkerView()}
          >
            <i className="fa fa-lg fa-arrow-left" />
          </Button>
          {hasBackofficeAccess(user.self) && (
            <Button
              data-tooltip-id="tooltip"
              data-tooltip-content="Open in administration"
              className="btn btn-hiphen"
              onClick={() =>
                history.push(
                  `/administration/company/${trial.company_id}/contract/${trial.contract_id}`
                )
              }
            >
              <i className="fa fa-lg fa-cogs" />
            </Button>
          )}
          <Button
            className="hiphen-green-button download-button"
            size="sm"
            onClick={() => setIsModalOpen(true)}
          >
            <i className="fa fa-download"></i> Download results
          </Button>
        </div>
      )}
      {trial != null && (
        <ResultDownloadModal
          trial={trial}
          mission={{ date: trialDate }}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          user={user}
          trackEvent={trackEvent}
        />
      )}
      {trial && (
        <Button
          className="hiphen-green-button"
          size="sm"
          onClick={toggleOffcanvas}
        >
          <i className="fa fa-filter" /> Advanced Filtering
        </Button>
      )}

      <Offcanvas
        isOpen={isOffcanvasOpen}
        direction="end"
        backdrop={false}
        className="offcanvas-wrap"
        toggle={toggleOffcanvas}
      >
        <AdvancedFiltering
          key={advancedFilteringKey}
          data={aggregatedPrefilteredFeatures}
          traitsMetrics={traitsMetrics}
          traits={traits}
          toggleOffcanvas={toggleOffcanvas}
        />
      </Offcanvas>
    </div>
  );
};

MapNavbar.propTypes = {
  onSelectContract: PropTypes.func.isRequired,
  onBackToMarkerView: PropTypes.func.isRequired,
  modeActivated: PropTypes.bool.isRequired,
  onSelectTrial: PropTypes.func.isRequired,
};
