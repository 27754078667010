import "./uploadYourOwnData.scss";

import { useCallback, useEffect, useRef, useState } from "react";

import { ANALYTICS_EVENTS } from "../../../../constants";
import { Button } from "reactstrap";
import { Dashboard } from "@uppy/react";
import Papa from "papaparse";
import { PropTypes } from "prop-types";
import Uppy from "@uppy/core";
import { VerifyYourOwnData } from "./VerifyYourOwnData";
import download from "downloadjs";
import { useTracking } from "../../../../analytics";

export function UploadYourOwnData({ features, trial, setUploadIsOpen }) {
  const [falseDataImported, setFalseDataImported] = useState(false);
  const [clientData, setClientData] = useState(null);

  const uppyRef = useRef(
    Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["text/csv"],
      },
    })
  );

  const { trackEvent } = useTracking();

  const downloadCSVTemplate = useCallback(() => {
    const csvRows = features
      .map(
        (feature) =>
          `${feature.id};${feature.displayId};${feature.genotype || ""};${
            feature.modality || ""
          };${feature.layer || ""};${feature.experiment || ""};${
            feature.properties.control_variety || ""
          }\n`
      )
      .join("");

    const csvData = `cloverfieldId;id;genotype;modality;layer;experiment;control_variety\n${csvRows}`;
    const fileName = `template_${trial.display_name}_${features[0].date}`;
    download(csvData, fileName, "text/csv");
    trackEvent(ANALYTICS_EVENTS.UPLOAD_YOUR_OWN_DATA_DOWNLOAD_CSV_TEMPLATE);
  }, [features, trackEvent, trial.display_name]);

  const uppy = uppyRef.current;

  useEffect(() => {
    uppy.on("file-added", (file) => {
      Papa.parse(file.data, {
        header: true,
        skipEmptyLines: "greedy",
        transformHeader: (header, index) =>
          header.trim() === "" ? `no_name_${index + 1}` : header.trim(),
        complete: (results) => {
          if (results.meta.fields.includes("cloverfieldId")) {
            setClientData(results);
            setFalseDataImported(false);
            trackEvent(ANALYTICS_EVENTS.ADD_FILE_UPLOAD_YOUR_OWN_DATA);
          } else {
            setFalseDataImported(true);
          }
        },
      });
    });
    return () => {
      uppy.close();
    };
  }, [trackEvent, uppy]);

  return (
    <div className="upload-own-data">
      <div className="d-flex justify-content-between">
        <h6>
          <i className="fa fa-upload me-1" aria-hidden="true" />
          Upload your data
        </h6>
        <i
          className="fa fa-question-circle justify-content-end"
          aria-hidden="true"
          data-tooltip-id="tooltip"
          data-tooltip-html="follow those steps <br/> 1. download CSV template <br/> 2. Add your data <br/> 3. drop your modified CSV file <br/> 4. start processing"
        />
      </div>
      <Button
        className="hiphen-green-button mt-2"
        onClick={downloadCSVTemplate}
      >
        Download CSV template
      </Button>
      {falseDataImported && (
        <div className="danger mt-5">
          Cannot find 'cloverfieldId' column, please try again...
        </div>
      )}
      <>
        <p className="message mt-4">
          Your data uploaded here will not be saved in our database
        </p>
        {clientData ? (
          <VerifyYourOwnData
            data={clientData.data}
            headers={clientData.meta.fields}
            setUploadIsOpen={setUploadIsOpen}
          />
        ) : (
          !falseDataImported && (
            <Dashboard uppy={uppy} width="100%" height="20vh" />
          )
        )}
      </>
    </div>
  );
}

UploadYourOwnData.propTypes = {
  features: PropTypes.array.isRequired,
  trial: PropTypes.object.isRequired,
  setUploadIsOpen: PropTypes.func.isRequired,
};
