import { Button, Table } from "reactstrap";
import {
  createContract,
  deleteContract,
  fetchCompanyContracts,
  resetContractAlert,
  updateContractAlert,
  updateContractInformation,
  updateContractUsers,
} from "../../actions/company";

import AdminFilterHeader from "../../components/AdminFilterHeader/AdminFilterHeader";
import AlertComponent from "../../components/alert";
import { ButtonAddRemoveUser } from "../../components/ButtonAddRemoveUser";
import ContractModal from "./ContractModal";
import { ContractUsersModal } from "./ContractUsersModal";
import CopyButton from "../../components/copyButton";
import { Link } from "react-router-dom";
import LoadingImg from "../../components/loading";
import NavbarComponent from "../../components/navbar";
import { OPTIONS_SYSTEM } from "../../constants";
import PageTitle from "../../components/pageTitle";
import PropTypes from "prop-types";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchCompanies } from "../../actions/companies";
import { fetchUsers } from "../../actions/users";

class AdministrationCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedContract: {},
      // Accepted values: "", "contract", "users"
      currentModal: "",
      search: "",
    };
    this.openContractModalForCreation =
      this.openContractModalForCreation.bind(this);
  }
  handleFilterChange = (event) => {
    this.setState({ search: event.target.value });
  };

  resetState() {
    this.setState({
      updatedUser: {},
      currentModal: "",
    });
  }

  componentDidMount() {
    this.props.fetchUsers();
    if (!this.props.company) {
      this.props.fetchCompanies(this.props.user);
      this.props.fetchCompanyContracts({ id: this.props.companyId });
    } else {
      this.props.fetchCompanyContracts(this.props.company);
    }
  }

  handleContractRowClick(company, contract) {
    this.props.history.push(
      `/administration/company/${company.id}/contract/${contract.id}`
    );
  }

  openContractModalForUpdate(event, contract, modalType) {
    event.stopPropagation();
    this.setState({
      updatedContract: contract,
      currentModal: modalType,
    });
  }

  /** CONTRACT MODAL */

  openContractModalForCreation(event) {
    event.stopPropagation();
    this.setState({
      updatedContract: {
        active: false,
        name: "",
        start_date: "",
        end_date: "",
      },
      currentModal: "contract",
    });
  }

  submitContractModal = (contract) => {
    if (this.checkContractUnicity(contract)) {
      const promise = contract.id
        ? this.props.onUpdateContract(this.props.company, contract)
        : this.props.onCreateContract(this.props.company, contract);

      promise
        .then(() => this.resetState())
        .catch((err) => this.props.updateContractAlert(err));
    }
  };

  submitContractUsersModal = (contractUserList) =>
    this.props
      .updateContractUsers(
        contractUserList,
        this.state.updatedContract.id,
        this.props.company
      )
      .then(() => this.resetState());

  checkContractUnicity(contract) {
    const contractsFiltered = _.filter(
      this.props.company.contracts,
      (item) => item.id !== contract.id
    );
    if (_.find(contractsFiltered, { name: contract.name })) {
      this.props.updateContractAlert("Campaign name must be unique.");
      return false;
    }
    return true;
  }

  cancelContractModal = () => {
    this.props.resetContractAlert();
    this.resetState();
  };

  deleteContract(event, contract) {
    event.stopPropagation();
    if (
      window.confirm(
        "Do you really want to delete campaign: " + contract.name + " ?"
      )
    )
      this.props.deleteContract(this.props.company, contract);
  }

  renderCompanyCard() {
    const search = this.state.search.toLowerCase();
    const filteredContracts = !this.props.contracts
      ? []
      : search === ""
      ? this.props.contracts
      : this.props.contracts.filter((contract) => {
          return contract.name.toLowerCase().includes(search);
        });
    return (
      <div className="col-12 section">
        <div className="row mt-2">
          <AdminFilterHeader
            onButtonClick={this.openContractModalForCreation}
            onFilterChange={this.handleFilterChange}
            onRefresh={() =>
              this.props.fetchCompanyContracts(this.props.company)
            }
            refreshing={this.props.refreshingContracts}
            buttonName="New campaign"
            search={this.state.search}
          />
          <div className="col-12 mb-2">
            <Table striped hover>
              <thead>
                <tr>
                  <th>
                    <span>Active</span>
                  </th>
                  <th className="w-25">
                    <span>Name</span>
                  </th>
                  <th>
                    <span>System</span>
                  </th>
                  <th>
                    <span>Start date</span>
                  </th>
                  <th>
                    <span>End date</span>
                  </th>
                  <th>
                    <span>Number of microplots</span>
                  </th>
                  <th>
                    <span>Number of sites</span>
                  </th>
                  <th>
                    <span>Users</span>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!this.props.refreshingContracts &&
                  filteredContracts
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((contract) => (
                      <tr
                        key={contract.id}
                        onClick={(event) =>
                          this.handleContractRowClick(
                            this.props.company,
                            contract
                          )
                        }
                        className="clickable-row"
                      >
                        <td>
                          {contract.active ? (
                            <i
                              className="fa fa-lg fa-check text-success"
                              aria-hidden="true"
                            />
                          ) : (
                            <i
                              className="fa fa-lg fa-times text-danger"
                              aria-hidden="true"
                            />
                          )}
                        </td>
                        <td>{contract.name}</td>
                        <td>
                          {OPTIONS_SYSTEM.find(
                            (item) => item.value === contract.system
                          )?.label ?? ""}
                        </td>
                        <td>{contract.start_date}</td>
                        <td>{contract.end_date}</td>
                        <td>{contract.total_microplots}</td>
                        <td>{contract.total_sites}</td>
                        <td>
                          {contract.users.length}
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Update campaign users"
                            className="ms-2 p-0"
                            color="link"
                            onClick={(event) =>
                              this.openContractModalForUpdate(
                                event,
                                contract,
                                "users"
                              )
                            }
                          >
                            <i className="fa fa-lg fa-users" />
                          </Button>
                        </td>
                        <td>
                          <ButtonAddRemoveUser
                            contract={contract}
                            company={this.props.company}
                            hasContractAccess={
                              !!contract.users.find(
                                ({ id }) => id === this.props.user.self.id
                              )
                            }
                          />
                          <CopyButton
                            value={contract.id}
                            datatip="Copy campaign UUID"
                          />
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Edit campaign information"
                            className="ms-2 p-0"
                            color="link"
                            onClick={(event) =>
                              this.openContractModalForUpdate(
                                event,
                                contract,
                                "contract"
                              )
                            }
                          >
                            <i className="fa fa-lg fa-pencil" />
                          </Button>
                          <Button
                            data-tooltip-id="tooltip"
                            data-tooltip-content="View upload sessions"
                            className="ms-2 p-0"
                            color="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.history.push(
                                "/uploadsessions?company=" +
                                  this.props.company.id +
                                  "&contract=" +
                                  contract.id
                              );
                              ReactTooltip.hide();
                            }}
                          >
                            <i className="fa fa-lg fa-upload" />
                          </Button>
                          {!contract.active && (
                            <Button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Delete campaign"
                              data-tooltip-variant="error"
                              className="ms-2 p-0"
                              color="link"
                              onClick={(event) =>
                                this.deleteContract(event, contract)
                              }
                            >
                              <i className="fa fa-lg fa-trash" />
                            </Button>
                          )}
                          {contract.active && (
                            <Button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Please deactivate before deleting."
                              data-tooltip-offset="{'left':10}"
                              className="ms-2 p-0"
                              color="secondary"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <i className="fa fa-lg fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
            <LoadingImg visible={this.props.refreshingContracts} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.company) return "";

    return (
      <div className="wrapper">
        <NavbarComponent />
        <AlertComponent />
        {this.state.currentModal === "contract" && (
          <ContractModal
            alert={this.props.contractUpdateAlert}
            isOpen
            onClose={this.cancelContractModal}
            onSubmit={this.submitContractModal}
            contract={this.state.updatedContract}
            users={this.props.users}
          />
        )}
        {this.state.updatedContract.users &&
          this.state.currentModal === "users" && (
            // updatedContract state defaults to {}, if users defined then contract is selected
            <ContractUsersModal
              contractUsers={this.state.updatedContract.users}
              isOpen
              onUpdate={this.submitContractUsersModal}
              onClose={this.cancelContractModal}
            />
          )}
        <div className="page-content container-fluid">
          <div className="row">
            <div className="col-12">
              <Link to="/administration">
                <Button color="primary">
                  <i className="fa fa-lg fa-arrow-left" /> Administration
                </Button>
              </Link>
            </div>
          </div>
          <PageTitle title={this.props.company.name} />
          {this.renderCompanyCard()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(store, props) {
  const { companyId } = props.match.params;
  const companies = store.companies.all;
  let company = {};

  if (companies) company = companies.find((item) => item.id === companyId);

  return {
    user: store.user,
    companyId: companyId,
    company: company,
    contracts: store.company.contracts,
    contractUpdateAlert: store.company.contractUpdateAlert,
    refreshingContracts: store.company.contractsTable.refreshing,
  };
}

const callbacks = {
  onUpdateContract: updateContractInformation,
  onCreateContract: createContract,
  updateContractAlert,
  resetContractAlert,
  deleteContract,
  fetchCompanyContracts,
  fetchCompanies,
  updateContractUsers,
  fetchUsers,
};

export default connect(mapStateToProps, callbacks)(AdministrationCompany);

AdministrationCompany.propTypes = {
  fetchCompanies: PropTypes.func.isRequired,
  fetchCompanyContracts: PropTypes.func.isRequired,
  refreshingContracts: PropTypes.bool.isRequired,
  onUpdateContract: PropTypes.func.isRequired,
  onCreateContract: PropTypes.func.isRequired,
  updateContractUsers: PropTypes.func.isRequired,
  resetContractAlert: PropTypes.func.isRequired,
  contractUpdateAlert: PropTypes.string.isRequired,
  updateContractAlert: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
};
