import "./siteModal.css";

import {
  Alert,
  Badge,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { EXPERIMENT_STATUS, HIPHEN_GREEN } from "../../constants.js";
import React, { useMemo, useState } from "react";
import {
  createSite,
  fetchTrial,
  updateSite,
  updateSiteAlert,
} from "../../actions/sites";
import {
  requestCreateMission,
  requestDeleteMission,
} from "../../services/backendRequests.js";

import { ButtonAddRemoveUser } from "../../components/ButtonAddRemoveUser";
import { CountryFlag } from "../../powerdash/components/countryFlag/countryFlag.jsx";
import PropTypes from "prop-types";
import { RefreshButton } from "../../components/dataTable";
import Select from "react-select";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchCrops } from "../../actions/crops";
import { iso31661 } from "iso-3166";
import { withRouter } from "react-router-dom";

const iso31661Options = iso31661.map((c) => ({
  label: c.name,
  value: c.alpha3,
}));

const MissionItem = ({ date, missions, createNewMission, deleteMission }) => {
  const mission = useMemo(
    () => missions.find(({ date: missionDate }) => missionDate === date),
    [date, missions]
  );

  const [hovered, setHovered] = useState(false);

  return (
    <div className="mission-item">
      <Badge
        className={`hiphen-badge clickable ${Boolean(mission) ? "active" : ""}`}
        onClick={() => {
          if (!Boolean(mission)) createNewMission(date);
          else deleteMission(mission);
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {date}
      </Badge>

      {hovered ? (
        Boolean(mission) ? (
          <>
            <i className="fa fa-close discrete-icon" />
            <i>Remove this date</i>
          </>
        ) : (
          <>
            <i className="fa fa-long-arrow-right discrete-icon" />
            <i>Deliver this date</i>
          </>
        )
      ) : (
        Boolean(mission) && (
          <span style={{ color: HIPHEN_GREEN }}>
            <i className="fa fa-check" /> Delivered
          </span>
        )
      )}
    </div>
  );
};

class SiteModal extends React.Component {
  state = {
    siteSelected: null,
    siteChanged: false,
  };

  componentDidMount() {
    this.props.fetchCrops();
  }

  createNewMission = async (date) => {
    const missionCreated = await requestCreateMission(
      this.state.siteSelected,
      { date },
      this.props.user
    );

    this.setState((prevState) => {
      var siteSelectedWithNewMissions = prevState.siteSelected;
      siteSelectedWithNewMissions.missions = _.concat(
        siteSelectedWithNewMissions.missions,
        missionCreated
      );
      return { siteSelected: siteSelectedWithNewMissions };
    });
  };

  setSiteValue(attr, value) {
    this.setState((state) => {
      const siteWithNewValue = state.siteSelected;
      siteWithNewValue[attr] = value;
      return {
        ...state,
        siteSelected: siteWithNewValue,
        siteChanged: true,
      };
    });
  }

  checkSiteUnicity(site) {
    // list containing with same name / display_name AND that have different id
    // this ensures that we do not compare current site to itself in edition mode
    const sitesList = this.props.sites.all.filter(
      ({ name, display_name, id }) =>
        (name === site.name || display_name === site.display_name) &&
        id !== site.id
    );
    if (sitesList && sitesList.length > 0) {
      this.props.updateSiteAlert("Site name and display name must be unique.");
      return false;
    }

    return true;
  }

  saveSite(site) {
    if (this.checkSiteUnicity(site)) {
      if (site.id) this.props.updateSite(this.props.contract, site);
      else this.props.createSite(this.props.contract, site);
      this.setState({ ...this.state, siteChanged: false });
    }
  }

  deleteMission = async (mission) => {
    await requestDeleteMission(mission, this.props.user);

    this.setState((prevState) => {
      const siteSelectedWithNewMissions = prevState.siteSelected;
      siteSelectedWithNewMissions.missions = _.filter(
        siteSelectedWithNewMissions.missions,
        (m) => m.id !== mission.id
      );
      return { siteSelected: siteSelectedWithNewMissions };
    });
  };

  displaySite() {
    const cropOptions = [
      { label: "No crop", value: "" },
      ...this.props.crops
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map(({ name }) => ({ label: name, value: name })),
    ];
    return (
      <Row>
        <Col xs="6" className="site-form">
          <h5 className="fw-bold">Edit site information</h5>
          <div>
            <label>Technical site name*</label>
            <input
              className="form-control"
              value={this.state.siteSelected.name}
              type="text"
              onChange={(e) => {
                this.setSiteValue("name", e.target.value);
              }}
            />
          </div>
          <div>
            <label>Display site name*</label>
            <input
              className="form-control"
              value={this.state.siteSelected.display_name}
              type="text"
              onChange={(e) =>
                this.setSiteValue(
                  "display_name",
                  e.target.value || this.state.siteSelected.name
                )
              }
            />
          </div>
          <div>
            <label>Crop</label>
            <Select
              value={cropOptions.find(
                ({ value }) => value === this.state.siteSelected.crop
              )}
              onChange={({ value }) => this.setSiteValue("crop", value)}
              options={cropOptions}
            />
          </div>
          <div>
            <label>Country</label>
            <Select
              isSearchable
              value={
                this.state.siteSelected.country
                  ? iso31661Options.find(
                      (c) => c.value === this.state.siteSelected.country
                    )
                  : null
              }
              onChange={(selectedOption) => {
                this.setSiteValue("country", selectedOption.value);
              }}
              options={iso31661Options}
              required
            />
          </div>
          <div>
            <label>Microplots</label>
            <input
              className="form-control"
              value={this.state.siteSelected.nb_microplots}
              type="number"
              min="0"
              onChange={(e) =>
                this.setSiteValue("nb_microplots", e.target.value)
              }
            />
          </div>
          {this.props.contract.has_experiment && (
            <div>
              <label>Plot map status</label>
              <Input
                type="select"
                value={this.state.siteSelected.experiment_status}
                onChange={(e) =>
                  this.setSiteValue("experiment_status", e.target.value)
                }
              >
                {Object.keys(EXPERIMENT_STATUS).map((key) => {
                  if (key !== "properties")
                    return (
                      <option key={key} value={EXPERIMENT_STATUS[key]}>
                        {
                          EXPERIMENT_STATUS.properties[EXPERIMENT_STATUS[key]]
                            .label
                        }
                      </option>
                    );
                })}
              </Input>
            </div>
          )}
          <div className="save-site">
            <Button
              color="primary"
              onClick={() => this.saveSite(this.state.siteSelected)}
              disabled={!this.state.siteChanged}
            >
              <i className="fa fa-save" /> Save site
            </Button>
          </div>
        </Col>
        <Col xs="6">
          <h5 className="fw-bold">
            Manage available dates{" "}
            <RefreshButton
              refreshing={this.props.refreshingSiteTrial}
              onRefresh={() => this.props.fetchTrial(this.state.siteSelected)}
            />
          </h5>

          <div className="site-modal-mission-list">
            {this.props.sites.siteTrial?.trial_dates.map((date, index) => (
              <MissionItem
                date={date}
                missions={this.state.siteSelected.missions}
                createNewMission={this.createNewMission}
                deleteMission={this.deleteMission}
                key={index}
              />
            ))}
            {!this.props.sites.siteTrial && !this.props.refreshingSiteTrial && (
              <p className="text-info fw-light font-italic">
                No result data available
              </p>
            )}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Modal
        className="modal-xl site-modal"
        isOpen={this.props.isOpen}
        onOpened={() =>
          this.setState({
            siteSelected: this.props.site,
          })
        }
        onClosed={() =>
          this.setState({
            siteSelected: null,
            siteChanged: false,
          })
        }
      >
        <ModalBody className="site-modal-body">
          <div className="site-modal-header">
            <h3 className="site-modal-header-title">
              <CountryFlag alpha={this.props.site?.country} />
              {this.props.site?.display_name}
              <Badge className="hiphen-badge disabled">
                {this.props.site?.name}
              </Badge>
            </h3>
            {this.props.sites.siteTrial &&
              Boolean(this.state.siteSelected?.missions?.length) && (
                <>
                  {!!this.props.contract.users.find(
                    ({ id }) => id === this.props.user.self.id
                  ) ? (
                    <Button
                      className="p-1"
                      color="primary"
                      onClick={() =>
                        this.props.history.push(`/map/${this.props.site?.id}`)
                      }
                    >
                      <i className="fa fa-lg fa-globe" /> View on map
                    </Button>
                  ) : (
                    <ButtonAddRemoveUser
                      contract={this.props.contract}
                      company={this.props.company}
                      hasContractAccess={
                        !!this.props.contract.users.find(
                          ({ id }) => id === this.props.user.self.id
                        )
                      }
                    />
                  )}
                </>
              )}
          </div>
          {this.props.sites.alert ? (
            <Alert className="mx-auto col-10 text-center" color="danger">
              {this.props.sites.alert}
            </Alert>
          ) : null}
          <hr />
          {this.state.siteSelected && this.displaySite()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => this.props.handleCloseModal()}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(store) {
  return {
    crops: store.crops.all,
    refreshingSiteTrial: store.sites.refreshingSiteTrial,
    sites: store.sites,
    siteTrial: store.sites.siteTrial,
    user: store.user,
  };
}

const callbacks = {
  fetchCrops,
  fetchTrial,
  createSite,
  updateSite,
  updateSiteAlert,
};

export default withRouter(connect(mapStateToProps, callbacks)(SiteModal));

MissionItem.propTypes = {
  date: PropTypes.string.isRequired,
  missions: PropTypes.array.isRequired,
  createNewMission: PropTypes.func.isRequired,
  deleteMission: PropTypes.func.isRequired,
};

SiteModal.propTypes = {
  user: PropTypes.object.isRequired,
  site: PropTypes.object,
  siteTrial: PropTypes.object,
  company: PropTypes.object,
  contract: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  fetchCrops: PropTypes.func.isRequired,
  fetchTrial: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  updateSite: PropTypes.func.isRequired,
  updateSiteAlert: PropTypes.func.isRequired,
  refreshingSiteTrial: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
