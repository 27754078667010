/* eslint-disable react/prop-types */
import "./navbar.css";

import React, { Component } from "react";
import { fetchUserContracts, setSidebarCollapse } from "../actions/user";
import { hasAnalyticsRole, hasMonitoringRole } from "../users/rolesUtil";
import { useHistory, useLocation, withRouter } from "react-router-dom";

import DEFAULT_TRAIT_ICON from "../static/img/default_trait_icon.png";
import { HelpDropdown } from "./HelpDropdown";
import PropTypes from "prop-types";
import UserCardComponent from "./UserCard";
import { connect } from "react-redux";
import { hasBackofficeAccess } from "../services/roles";
import moment from "moment";

function LargeIcon({ icon }) {
  return <i className={`fa fa-lg ${icon}`} />;
}

function NavIcon({ title, path, icon, jsx }) {
  const history = useHistory();
  const location = useLocation();
  const active = "/" + location.pathname.split("/")[1] === path;
  const handleClick = () => history.push(path);
  const activeClass = active ? "btn btn-link active" : "btn btn-link";

  return (
    <li>
      <button
        onClick={() => handleClick(path)}
        data-tooltip-id="tooltip"
        data-tooltip-content={title}
        data-tooltip-place="right"
        className={activeClass}
      >
        {jsx ?? <LargeIcon icon={icon} />}
        <span>{title}</span>
      </button>
    </li>
  );
}

function CloverfieldIcon() {
  const history = useHistory();
  const handleClick = (p) => history.push(p);
  return (
    <i
      // Christmas version in december
      className={`icon-cloverfield${moment().month() === 11 ? "-xmas" : ""}`}
      onClick={() => handleClick("/")}
    />
  );
}

function userCanViewMonitoring(contracts) {
  let viewMonitoring = false;
  contracts.forEach((contract) => {
    if (hasMonitoringRole(contract.roles)) viewMonitoring = true;
  });
  return viewMonitoring;
}

function userCanViewAnalytics(contracts) {
  let viewAnalytics = false;
  contracts.forEach((contract) => {
    if (hasAnalyticsRole(contract.roles)) viewAnalytics = true;
  });
  return viewAnalytics;
}

class NavbarComponent extends Component {
  // eslint-disable-next-line max-lines-per-function
  renderBackofficeNavigation() {
    return [
      <li key="splitter" className="border-top border-secondary" />,
      <NavIcon
        key="users"
        title="Users"
        path="/users"
        icon="fa-user-circle-o"
      />,
      <NavIcon
        key="uploadSessions"
        title="Upload sessions"
        path="/uploadsessions"
        icon="fa-upload"
      />,
      <NavIcon
        key="Plot maps"
        title="Plot maps"
        path="/plot_maps"
        icon="fa-pencil-square-o"
      />,
      <NavIcon
        key="administration"
        title="Administration"
        path="/administration"
        icon="fa-cogs"
      />,
    ];
  }

  renderClientNavigation() {
    return [
      <NavIcon key="map" title="Map" path="/map" icon="fa-globe" />,

      userCanViewAnalytics(this.props.contracts) && (
        <NavIcon
          key="analyticsdashboard"
          title="Analytics"
          path="/analyticsdashboard"
          icon="fa-area-chart"
        />
      ),
      <NavIcon
        key="clientdashboard"
        title="Campaigns"
        path="/clientdashboard"
        icon="fa-file-text"
      />,
      userCanViewMonitoring(this.props.contracts) && (
        <NavIcon
          key="mission_monitoring"
          title="Monitoring"
          path="/monitoring"
          icon="fa-pie-chart"
        />
      ),
      <NavIcon
        key="upload"
        title="Upload"
        path="/upload"
        icon="fa-cloud-upload"
      />,
      <li key="splitter" className="border-top border-secondary" />,
      <NavIcon
        key="glossary"
        title="Glossary"
        path="/glossary"
        icon="fa-book"
        jsx={
          <i>
            <img src={DEFAULT_TRAIT_ICON} alt="" />
          </i>
        }
      />,
    ];
  }

  render() {
    return (
      <nav id="sidebar" className={this.props.sidebarCollapse ? "reduced" : ""}>
        <div className="sidebar-header">
          <CloverfieldIcon />
        </div>
        <ul className="list-unstyled components">
          {this.renderClientNavigation()}
          {hasBackofficeAccess(this.props.user)
            ? this.renderBackofficeNavigation()
            : ""}
        </ul>
        <div className="sidebar-footer">
          <button
            type="button"
            id="sidebarCollapse"
            className={
              "btn btn-info " + (this.props.sidebarCollapse ? "reduced" : "")
            }
            onClick={() => this.props.setSidebarCollapse()}
          >
            <span />
            <span />
            <span />
          </button>
          <HelpDropdown />
          <UserCardComponent />
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.self,
    contracts: state.user.contracts,
    sidebarCollapse: state.user.sidebarCollapse,
  };
}

const callbacks = {
  setSidebarCollapse,
  fetchUserContracts,
};

export default withRouter(connect(mapStateToProps, callbacks)(NavbarComponent));

NavbarComponent.propTypes = {
  user: PropTypes.object.isRequired,
  setSidebarCollapse: PropTypes.func.isRequired,
};
