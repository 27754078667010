import { Badge } from "reactstrap";
import { ComparativeScatter } from "./ComparativeScatter/ComparativeScatter";
import { GenotypeAnalyzer } from "./GenotypeAnalyzer";
import { ModalityProfiler } from "./ModalityProfiler";
import PropTypes from "prop-types";
import { formatDate } from "../../../utils";
import { useSelector } from "react-redux";
import { useState } from "react";

/*
 * MultipleChart is used to display different charts under tabs you can choose from
 */
export const MultipleChart = ({
  displayTabs,
  selectedTrait,
  scopeTrait,
  getColor,
  plotRatingConfig,
}) => {
  const [selectedDate, trial, modalities] = useSelector(({ resultMap }) => [
    resultMap.trial_date,
    resultMap.trial,
    resultMap.distinctModalities,
  ]);

  const tabs = [
    { name: "Genotype Analyzer" },
    { name: "Modality Profiler", disabled: modalities.length < 2 },
    { name: "Comparative Scatter", disabled: !Boolean(trial) },
  ];

  const [selectedChart, setSelectedChart] = useState(tabs[0].name);

  // Lifted the state up for scatter tool in order to keep choices when changing tabs
  const [xAxis, setXAxis] = useState(selectedTrait.technical_name);
  const [yAxis, setYAxis] = useState(selectedTrait.technical_name);
  const [showRegression, setShowRegression] = useState(true);
  const [scatterOpacity, setScatterOpacity] = useState(0.5);

  return (
    <>
      {displayTabs && (
        <div className="multiple-chart-selector">
          {tabs.map(({ name, disabled }) => (
            <Badge
              pill
              key={name}
              className={`hiphen-badge ${
                name === selectedChart ? "active" : ""
              } ${disabled ? "disabled" : "clickable"}`}
              onClick={() => {
                if (!disabled) setSelectedChart(name);
              }}
            >
              {name}
            </Badge>
          ))}
        </div>
      )}
      {selectedChart === "Genotype Analyzer" && (
        <GenotypeAnalyzer
          title={`Genotypes performance on ${selectedTrait.name} ${
            scopeTrait.unit ? `(${scopeTrait.unit}) ` : ""
          }${selectedTrait.isAddon ? "" : `on ${formatDate(selectedDate)}`}`}
          trait={selectedTrait}
          getColor={getColor}
          exportName={`gen_ranking_${trial?.display_name}_${
            selectedTrait.name
          }${selectedTrait.isAddon ? "" : `_${formatDate(selectedDate)}`}`}
        />
      )}
      {selectedChart === "Modality Profiler" && (
        <ModalityProfiler
          title={`Modalities performance on ${selectedTrait.name} ${
            selectedTrait.isAddon ? "" : `on ${formatDate(selectedDate)}`
          }`}
          trait={selectedTrait}
          exportName={`modality_performance_${trial?.display_name}_${formatDate(
            selectedDate
          )}`}
        />
      )}
      {selectedChart === "Comparative Scatter" && (
        <ComparativeScatter
          exportName={`comparative_scatter_${trial?.display_name}_${formatDate(
            selectedDate
          )}`}
          plotRatingConfig={plotRatingConfig}
          xAxis={xAxis}
          setXAxis={setXAxis}
          yAxis={yAxis}
          setYAxis={setYAxis}
          showRegression={showRegression}
          setShowRegression={setShowRegression}
          scatterOpacity={scatterOpacity}
          setScatterOpacity={setScatterOpacity}
        />
      )}
    </>
  );
};

MultipleChart.propTypes = {
  getColor: PropTypes.func.isRequired,
  displayTabs: PropTypes.bool,
  selectedTrait: PropTypes.object,
  scopeTrait: PropTypes.object,
  plotRatingConfig: PropTypes.array,
};
