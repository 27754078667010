import "./focusList.css";

import {
  AD_MAX_VISUALIZED_ELEMENTS,
  HIPHEN_GREEN,
} from "../../../../constants";
import { Badge, Button, Input, ListGroup, ListGroupItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { ComplexSearch } from "../../../components/complexSearch/ComplexSearch";
import { ExpandButton } from "../../../components/expandButton/expandButton";
import PropTypes from "prop-types";
import { addFocusedVarieties } from "../../../../actions/resultMap";
import classNames from "classnames";
import { removeElementFromArray } from "../../utils";
import { selectControlVarieties } from "../../../../selectors/resultMap";
import { useMemo } from "react";

/* Item of list
 *  Displays one element name and associated controls
 */
const FocusListItem = (props) => {
  const {
    name,
    onShow,
    isControl,
    onPin,
    onRemove,
    visualIndex,
    color,
    pinned,
  } = props;

  // Classes for pin btn switch on/off
  const pinIconClasses = classNames("smol-btn fa fa-lg", {
    "fa-star golden": pinned,
    "fa-star-o": !pinned,
  });
  // Classes for gen
  const genNameClasses = classNames("focus-list-item-name", {
    visualized: visualIndex !== -1,
  });
  return (
    <ListGroupItem className="focus-list-item">
      {/* If visualIndex !== -1 means the item is associated to a visualizer,
       * in this case shows the associated index in a colored badge
       * else shows a round indicator of the right color
       * color is based on index in list, corresponds to the color on line chart (theme.extendedPalette)
       */}
      {/* Button pin */}
      <div className="focus-list-item-controls">
        <i
          className={pinIconClasses}
          aria-hidden="true"
          onClick={(e) => onPin(name)}
          data-tooltip-id="tooltip"
          data-tooltip-content={
            pinned ? "Remove from favorites" : "Add to favorites"
          }
          data-tooltip-place="top"
        />
      </div>

      <Button
        className={genNameClasses}
        title={name}
        style={{
          background: color,
          border: "none",
          paddingTop: "2px",
          paddingBottom: "2px",
          color: "black",
          boxShadow: `0 10px 20px -8px ${color}`,
        }}
        onClick={(e) => onShow(name)}
      >
        {name}
      </Button>
      {visualIndex !== -1 && (
        <Badge
          className="index-badge animated"
          // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
          color="invalid background color"
          style={{
            backgroundColor: color,
            boxShadow: `1px 1px 8px -1px ${color}`,
          }}
        >
          {visualIndex + 1}
        </Badge>
      )}
      {isControl && (
        <Badge
          data-tooltip-id="tooltip"
          data-tooltip-content="Control Variety"
          data-tooltip-place="top"
          data-tooltip-variant="dark"
        >
          CV
        </Badge>
      )}

      <i
        className="fa fa-lg fa-close smol-btn remove-button"
        aria-hidden="true"
        data-tooltip-id="tooltip"
        data-tooltip-content="Remove"
        data-tooltip-place="right"
        data-tooltip-variant="dark"
        onClick={() => onRemove(name)}
      />
    </ListGroupItem>
  );
};

/* FocusList displays the focused elements selected
 * It is a list group with additionnal controls to
 * interact with other components from the template
 * focusedElements are string names: generic (can be: genotypes / names / treaments ..)
 */
export const FocusList = (props) => {
  const {
    groups,
    features,
    focusedElements,
    setFocusedElements,
    visualizedElements,
    setVisualizedElements,
    getColor,
    expandFocusView,
    setExpandFocusView,
    pinnedElements,
    setPinnedElements,
    propertyFilter,
    setPropertyFilter,
    selectedFeature,
    openPlotRatingModal,
    userCanRatePlots,
  } = props;
  const controlVarieties = useSelector(selectControlVarieties);

  const dispatch = useDispatch();

  // For show button: toggle visualisation of element, handles visualised list
  // Size limited to DAHLIA_MAX_VISUALIZED_ELEMENTS
  const handeSwitchVisualisation = (gen) => {
    if (visualizedElements.includes(gen))
      setVisualizedElements(removeElementFromArray(visualizedElements, gen));
    else if (visualizedElements.length < AD_MAX_VISUALIZED_ELEMENTS)
      setVisualizedElements([...visualizedElements, gen]);
  };

  // Pinned elements to be persistent on clear
  const handeSwitchPinning = (gen) => {
    if (pinnedElements.includes(gen))
      setPinnedElements(removeElementFromArray(pinnedElements, gen));
    else setPinnedElements([...pinnedElements, gen]);
  };

  // Removes all visualized elements
  const clearVisualisation = () => {
    setVisualizedElements([]);
  };

  // Removes every element except pinned ones
  const clearList = () => {
    clearVisualisation();
    setFocusedElements(pinnedElements);
  };

  // Removes one element from the list
  const removeElement = (gen) => {
    setFocusedElements(removeElementFromArray(focusedElements, gen));
    setVisualizedElements(removeElementFromArray(visualizedElements, gen));
    setPinnedElements(removeElementFromArray(pinnedElements, gen));
  };

  const content = useMemo(
    () => ({
      varieties: {
        options: groups
          .filter((grp) => !focusedElements.includes(grp))
          .map((grp) => ({
            label: grp,
            value: grp,
            renderBullet: <i className="fa fa-pagelines" />,
          })),
      },
      plots: {
        options: features
          .filter((feature) => !focusedElements.includes(feature.group))
          .map((feature) => ({
            label: feature.displayId,
            value: feature.group,
            renderBullet: <i className="fa fa-square" />,
          })),
      },
    }),
    [features, focusedElements, groups]
  );

  return (
    <div className="powerdash-component focus-list">
      <div className="focus-list-controls">
        <ComplexSearch
          deferTime={500}
          content={content}
          placeholder="Search among varieties and plots..."
          handleClickDefault={(value) => {
            dispatch(addFocusedVarieties([value]));
          }}
          disabled={!groups.length}
          inverted={!expandFocusView}
        />

        <Badge pill className="focused-elements-badge">
          {focusedElements.length}
        </Badge>
        <ExpandButton
          expanded={expandFocusView}
          setExpanded={setExpandFocusView}
        />
      </div>
      <div className="focus-list-group">
        <ListGroup>
          {focusedElements.length > 0 &&
            focusedElements.map((gen) => (
              <FocusListItem
                key={gen}
                name={gen}
                isControl={controlVarieties.includes(gen)}
                onShow={handeSwitchVisualisation}
                onPin={handeSwitchPinning}
                onRemove={removeElement}
                visualIndex={visualizedElements.indexOf(gen)}
                color={getColor(gen)}
                pinned={pinnedElements.includes(gen)}
              />
            ))}
        </ListGroup>
      </div>
      {selectedFeature && !focusedElements.includes(selectedFeature.group) ? (
        <Badge
          className="focus-list-item-name pending-selection"
          data-tooltip-id="tooltip"
          data-tooltip-content={`Click to add variety of the selected feature on plot map (${selectedFeature.id})`}
          data-tooltip-place="top"
          data-text-color="white"
          onClick={() => dispatch(addFocusedVarieties([selectedFeature.group]))}
        >
          <i className="fa fa-plus"></i> {selectedFeature.group}
        </Badge>
      ) : (
        <></>
      )}
      <div className="controls">
        <Button
          outline
          className="control-btn icon"
          onClick={clearList}
          disabled={focusedElements.length < 1}
          data-tooltip-id="tooltip"
          data-tooltip-content="Remove all genotypes except favorites"
          data-tooltip-place="top"
          data-tooltip-variant="dark"
        >
          <span className="buttontext">
            <i className="fa fa-trash" />
          </span>
        </Button>
        <Button
          outline
          className="control-btn icon"
          onClick={clearVisualisation}
          disabled={visualizedElements.length < 1}
          data-tooltip-id="tooltip"
          data-tooltip-content="Clear thumbnail views"
          data-tooltip-place="top"
          data-tooltip-variant="dark"
        >
          <span className="buttontext">
            <i className="fa fa-eye-slash"></i>
          </span>
        </Button>
        {userCanRatePlots && (
          <Button
            className="control-btn"
            style={{ backgroundColor: HIPHEN_GREEN, border: "none" }}
            disabled={focusedElements.length < 1}
            onClick={openPlotRatingModal}
          >
            <span className="buttontext">
              <i className="fa fa-pencil" /> Rate plots
            </span>
          </Button>
        )}
        <Input
          className="search-property"
          bsSize="sm"
          placeholder="Search property"
          value={propertyFilter}
          disabled={visualizedElements.length === 0}
          onChange={(event) => setPropertyFilter(event.target.value)}
        />
      </div>
    </div>
  );
};

FocusListItem.propTypes = {
  name: PropTypes.string.isRequired,
  onShow: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isControl: PropTypes.bool.isRequired,
  onPin: PropTypes.func.isRequired,
  visualIndex: PropTypes.number.isRequired,
  pinned: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

FocusList.propTypes = {
  groups: PropTypes.array.isRequired,
  features: PropTypes.array.isRequired,
  focusedElements: PropTypes.array.isRequired,
  setFocusedElements: PropTypes.func.isRequired,
  visualizedElements: PropTypes.array.isRequired,
  setVisualizedElements: PropTypes.func.isRequired,
  getColor: PropTypes.func.isRequired,
  expandFocusView: PropTypes.bool.isRequired,
  setExpandFocusView: PropTypes.func.isRequired,
  pinnedElements: PropTypes.array.isRequired,
  setPinnedElements: PropTypes.func.isRequired,
  propertyFilter: PropTypes.string.isRequired,
  setPropertyFilter: PropTypes.func.isRequired,
  selectedFeature: PropTypes.object,
  openPlotRatingModal: PropTypes.func.isRequired,
  userCanRatePlots: PropTypes.bool.isRequired,
};
