/* eslint-disable max-lines-per-function */
/* eslint-disable react/prefer-stateless-function */
import "./legendColorScale.css";

import { Col, Row } from "reactstrap";
import React, { Component } from "react";

const ColorTable = [
  "rgb(66, 33, 18)",
  "rgb(127, 64, 32)",
  "rgb(183, 97, 53)",
  "rgb(198, 151, 78)",
  "rgb(230, 201, 87)",
  "rgb(253, 254, 3)",
  "rgb(208, 223, 0)",
  "rgb(185, 207, 2)",
  "rgb(162, 192, 0)",
  "rgb(138, 175, 0)",
  "rgb(114, 160, 0)",
  "rgb(91, 142, 3)",
  "rgb(69, 129, 0)",
  "rgb(45, 112, 0)",
  "rgb(37, 96, 45)",
  "rgb(21, 84, 45)",
  "rgb(21, 68, 45)",
];

class CustomLegend extends Component {
  render() {
    return (
      <div className="customControlBottomLeft mt-2">
        <Row>
          <Col xs="12">
            <Row>
              <Col xs="12">
                {ColorTable.map((color) => (
                  <span
                    key={color}
                    className="grad-step-custom m-0 p-0 w-10"
                    style={{
                      backgroundColor: color,
                    }}
                  />
                ))}
              </Col>
              <Col xs="12">
                <span className="label-legend float-start">Low vegetation</span>
                <span className="label-legend float-end text-end">
                  High vegetation
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CustomLegend;
