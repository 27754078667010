import "./acquisitionReport.scss";

import { Badge, Card, CardBody, Collapse, Modal, ModalBody } from "reactstrap";

import { ACQUISITION_REPORT_STATUS_COLORS } from "../../../constants";
import PropTypes from "prop-types";
import { RichTextEditor } from "../../../../components/richTextEditor/RichTextEditor";
import { useState } from "react";

export const AcquisitionReport = ({ expanded, hccMission }) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = () => setIsModalOpen(!isModalOpen);

  const handleCollapse = () => {
    setIsCollapseOpen(!isCollapseOpen);
  };

  return (
    <Card className="acquisition-report">
      <div
        onClick={expanded ? handleModal : handleCollapse}
        className={`d-flex align-items-center justify-content-between w-100 ps-2 pe-2 ${
          hccMission.reportComment ? "clickable" : ""
        }`}
      >
        <div className="d-flex align-items-center">
          <span>
            {hccMission.reportDate
              ? `${hccMission.reportDate} | Imagery quality control`
              : "Imagery quality control"}
          </span>
        </div>

        <div className="d-flex align-items-center gap-2">
          {hccMission.reportStatus && (
            <Badge
              // if color is not valid "primary secondary...", the background color is overriden by the background color of the style
              className="active small"
              color="invalid background color"
              style={{
                backgroundColor:
                  ACQUISITION_REPORT_STATUS_COLORS[hccMission.reportStatus],
              }}
            >
              {hccMission.reportStatus}
            </Badge>
          )}

          {hccMission.reportComment &&
            (expanded ? (
              <i
                className="fa fa-comment-o"
                aria-hidden="true"
                onClick={handleModal}
              />
            ) : !isCollapseOpen ? (
              <i className="fa fa-chevron-down" aria-hidden="true" />
            ) : (
              <i className="fa fa-chevron-up" aria-hidden="true" />
            ))}
        </div>
      </div>
      {hccMission.reportComment &&
        (expanded ? (
          <Modal size="xl" isOpen={isModalOpen} toggle={handleModal}>
            <ModalBody>
              <RichTextEditor defaultEditorState={hccMission.reportComment} />
            </ModalBody>
          </Modal>
        ) : (
          <Collapse isOpen={isCollapseOpen}>
            <CardBody className="card-body">
              <RichTextEditor defaultEditorState={hccMission.reportComment} />
            </CardBody>
          </Collapse>
        ))}
    </Card>
  );
};
AcquisitionReport.propTypes = {
  expanded: PropTypes.bool.isRequired,
  hccMission: PropTypes.object.isRequired,
};
