import "./labelledSeparator.css";

import PropTypes from "prop-types";

export const LabelledSeparator = ({ children, visible = true }) => {
  return (
    visible && (
      <div className="labelled-separator d-flex pt-2 pb-2">
        <div className="labelled-separator-line" />
        <div>{children}</div>
        <div className="labelled-separator-line" />
      </div>
    )
  );
};

LabelledSeparator.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
};
