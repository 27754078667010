import { Badge } from "reactstrap";
import gif1 from "./res/af-gif1.gif";
import gif2 from "./res/af-gif2.gif";

export const ADVANCED_FILTERING_STEPS = [
  {
    content: (
      <>
        Introducing : <Badge className="new-feature-badge">NEW</Badge> Advanced
        filtering
        <br />
        Click next for a guided tour
      </>
    ),

    target: "#advanced-filtering-button",
    placement: "right",
    disableBeacon: true,
  },
  {
    content: (
      <>
        Define a scope before filtering:
        <br />
        Select experiments, modalities and layers that you want to study and/or
        compare.
        <img src={gif1} width={300} alt="" />
      </>
    ),
    target: "#advanced-filtering-scope-selector",
    placement: "left",
  },
  {
    content: (
      <>
        Apply multiple criteria filtering on your genetic material and
        dynamically eliminate under-performing genotypes based on your ideotype
        recipe.
        <br />
        Click "Apply filters"
        <img src={gif2} width={300} alt="" />
      </>
    ),
    target: ".trait-list-advanced-group",
    placement: "left",
  },
  {
    content: "Save and load filtering profiles for later use",
    target: ".filtering-profile-manager-header",
    placement: "left",
  },
];
