import ColorHash from "color-hash";
import { bimap } from "./services/utils";

export const THEME = Object.freeze({
  background: "none",
  indicators: "darkgray",
  baseColors: [
    "#8FBF48",
    "#0c2800",
    "#6f9e2a",
    "#134400",
    "#4f7f00",
    "#2f6100",
  ],
  extendedPalette: [
    "#845EC2",
    "#D65DB1",
    "#FF6F91",
    "#FF9671",
    "#FFC75F",
    "#F9F871",
    "#9BDE7E",
    "#4BBC8E",
    "#039590",
  ],
  modalityColorHash: new ColorHash({
    hue: { min: 0, max: 360 },
    saturation: 0.8,
    lightness: 0.45,
  }),
});

export const APPLICATION_ENABLED = "APPLICATION_ENABLED";
export const APPLICATION_FREEZED = "APPLICATION_FREEZED";

export const METADATA_TYPE = "METADATA_TYPE";
export const DATASET_TYPE = "DATASET_TYPE";
export const CONFIG_TYPE = "CONFIG_TYPE";
export const CAMERA_PROFILE_TYPE = "CAMERA_PROFILE_TYPE";
export const SVM_TRAININGSET_TYPE = "SVM_TRAININGSET_TYPE";
export const VECTOR_CONFIG_TYPE = "VECTOR_CONFIG_TYPE";
export const CAMERA_SETUP_TYPE = "CAMERA_SETUP_TYPE";
export const GRP_PARAMETERS_TYPE = "GRP_PARAMETERS_TYPE";
export const NNT_PARAMETERS_TYPE = "NNT_PARAMETERS_TYPE";
export const LUT_PARAMETERS_TYPE = "LUT_PARAMETERS_TYPE";

export const PHENOSCRIPT_LABEL_MARKERS_TYPE = "PHENOSCRIPT_LABEL_MARKERS_TYPE";
export const PHENOSCRIPT_PLOTS_TYPE = "PHENOSCRIPT_PLOTS_TYPE";
export const PHENOSCRIPT_REFLECTANCE_TYPE = "PHENOSCRIPT_REFLECTANCE_TYPE";
export const PHENOSCRIPT_TRAINING_TYPE = "PHENOSCRIPT_TRAINING_TYPE";
export const PHENOSCRIPT_VIGNETTES_TYPE = "PHENOSCRIPT_VIGNETTES_TYPE";
export const PHOTOSCAN_PROJECT_TYPE = "PHOTOSCAN_PROJECT_TYPE";

export const ADDITIONAL_DATA_TYPE = "ADDITIONAL_DATA_TYPE";

export const UPLOAD_EMPTY = "UPLOAD_EMPTY";
export const UPLOAD_READY = "UPLOAD_READY";
export const UPLOAD_IN_PROGRESS = "UPLOAD_IN_PROGRESS";
export const UPLOAD_DONE = "UPLOAD_DONE";
export const UPLOAD_MISSING_DATA = "UPLOAD_MISSING_DATA";

export const USER_ROLE_ADMIN = "Administrator";
export const USER_ROLE_BACKOFFICE = "Back office";
export const USER_ROLE_REGULAR = "Regular user";

export const OPTIONS_SYSTEM = [
  { value: "AV", label: "Aircraft System" },
  { value: "satellite", label: "Satellite" },
  { value: "phenomobile", label: "Phenomobile" },
  { value: "literal", label: "Literal" },
];

export const default_trait = {
  name: "",
  definition: "",
  unit: "",
  crops: "",
  sensor: "",
  ref_paper: "",
  style: {
    opacity: true,
    color: ["#ff0000", "#00ff20"],
    step: 5,
    intervalDefault: false,
    intervalMin: 0,
    intervalMax: 100,
    display_on_map: true,
    display_on_analysis: true,
  },
  technical_name: "",
  image: null,
};

export const SESSION_UPLOAD_TOOLTIP_SUCCESS =
  "Your data is successfully uploaded.";
export const SESSION_UPLOAD_TOOLTIP_PARTIAL =
  "You defined this session as partial. Thank you to provide missing files then mark it as complete.";
export const SESSION_UPLOAD_TOOLTIP_INCOMPLETE =
  "Your session seems to be incomplete. Please review your uploaded data.";
export const SESSION_UPLOAD_STATUS_CLIENT = [
  {
    value: "complete",
    label:
      "All files and datasets are available in this upload session (data processing can start).",
  },
  {
    value: "partial",
    label:
      "Missing files will be shared at a later stage (data processing on hold until then).",
  },
];

const GEO_STD = [".7z", ".rar", ".zip", ".geojson", ".kml", ".kmz", ".gpkg"];
export const GEO_EXT = { plot_file: GEO_STD, gcp_file: GEO_STD };
export const GEO_EXT_TOOLTIP =
  "Accepted formats are shapefile (zipped), geojson, KML and geopackage";

export const EXPERIMENT_STATUS = {
  NOT_STARTED: 0,
  DRAFT: 1,
  COMPLETED: 2,
  SYNCHRONIZED: 3,
  properties: {
    0: { color: "bg-secondary", label: "Not started" },
    1: { color: "bg-info", label: "Draft" },
    2: { color: "bg-success", label: "Completed" },
    3: { color: "bg-primary", label: "Synchronized" },
  },
};

export const SESSION_STATUS = bimap({
  Initialized: 0,
  InProgress: 1,
  Completed: 2,
  Synchronized: 3,
  Partial: 4,
});

export const FILE_TYPE_LABEL = {
  plot_file: "Plot File",
  gcp_file: "GCP File",
  metadata_file: "Metadata File",
  sensor: "Sensor Model",
  flight_altitude: "Flight altitude (in m)",
  gap: "Gap (in cm)",
  archive_filename: "Archive file name",
};
export const CLIENT_REQUEST_TYPES = {
  plot_ratings_config: "plot_ratings_config",
};

export const DEFAULT_ERROR_MESSAGE =
  "An error occurred, please try again in a few minutes or get in touch with your cloverfield contact.";

export const DOWNLOAD_WILL_BEGIN =
  "We are getting your data. Your download will begin in few seconds.";

export const DOWNLOAD_WITHOUT_RESULT = "No result available for this date.";

export const ROLES = [
  {
    label: "Display results",
    value: "view_features",
  },
  {
    label: "Display plot map",
    value: "view_experiments",
  },
  {
    label: "View monitoring",
    value: "view_monitoring",
  },
  {
    label: "View analytics",
    value: "view_analytics",
  },
  {
    label: "Create site",
    value: "create_site",
  },
  {
    label: "Rate plots",
    value: "rate_plots",
  },
  {
    label: "Edit plot rating configuration",
    value: "edit_plot_rating_config",
  },
];

export const BASIC_ROLES = [
  "view_features",
  "view_experiments",
  "view_monitoring",
  "view_analytics",
  "rate_plots",
];

export const HIPHEN_GREEN = "#8FBF48";
export const PLOT_RATING_COLOR = "#e65e69";
export const COMPANY_HIPHEN = "Hiphen";

// Visualisation
export const AD_MAX_VISUALIZED_ELEMENTS = 3;
export const AD_DATE_FORMAT = "DD-MM-YY";
export const AD_COL_CLASSES = "col d-flex flex-column";

export const DEFAULT_BLACKLISTED_TRAITS = Object.freeze([
  "latitude",
  "longitude",
  "plot_id",
  "client_id",
]);

export const GENOTYPE_PROPERTY = "genotype";
export const MODALITY_PROPERTY = "modality";
export const EXPERIMENT_PROPERTY = "experiment";
export const LAYER_PROPERTY = "layer";
export const CLIENT_ID_PROPERTY = "client_id";

export const LABEL_ALL = "All";
export const LABEL_OVERVIEW = "Overview";

export const PLOT_VIEW_MODES = Object.freeze({
  PROPERTIES: "properties",
  IMAGES: "images",
});

export const BBCH_STAGES = Object.freeze({
  0: "Germination, sprouting, bud development",
  1: "Leaf development",
  2: "Formation of side shoots, tillering",
  3: "Stem elongation or rosette growth, shoot development",
  4: "Development of harvestable vegetative plant parts, bolting",
  5: "Inflorescence emergence, heading",
  6: "Flowering",
  7: "Development of fruit",
  8: "Ripening or maturity of fruit and seed",
  9: "Senescence, beginning of dormancy",
  10: "Post-harvest",
});

export const ANALYTICS_EVENTS = {
  AUC_MODE: {
    eventName: "analytics/AucMode",
    eventLabel: "switch AUC mode",
    eventCategory: "switch button",
  },
  MAP_DOWNLOAD_BUTTON: {
    eventName: "maps/DownloadButton",
    eventLabel: "dwonload",
    eventCategory: "button",
  },
  ANALYTICS_DOWNLOAD_BUTTON: {
    eventName: "analytics/DownloadButton",
    eventLabel: "download",
    eventCategory: "button",
  },
  ANALYTICS_AF_BUTTON: {
    eventName: "analytics/AFButton",
    eventLabel: "open AF drawer",
    eventCategory: "button",
  },
  MAP_AF_BUTTON: {
    eventName: "maps/AFButton",
    eventLabel: "open AF drawer",
    eventCategory: "button",
  },
  RATE_PLOTS: {
    eventName: "analytics/RatePlots",
    eventLabel: "open rate plots modal",
    eventCategory: "button",
  },
  AF_APPLY_FILTER: {
    eventName: "analytics/AFApplyFilter",
    eventLabel: "apply filter",
    eventCategory: "button",
  },
  AF_SAVE_SCOPE: {
    eventName: "analytics/AFSaveScope",
    eventLabel: "save scope",
    eventCategory: "button",
  },
  UPLOAD_SAVE: {
    eventName: "upload/upload&save",
    eventLabel: "save and upload mission",
    eventCategory: "button",
  },
  RANDOM_SELECT: {
    eventName: "analytics/RandomSelect",
    eventLabel: "random select",
    eventCategory: "button",
  },
  UPLOAD_YOUR_OWN_DATA: {
    eventName: "analytics/UploadYourOwnData",
    eventLabel: "upload your own data",
    eventCategory: "button",
  },
  OPEN_UPLOAD_YOUR_OWN_DATA: {
    eventName: "analytics/OpenUploadYourOwnData",
    eventLabel: "open upload your own data",
    eventCategory: "button",
  },
  ADD_FILE_UPLOAD_YOUR_OWN_DATA: {
    eventName: "analytics/AddFileUploadYourOwnData",
    eventLabel: "add file upload your own data",
    eventCategory: "drop",
  },
  UPLOAD_YOUR_OWN_DATA_DOWNLOAD_CSV_TEMPLATE: {
    eventName: "analytics/UploadYourOwnDataDownloadCSVTemplate",
    eventLabel: "download csv template",
    eventCategory: "button",
  },
  SELECT_FROM_GENOTYPE_ANALYZER: {
    eventName: "analytics/SelectFromGenotypeAnalyzer",
    eventLabel: "select from genotype analyzer",
    eventCategory: "select",
  },
  SELECT_FROM_SCATTER_TOOL: {
    eventName: "analytics/SelectFromScatterTool",
    eventLabel: "select from scatter tool",
    eventCategory: "select",
  },
  CREATE_SITE: {
    eventName: "clientdashboard/CreateSite",
    eventLabel: "create site",
    eventCategory: "button",
  },
};
