import "leaflet-linear-measurement/src/Leaflet.LinearMeasurement.js";
import "leaflet-linear-measurement/sass/Leaflet.LinearMeasurement.scss";

import L from "leaflet";
import { MapControl } from "react-leaflet";

export default class LinearRuler extends MapControl {
  createLeafletElement(props) {
    return new L.Control.LinearMeasurement(props);
  }

  componentDidMount() {
    const { map } = this.props;
    this.leafletElement.addTo(map);
  }

  componentWillUnmount() {
    const { map } = this.props;
    //remove manually the class to fix cursor behaviour
    L.DomUtil.removeClass(map.getContainer(), "ruler-map");
    this.leafletElement.remove();
  }

  resetRuler() {
    const { map } = this.props;
    this.leafletElement.resetRuler(true);
    L.DomUtil.removeClass(map.getContainer(), "ruler-map");
    const icon = document.getElementsByClassName("icon-ruler")[0];
    L.DomUtil.removeClass(icon, "icon-active");
  }

  isActive() {
    const icon = document.getElementsByClassName("icon-ruler")[0];
    return icon?.classList.contains("icon-active");
  }
}
