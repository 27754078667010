/* eslint-disable max-lines-per-function */
import {
  ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST,
  ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST,
  CREATE_FILTERING_PROFILE,
  DELETE_FILTERING_PROFILE,
  DELETE_FILTERING_PROFILE_FILTER,
  FULL_RESET_RESULT_MAP,
  REFRESHING_FEATURES_BY_DATE,
  REFRESHING_RESULT_MAP,
  REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST,
  REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST,
  REMOVE_TRAIT_FROM_AUC_DATA,
  RESET_FILTERING_PROFILE,
  RESET_RESULT_MAP,
  SET_AUC_DATA,
  SET_COLOR_OPACITY,
  SET_COLOR_SCALE_STEP,
  SET_EXPERIMENTS_FILTER,
  SET_FAVORITE_VARIETIES,
  SET_FEATURES_BY_DATE,
  SET_FILTERING_PROFILE,
  SET_FILTERING_PROFILES,
  SET_FILTERING_PROFILE_FILTER,
  SET_FILTERING_PROFILE_SCOPE,
  SET_FOCUSED_VARIETIES,
  SET_LAYER_FILTER,
  SET_MODALITIES_FILTER,
  SET_NEW_FEATURE_PROPERTIES,
  SET_RESULT_MAP_FEATURES,
  SET_SELECTED_CONTRACT,
  SET_SELECTED_PLOT_ID,
  SET_SELECTED_TRAIT,
  SET_SITE_EXPERIMENTS,
  SET_SITE_MODALITIES,
  SET_VISUALIZED_VARIETIES,
} from "../actions/actionTypes";
import {
  CLIENT_ID_PROPERTY,
  EXPERIMENT_PROPERTY,
  GENOTYPE_PROPERTY,
  LAYER_PROPERTY,
  MODALITY_PROPERTY,
} from "../constants";

import { traitGroupCompare } from "../services/utils";

const initialState = {
  trial: null,
  experiments: [],
  modalities: [],
  trial_date: null,
  traitsListForMap: [],
  otherProperties: [],
  features: [],
  distinctLayers: [],
  distinctExperiments: [],
  distinctModalities: [],
  featuresByDate: [],
  refreshingFeaturesByDate: false,
  refreshing: false,
  selectedTrait: {},
  colorScaleStep: null,
  opacity: 0.7,
  filters: {
    experiments: [],
    modalities: [],
    layer: null,
  },
  focusedVarieties: [],
  favoriteVarieties: [],
  visualizedVarieties: [],
  selectedPlotId: null,
  selectedContract: null,
  filteringProfiles: [],
  filteringProfile: {
    scope: null,
    filters: [],
    blacklist: [],
    whitelist: [],
  },
  aucData: {
    traits: [],
    featureData: {},
  },
  uploadedData: null,
};

const getTraitsAsObject = (traits) => {
  return traits.reduce(
    (traitsObject, trait) => ({
      ...traitsObject,
      [trait.uuid]: trait,
    }),
    {}
  );
};

const extractMandatoryProperties = (data, id) => {
  const displayId = data[CLIENT_ID_PROPERTY] ?? id;
  const genotype = data[GENOTYPE_PROPERTY] ?? displayId;
  const modality = data[MODALITY_PROPERTY] ?? "";
  const experiment = data[EXPERIMENT_PROPERTY] ?? "";
  const layer = data[LAYER_PROPERTY];

  return {
    displayId,
    genotype,
    modality,
    group: `${genotype} ${modality}`.trim(),
    experiment,
    layer,
  };
};

/*
 * Convert fetched feature to cloverfield compatible format and
 * fills trait list for map set
 */
const formatFeature = (
  { feature, traitsObject, plotRating = {}, uploadedData },
  traitsListForMap
) => {
  const { traits, data } = feature.properties;
  const formattedTraitEntries = traits.reduce((currentEntries, traitResult) => {
    const { id, value } = traitResult;
    const trait = { ...traitsObject[id] };
    if (traitResult.class) {
      trait.technical_name = `${trait.technical_name}$${traitResult.class}`;
      trait.name = `${trait.name}|${traitResult.class}`;
    }
    if (
      traitsListForMap &&
      !traitsListForMap.find((t) => t.technical_name === trait.technical_name)
    )
      traitsListForMap.push(trait);
    return {
      ...currentEntries,
      [trait.technical_name]: value,
    };
  }, {});

  const mandatoryProperties = extractMandatoryProperties(data, feature.id);
  let formattedFeature = {
    ...feature,
    trait_list: Object.keys(formattedTraitEntries),
    ...mandatoryProperties,

    properties: {
      ...data,
      ...formattedTraitEntries,
      ...plotRating,
    },
  };
  if (uploadedData) {
    const newProperties = uploadedData.find(
      (row) => row.cloverfieldId === feature.id
    );
    formattedFeature = formatFeatureOnUpload(formattedFeature, newProperties);
  }

  return formattedFeature;
};

const formatFeatureOnUpload = (feature, newProperties) => {
  if (!newProperties) return feature;
  const { id, cloverfieldId, ...propertiesToMerge } = newProperties;
  const updatedFeatureProperties = {
    ...feature.properties,
    ...propertiesToMerge,
  };

  const mandatoryProperties = extractMandatoryProperties(
    updatedFeatureProperties,
    cloverfieldId
  );

  return {
    ...feature,
    ...mandatoryProperties,
    properties: updatedFeatureProperties,
  };
};

const formatFilteringProfile = (filteringProfile) => {
  return {
    ...filteringProfile,
    scope: {
      ...filteringProfile.scope,
      experiments: filteringProfile.scope.experiments.length
        ? filteringProfile.scope.experiments
        : [null],
      modalities: filteringProfile.scope.modalities.length
        ? filteringProfile.scope.modalities
        : [null],
    },
    filters: filteringProfile.filters.map((filter) => ({
      ...filter,
      excludedGroups: filter.excluded_groups,
    })),
  };
};

export default function resultMap(state = initialState, action) {
  switch (action.type) {
    case SET_RESULT_MAP_FEATURES: {
      const features = action.features;
      const traitsObject = getTraitsAsObject(action.traits);

      // TODO compute distinctExperiments from the filtered features based on layer
      let distinctExperiments = new Set();
      let distinctLayers = new Set();
      let distinctModalities = new Set();
      let traitsListForMap = [];

      // Plot ratings indexed by plot id
      const plotRatings = action.plot_ratings.reduce(
        (ratings, currentRating) => ({
          ...ratings,
          [currentRating.plot_id]: currentRating.rating,
        }),
        {}
      );

      // Iterate through features to rework the object and keep track of unique data
      const updatedFeatures = features.map((feature) => {
        /* Construct display plot id for each features
         * Add plot ratings to properties
         */
        const formattedFeature = formatFeature(
          {
            feature,
            traitsObject,
            plotRating: plotRatings[feature.id],
            uploadedData: state.uploadedData,
          },
          traitsListForMap
        );

        const { experiment, layer, modality } = formattedFeature.properties;
        distinctExperiments.add(experiment);
        distinctLayers.add(layer);
        distinctModalities.add(modality);

        return formattedFeature;
      });

      distinctExperiments = Array.from(distinctExperiments).sort();
      // Remove falsy layer values
      distinctLayers = Array.from(distinctLayers).sort().filter(Boolean);
      distinctModalities = Array.from(distinctModalities).sort();
      traitsListForMap = traitsListForMap.sort((a, b) =>
        traitGroupCompare(a.traitGroup, b.traitGroup)
      );

      // Identify default selected trait into map
      // Objective is to keep the same selected trait after site or date selection
      let selectedTrait = state.selectedTrait;
      if (
        !traitsListForMap.some(
          (e) => e.technical_name === selectedTrait.technical_name
        )
      )
        selectedTrait = traitsListForMap[0] ?? initialState.selectedTrait;

      // On site update, reinitialize data for analysis charts and selected plot
      let featuresByDate = initialState.featuresByDate;
      let selectedPlotId = initialState.selectedPlotId;

      if (state.trial === action.trial) {
        featuresByDate = state.featuresByDate;
        selectedPlotId = state.selectedPlotId;
      }

      return {
        ...state,
        selectedContract: action.contract,
        filters: {
          ...initialState.filters,
          experiments: distinctExperiments,
          modalities: distinctModalities,
          layer: distinctLayers[0] ?? initialState.filters.layer,
        },
        filteringProfiles: action.filteringProfiles,
        // Reset filtering profile only if site has changed
        filteringProfile:
          state.filteringProfile.site_id === action.trial.id
            ? state.filteringProfile
            : initialState.filteringProfile,
        trial: action.trial,
        distinctExperiments,
        distinctModalities,
        distinctLayers,
        experiments: action.experiments
          ? action.experiments
          : state.experiments,
        modalities: action.modalities ? action.modalities : state.modalities,
        features: updatedFeatures,
        featuresByDate,
        traitsListForMap,
        trial_date: action.trialDate,
        refreshing: false,
        selectedTrait,
        selectedPlotId,
      };
    }

    case SET_NEW_FEATURE_PROPERTIES:
      let distinctExperiments = new Set();
      let distinctLayers = new Set();
      let distinctModalities = new Set();

      const updatedFeatures = state.features.map((feature) => {
        const newProperties = action.newProperties.find(
          (item) => item.cloverfieldId === feature.id
        );

        const updatedFeature = formatFeatureOnUpload(feature, newProperties);
        const { experiment, layer, modality } = updatedFeature.properties;

        distinctExperiments.add(experiment);
        distinctLayers.add(layer);
        distinctModalities.add(modality);

        return updatedFeature;
      });

      distinctExperiments = Array.from(distinctExperiments).sort();
      distinctLayers = Array.from(distinctLayers).sort().filter(Boolean);
      distinctModalities = Array.from(distinctModalities).sort();

      const updatedFeaturesByDate = state.featuresByDate.map((feature) => {
        const newProperties = action.newProperties.find(
          (item) => item.cloverfieldId === feature.id
        );

        const updatedFeature = formatFeatureOnUpload(feature, newProperties);

        return updatedFeature;
      });

      return {
        ...state,
        featuresByDate: updatedFeaturesByDate,
        uploadedData: action.newProperties,
        features: updatedFeatures,
        filters: {
          ...initialState.filters,
          experiments: distinctExperiments,
          modalities: distinctModalities,
          layer: distinctLayers[0] ?? initialState.filters.layer,
        },
        distinctExperiments,
        distinctModalities,
        distinctLayers,
      };

    case SET_FEATURES_BY_DATE: {
      const featuresByDate = action.featuresByDate.map((feature) => {
        return formatFeature({
          feature,
          traitsObject: getTraitsAsObject(action.traits),
          uploadedData: state.uploadedData,
        });
      });

      return {
        ...state,
        featuresByDate: featuresByDate,
        refreshingFeaturesByDate: false,
      };
    }

    case SET_SITE_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };

    case SET_SITE_MODALITIES:
      return {
        ...state,
        modalities: action.modalities,
      };

    case SET_SELECTED_TRAIT:
      return {
        ...state,
        selectedTrait: action.trait,
        refreshing: false,
      };

    case SET_EXPERIMENTS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          experiments: action.experiments,
        },
      };

    case SET_MODALITIES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          modalities: action.modalities,
        },
      };

    case SET_LAYER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          layer: action.layer,
        },
      };

    case CREATE_FILTERING_PROFILE:
      return {
        ...state,
        filteringProfile: formatFilteringProfile(action.filteringProfile),
        filteringProfiles: [
          ...state.filteringProfiles,
          {
            id: action.filteringProfile.id,
            name: action.filteringProfile.name,
            created_at: action.filteringProfile.created_at,
          },
        ],
      };

    case DELETE_FILTERING_PROFILE:
      return {
        ...state,
        filteringProfile: initialState.filteringProfile,
        filteringProfiles: state.filteringProfiles.filter(
          ({ id }) => id !== action.filteringProfile.id
        ),
      };

    case SET_FILTERING_PROFILE_SCOPE:
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          scope: action.scope,
        },
      };

    case SET_FILTERING_PROFILE_FILTER:
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          // Replacement of found filter in filters array
          filters: [
            ...state.filteringProfile.filters.filter(
              ({ date }) => date !== action.filter.date
            ),
            action.filter,
          ].filter(({ traits }) => traits.length),
        },
      };

    case DELETE_FILTERING_PROFILE_FILTER:
      const filter = state.filteringProfile.filters.find(
        ({ date }) => date === action.date
      );
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          filters: [
            ...state.filteringProfile.filters.filter(
              ({ date }) => date !== action.date
            ),
            {
              ...filter,
              // If action.technical_name is undefined, then every traits are removed
              // meaning that the date will be removed.
              traits: filter.traits.filter(
                ({ technical_name }) =>
                  action.technical_name &&
                  technical_name !== action.technical_name
              ),
            },
          ].filter(({ traits }) => traits.length),
        },
      };
    case SET_FILTERING_PROFILES: {
      return {
        ...state,
        filteringProfiles: action.filteringProfiles,
      };
    }
    case SET_FILTERING_PROFILE:
      return {
        ...state,
        filteringProfile: formatFilteringProfile(action.filteringProfile),
      };
    case RESET_FILTERING_PROFILE:
      return {
        ...state,
        filteringProfile: initialState.filteringProfile,
      };

    case ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST:
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          blacklist: Array.from(
            new Set([...state.filteringProfile.blacklist, action.group])
          ),
          whitelist: state.filteringProfile.whitelist.filter(
            (group) => group !== action.group
          ),
        },
      };

    case REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST:
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          blacklist: state.filteringProfile.blacklist.filter(
            (group) => group !== action.group
          ),
        },
      };

    case ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST:
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          whitelist: Array.from(
            new Set([...state.filteringProfile.whitelist, action.group])
          ),
          blacklist: state.filteringProfile.blacklist.filter(
            (group) => group !== action.group
          ),
        },
      };

    case REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST:
      return {
        ...state,
        filteringProfile: {
          ...state.filteringProfile,
          whitelist: state.filteringProfile.whitelist.filter(
            (group) => group !== action.group
          ),
        },
      };

    case SET_COLOR_SCALE_STEP:
      return {
        ...state,
        colorScaleStep: action.step,
        refreshing: false,
      };

    case SET_COLOR_OPACITY:
      return {
        ...state,
        opacity: action.opacity,
        refreshing: false,
      };

    case SET_SELECTED_PLOT_ID:
      return {
        ...state,
        selectedPlotId: action.selectedPlotId,
      };

    case RESET_RESULT_MAP:
      const refreshing = state.refreshing;
      return {
        ...initialState,
        refreshing: refreshing,
      };

    case REFRESHING_RESULT_MAP:
      return {
        ...state,
        refreshing: !state.refreshing,
      };

    case REFRESHING_FEATURES_BY_DATE:
      return {
        ...state,
        refreshingFeaturesByDate: true,
      };

    case SET_FOCUSED_VARIETIES:
      return {
        ...state,
        focusedVarieties: action.focusedVarieties,
      };

    case SET_FAVORITE_VARIETIES:
      return {
        ...state,
        favoriteVarieties: action.favoriteVarieties,
      };

    case SET_VISUALIZED_VARIETIES:
      return {
        ...state,
        visualizedVarieties: action.visualizedVarieties,
      };

    case SET_SELECTED_CONTRACT:
      return {
        ...state,
        selectedContract: action.contract,
      };

    case SET_AUC_DATA:
      return {
        ...state,
        aucData: action.aucData,
      };

    case REMOVE_TRAIT_FROM_AUC_DATA:
      const featureDataCopy = JSON.parse(
        JSON.stringify(state.aucData.featureData)
      );
      Object.values(featureDataCopy).forEach((featureData) => {
        delete featureData[action.traitLabel];
      });
      return {
        ...state,
        aucData: {
          traits: state.aucData.traits.filter(
            (trait) => trait.technical_name !== action.traitLabel
          ),
          featureData: featureDataCopy,
        },
      };

    case FULL_RESET_RESULT_MAP:
      return initialState;

    default:
      return state;
  }
}
