import "./advancedFilteringRecap.css";

import { Badge, Input } from "reactstrap";
import {
  addGroupToFilteringProfileBlacklist,
  addGroupToFilteringProfileWhitelist,
  deleteFilteringProfileFilter,
} from "../../../../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";

import { GroupListManager } from "./GroupListManager";
import { HighlightedText } from "../../../../../components/highlightedText/HighlightedText";
import PropTypes from "prop-types";
import { dateSort } from "../../../../utils";
import { selectExcludedGroupsSet } from "../../../../../selectors/resultMap";
import { selectTraitsIndexedOnTechnicalName } from "../../../../../selectors/traits";
import { truncateNumber } from "../../../../../services/utils";

const TraitFilterDetails = ({ trait, onDelete }) => {
  const traitsIndexedOnTechnicalName = useSelector(
    selectTraitsIndexedOnTechnicalName
  );

  const [traitTechnicalName, traitClasses] = trait.technical_name.split("$");

  const text = `${
    traitsIndexedOnTechnicalName[traitTechnicalName]?.name ??
    trait.technical_name
  }${traitClasses ? ` (${traitClasses.replaceAll("|", " ")})` : ""}`;

  return (
    <div className="d-flex justify-content-between" title={text}>
      <span className="traits-text flex-shrink-1 overflow-hidden text-truncate">
        {text} :
      </span>
      <span>
        {`${truncateNumber(trait.threshold[0])} → ${truncateNumber(
          trait.threshold[1]
        )}`}{" "}
        <i className="fa fa-close discrete-icon clickable" onClick={onDelete} />
      </span>
    </div>
  );
};

const DateFilterDetails = ({ filter }) => {
  const dispatch = useDispatch();
  return (
    <div className="date-filter-details">
      <div className="d-flex">
        <Badge className="hiphen-badge active">{filter.date}</Badge>{" "}
        <div className="linker" />
        <Badge pill className="hiphen-badge small">
          <i className="fa fa-ban discrete-icon" />{" "}
          {filter.excludedGroups.length}
        </Badge>
        <i
          className="fa fa-close discrete-icon clickable"
          onClick={() => dispatch(deleteFilteringProfileFilter(filter.date))}
        />
      </div>
      <div className="date-filter-details-traits p-2 pe-0">
        {filter.traits.map((trait) => (
          <TraitFilterDetails
            key={trait.technical_name}
            trait={trait}
            onDelete={() =>
              dispatch(
                deleteFilteringProfileFilter(filter.date, trait.technical_name)
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

const ExcludedGroup = ({ group, search, isBlacklisted }) => {
  const dispatch = useDispatch();
  return (
    <div className="group-list-item p-2" key={group}>
      <span>
        <i
          className={`fa ${
            isBlacklisted ? "fa-minus-circle bl" : "fa-filter discrete-icon"
          }`}
        />{" "}
        <HighlightedText text={group} highlight={search}></HighlightedText>
      </span>
      <div className="d-flex gap-2">
        {!isBlacklisted && (
          <i
            className="fa fa-minus-circle discrete-icon clickable bl"
            onClick={() => dispatch(addGroupToFilteringProfileBlacklist(group))}
          />
        )}
        <i
          className="fa fa-shield discrete-icon clickable wl"
          onClick={() => dispatch(addGroupToFilteringProfileWhitelist(group))}
        />
      </div>
    </div>
  );
};

export const AdvancedFilteringRecap = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState("");
  const [showBlacklisted, setShowBlacklisted] = useState(true);

  const filteringProfile = useSelector(
    (state) => state.resultMap.filteringProfile
  );

  const totalTraitsCount = useMemo(
    () => filteringProfile.filters.flatMap((filter) => filter.traits).length,
    [filteringProfile]
  );
  const excludedGroups = Array.from(useSelector(selectExcludedGroupsSet));
  const notExcludedGroupsLength = data.length - excludedGroups.length;
  const isExpanded = expanded && filteringProfile.scope;

  return (
    <div className={`advanced-filtering-recap ${isExpanded ? "exp" : ""}`}>
      <div
        className="advanced-filtering-recap-header p-3 d-flex clickable"
        onClick={() =>
          setExpanded((prev) => (filteringProfile.scope ? !prev : prev))
        }
      >
        <div className="summary d-flex justify-content-center gap-2 pe-2">
          <small>
            {data.length &&
              ((notExcludedGroupsLength * 100) / data.length).toFixed(1)}
            %
          </small>
          <Badge className="hiphen-badge active">
            {notExcludedGroupsLength}{" "}
            <i className="fa fa-check discrete-icon" />
          </Badge>
          <Badge className="hiphen-badge filtered-out">
            <i className="fa fa-ban discrete-icon" /> {excludedGroups.length}
          </Badge>
          <small>
            {data.length &&
              ((excludedGroups.length * 100) / data.length).toFixed(1)}
            %
          </small>
        </div>
        {filteringProfile.scope ? (
          <div>
            <span className="traits-text">
              <i className="fa fa-filter" />{" "}
              {`${totalTraitsCount} trait${totalTraitsCount !== 1 ? "s" : ""}`}
            </span>{" "}
            across{" "}
            <Badge className="hiphen-badge small active font-weight-bold">
              {filteringProfile.filters.length}
            </Badge>{" "}
            {`date${filteringProfile.filters.length !== 1 ? "s" : ""}`}
          </div>
        ) : (
          <div className="no-filters">No scope defined yet</div>
        )}
        <i
          className={`fa fa-chevron-up ${isExpanded ? "fa-rotate-180" : ""} ${
            !filteringProfile.scope ? "invisible" : ""
          }`}
        />
      </div>
      {isExpanded && (
        <div className="advanced-filtering-recap-body p-2 pt-0">
          <div className="d-flex flex-column basis-50 align-items-stretch flex-grow-1">
            <div className="pb-0 d-flex gap-2">
              <Input
                size="sm"
                type="search"
                placeholder="Search among filtered out groups"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span
                className="d-flex gap-1 align-items-end"
                data-tooltip-id="tooltip"
                data-tooltip-content="Show groups from block list"
              >
                <Input
                  type="checkbox"
                  checked={showBlacklisted}
                  onChange={() => setShowBlacklisted(!showBlacklisted)}
                />
                <i className="fa fa-minus-circle discrete-icon" />
              </span>
            </div>
            <div className="group-list flex-grow-1 p-2">
              {excludedGroups
                .filter(
                  (group) =>
                    (showBlacklisted ||
                      !filteringProfile.blacklist.includes(group)) &&
                    group.toLowerCase().includes(search.toLowerCase())
                )
                .toSorted()
                .map((group) => (
                  <ExcludedGroup
                    group={group}
                    isBlacklisted={filteringProfile.blacklist.includes(group)}
                    search={search}
                    key={group}
                  />
                ))}
            </div>
          </div>
          <div className="d-flex flex-column flex-grow-1 p-2 pt-0 pb-0 align-items-stretch">
            <div className="date-list">
              {filteringProfile.filters
                .toSorted((a, b) => dateSort(a.date, b.date))
                .map((filter) => (
                  <DateFilterDetails key={filter.date} filter={filter} />
                ))}
            </div>
            <GroupListManager filteringProfile={filteringProfile} />
          </div>
        </div>
      )}
    </div>
  );
};

TraitFilterDetails.propTypes = {
  trait: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ExcludedGroup.propTypes = {
  group: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  isBlacklisted: PropTypes.bool.isRequired,
};

AdvancedFilteringRecap.propTypes = {
  data: PropTypes.array.isRequired,
};

DateFilterDetails.propTypes = {
  filter: PropTypes.object.isRequired,
};
