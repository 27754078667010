import "./countryFlag.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import PropTypes from "prop-types";
import { iso31661 } from "iso-3166";

/*
 * Displays a small flag based on alpha code (Takes alpha-3 code in props)
 */
export const CountryFlag = ({ alpha }) => {
  const country = (alpha &&
    iso31661.find((country) => country.alpha3 === alpha)) ?? {
    name: "",
    alpha2: "xx",
  };
  return (
    <span
      className={`fi fi-${country.alpha2.toLowerCase()} fis`}
      title={country.name}
    ></span>
  );
};

CountryFlag.propTypes = {
  alpha: PropTypes.string,
};
