import "./administration.css";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, { Component } from "react";

import AlertComponent from "../components/alert";
import Companies from "./companies";
import Crops from "./crops";
import NavbarComponent from "../components/navbar";
import PageTitle from "../components/pageTitle";
import UploadTemplates from "./uploadTemplates";
import classnames from "classnames";
import { connect } from "react-redux";

class Administration extends Component {
  state = {
    activeTab: "Companies",
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    return (
      <div className="wrapper">
        <NavbarComponent />
        <AlertComponent />

        <div className="page-content container-fluid">
          <PageTitle title="Administration" />
          <div className="col-12 section full-height">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "Companies",
                    collapseToggleTitle: this.state.activeTab !== "Companies",
                  })}
                  onClick={() => {
                    this.toggle("Companies");
                  }}
                >
                  Companies
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "Templates",
                    collapseToggleTitle: this.state.activeTab !== "Templates",
                  })}
                  onClick={() => {
                    this.toggle("Templates");
                  }}
                >
                  Upload Templates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "Crops",
                    collapseToggleTitle: this.state.activeTab !== "Crops",
                  })}
                  onClick={() => {
                    this.toggle("Crops");
                  }}
                >
                  Crops
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="Companies">
                <Companies history={this.props.history} />
              </TabPane>
              <TabPane tabId="Templates">
                <UploadTemplates />
              </TabPane>
              <TabPane tabId="Crops">
                <Crops />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const callbacks = {};

export default connect(mapStateToProps, callbacks)(Administration);
